import React from 'react';
import Title from '../Title';
import OrangeButton from '../OrangeButton';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux'; 
import { change_visible_popup } from '../../redux/actions/users';
import { 
  setGeneratePage,
  setDeathDate,
  setBirthdayDate,
  setInitialDeath,
  setNationality,
  setBirthLocation,
  setDeathLocation,
  setEpity,
  setChildren,
  setSecondHalf,
  setCareer,
  setEducation,
  sendEmailServer, 
  setFile, 
  setBackgroundSelect,  
  setOneBlockArea, 
  setOneBlockInputTitle,
  setOneBlockOneInput,
  setOneBlockTwoInput,
  setTwoBlockArea, 
  setTwoBlockInputTitle,
  setTwoBlockOneInput,
  setTwoBlockTwoInput,
  setThreeBlockArea, 
  setRegestratorTel, 
  setEditPage  
}  from '../../redux/actions/pageinfo'; 
import  images  from '../../assets/images.js'
import './style.css';

export default function MainBlock(props) {

  const { mobile, isAuth, user, id } = props;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  return (
    <div id="toppage" className={`${mobile ? 'mobileItemWrapper' : 'itemWrapper'}`}> 
      {/* <div className="colInfoBlock">  */}
        <div className={`${mobile ? 'mobile-wrapper_maintxt' : 'wrapper_maintxt'}`} > 
          <div className={`${mobile ? 'mobileColTimerBlock' : 'colTimerBlock'}`}>
            <div className={`${mobile ? 'mobileInfoLeftRow' : 'infoLeftRow'}`}>
              <div style={{ flexDirection: 'column'}} className={`${mobile ? 'mobile-txtTimer' : 'txtTimer'}`}>
                <span className={`${mobile ? 'mobileSpantxt' : 'spantxt'}`}>
                  QR-КОД НА ПАМЯТНИКЕ ОТКРОЕТ
                </span>
                <span className={`${mobile ? 'mobileSpantxt' : 'spantxt'}`}>
                  ИСТОРИЮ ЖИЗНИ ЧЕЛОВЕКА 
                </span>
                <span className={`${mobile ? 'mobileSpansubtxt' : 'spansubtxt'}`}>
                  Сохрани в памяти фото, голос и видео<br/>о своих близких на долгие годы.
                </span> 
              </div>
              <div className={`${mobile ? 'mobileLineContainer' : 'lineContainer'}`}>
                <div style={{ width: mobile ? '10%' : '30%', height: '1px', backgroundColor: '#353d51' }}></div>
                <div> в системе уже более 10 000 страниц</div>
                <div style={{ width: mobile ? '10%' : '30%', height: '1px', backgroundColor: '#353d51' }}></div>
              </div>
              <OrangeButton
              styles={{ fontSize: '14px' }}
              height={mobile ? 40 : 55}
              width={mobile ? 300 : 350}
              margin={{ width: mobile ? "0px 0px 0px 0px" : "20px 0px 0px 0px" }}
              text="Создать страницу памяти"
              onClick={() => { 
                if( user.payinfo === '2') {
                  navigate('/createpage/#uppage');  
                  dispatch(setOneBlockArea('')) 
                  dispatch(setOneBlockInputTitle('')) 
                  dispatch(setOneBlockOneInput(''))
                  dispatch(setOneBlockTwoInput(''))
                  dispatch(setTwoBlockArea('')) 
                  dispatch(setTwoBlockInputTitle('')) 
                  dispatch(setTwoBlockOneInput(''))
                  dispatch(setTwoBlockTwoInput(''))
                  dispatch(setThreeBlockArea(''))    
                  dispatch(setDeathDate(moment().format("YYYY-MM-DD HH:mm")));
                  dispatch(setBirthdayDate(moment().format("YYYY-MM-DD HH:mm")));
                  dispatch(setInitialDeath(''));
                  dispatch(setNationality(''));
                  dispatch(setBirthLocation(''));
                  dispatch(setDeathLocation(''));
                  dispatch(setEpity(''));
                  dispatch(setFile([])); 
                  dispatch(setChildren(''));
                  dispatch(setSecondHalf(''));
                  dispatch(setCareer('')); 
                  dispatch(setEducation(''));
                  dispatch(setBackgroundSelect(3));  
              } else {
                dispatch(change_visible_popup(true)); 
              } 
              }} />
            </div>
            <div className={`${mobile ? 'mobileInfoRightRow' : 'infoRightRow'}`}>
              <img className='imglogoqr' src={images.logoqr} alt="qr" />
            </div> 
          </div>
        </div> 
      {/* </div> */}
    </div>
  );
};