import images from '../assets/images';

// const API_URL = "https://inmemory-forever.com/api";   // RELEASE
const API_URL = process.env.NODE_ENV == 'development' ? 'http://localhost:4000/api' : 'https://inmemory-forever.com/api'; 

const telephone = '+7 988 083 76 29'


const {
  an_0,
  an_1,
  an_2,
  an_3,
  an_4,
  an_5,
  an_6,
  an_7,
  an_8,
  an_9,
  an_10,
  an_11,
  an_12,
  an_13,
  an_14,
  an_15,
  an_16,
  an_17,
  an_18,
  an_19,
  an_20,
  an_21,
  w_0,
  w_1,
  w_2,
  w_3,
  w_4,
  w_5,
  w_6,
  w_7,
  w_8,
  w_9,
  w_10,
  w_11,
  w_12,
  w_13,
  w_14,
  w_15,
  w_16,
  w_17,
  w_18,
  w_19,
  w_20,
  w_21,
  w_22,
  w_23,
  w_24,
  w_25,
  w_26,
  w_27,
  w_28, 
  w_30,
  w_31,
  w_32,
  w_33,
  w_34,
  w_35,
  w_36,
  w_37,
  w_38,
  w_39,
  w_40, 
  w_42,
  w_43,
  w_44,
  w_45,
  w_46,
  w_47,
  w_48,
  w_49,
  w_50,
  w_51,
  w_52, 
  w_55,
  w_56,
  w_57,
  w_58,
  w_59,
  w_60,
  w_61,
  w_62,
  w_63,
  w_64,
  w_65,
  w_66,
  w_67,
  w_68,
  w_69,
  w_70,
  w_71,
  w_72,
  w_73,
  w_74,
  w_75,
  w_76,
  w_77,
  w_78,
  w_79,
  w_80,
  w_81,
  w_82,
  w_83,
  w_84,
  w_85,
  w_86,
  w_87,
  w_88,
  w_89,
  w_90,
  w_91,
  w_92,
  w_93,
  w_94,
  w_95,
  w_96,
  w_97,
  w_98,
  w_99,
  w_100,
  w_101,
  w_102,
  w_103,
  w_104,
  w_105,
  w_106,
  w_107,
  w_108,
  w_109,
  w_110,
  w_111,
  w_112,
  w_113,
  w_114,
  w_115,
  w_116,
  w_117,
  w_118,
  w_119,
  w_120,
  w_121,
  w_122,
  w_123,
  w_124,
  w_125,
  w_126,
  w_127,
  w_128,
  w_129,
  w_130,
  w_131,
  w_132, 
  w_135,
  w_136, 
  w_138,
  w_139,
  w_140,
  w_141,
  w_142,
  w_143,
  w_144,
  w_145,
  w_146,
  w_147,
  w_148,
  w_149,
  w_150,
  w_151,
  w_152,
  w_153, 
  w_156,
  w_157, 
  w_161,
  w_162,
  w_163,
  w_164,
  w_165,
  w_166,
  w_167,
  w_168,
  w_169,
  w_170,
  w_171,
  w_172,
  ch_m_0,
  ch_m_1,
  ch_m_2,
  ch_m_3,
  p_0,
  p_1,
  p_2,
  gr_f_0,
  gr_f_1,
  gr_f_2,
  gr_f_3,
  gr_f_4,
  gr_f_5,
  gr_f_6,
  gr_f_7,
  gr_f_8,
  gr_f_9,
  gr_f_10,
  gr_f_11,
  gr_f_12,
  gr_f_13,
  gr_f_14,
  gr_f_15,
  gr_f_16,
  gr_f_17,
  gr_f_18,
  gr_f_19,
  gr_f_20,
  gr_f_21,
  gr_f_22,
  gr_f_23,
  gr_f_24,
  gr_f_25,
  gr_f_26,
  gr_f_27,
  gr_f_28,
  gr_f_29,
  gr_f_30,
  gr_f_31,
  gr_f_32,
  gr_f_33,
  gr_f_34,
  gr_f_35,
  gr_f_36,
  gr_f_37,
  gr_f_38,
  gr_f_39,
  fam_m_0,
  fam_m_1,
  fam_m_2,
  fam_m_3,
  fam_m_4,
  fam_m_5,
  fam_m_6,
  fam_m_7,
  fam_m_8,
  fam_m_9,
  fam_m_10,
  fam_m_11,
  fam_m_12,
  fam_m_13,
  fam_m_14,
  fam_m_15,
  fam_m_16,
  fam_m_17,
  fam_m_18,
  fam_m_19,
  rez_f_0,
  rez_f_1,
  rez_f_2,
  rez_f_3,
  man_mr_0,
  man_mr_1,
  man_mr_2,
  man_mr_3,
  man_mr_4,
  man_mr_5,
  man_mr_6,
  man_mr_7,
  man_mr_8,
  man_mr_9,
  man_mr_10,
  man_mr_11,
  man_mr_12,
  man_mr_13,
  man_mr_14,
  man_mr_15,
  man_mr_16,
  man_mr_17,
  man_mr_18,
  man_mr_19,
  man_mr_20,
  man_mr_21,
  man_mr_22,
  man_mr_23,
  man_mr_24,
  man_mr_25,
  man_mr_26,
  man_mr_27,
  man_mr_28,
  man_mr_29,
  man_mr_30,
  man_mr_31,
  man_mr_32,
  man_mr_33,
  man_mr_34,
  man_mr_35,
  man_mr_36,
  man_mr_37,
  man_mr_38,
  man_mr_39,
  man_mr_40,
  man_mr_41,
  man_mr_42,
  man_mr_43,
  man_mr_44,
  man_mr_45,
  man_mr_46,
  man_mr_47,
  man_mr_48,
  man_mr_49,
  man_mr_50,
  man_mr_51,
  man_mr_52,
  man_mr_53,
  man_mr_54,
  man_mr_55,
  man_mr_56,
  man_mr_57,
  man_mr_58,
  man_mr_59,
  man_mr_60,
  man_mr_61,
  man_mr_62,
  man_mr_63,
  man_mr_64,
  man_mr_65,
  man_mr_66,
  man_mr_67,
  man_mr_68,
  man_mr_69,
  man_mr_70,
  man_mr_71,
  man_mr_72, 
  rez_m_0,
  rez_m_1,
  rez_m_2,
  rez_m_3,
  rez_m_4,
  rez_m_5,
  rez_m_6,
  rez_m_7,
  rez_m_8,
  rez_m_9,
  rez_m_10,
  rez_m_11,
  rez_m_12,
  rez_m_13,
  rez_m_14,
  rez_m_15,
  rez_m_16,
  rez_m_17,
  rez_m_18,
  rez_m_19,
  rez_m_20,
  rez_m_21,
  rez_m_22,
  rez_m_23,
  rez_m_24,
  rez_m_25,
  rez_m_26,
  rez_m_27,
  rez_w_0, 
  rez_w_1, 
  rez_w_2, 
  rez_w_3, 
  rez_w_4, 
  rez_w_5, 
  rez_w_6, 
  rez_w_7, 
  rez_w_8, 
  rez_w_9, 
  rez_w_10, 
  rez_w_11, 
  rez_w_12, 
  rez_w_13, 
  rez_w_14,
  wom_m_0,   
  wom_m_1,   
  wom_m_2,   
  wom_m_3,   
  wom_m_4,   
  wom_m_5,   
  wom_m_6,   
  wom_m_7,   
  wom_m_8,   
  wom_m_9,   
  wom_m_10,   
  wom_m_11,   
  wom_m_12,   
  wom_m_13,   
  wom_m_14,   
  wom_m_15,   
  wom_m_16,   
  wom_m_17,   
  wom_m_18,   
  wom_m_19,   
  wom_m_20,   
  wom_m_21,   
  wom_m_22,   
  wom_m_23,   
  wom_m_24,   
  wom_m_25,   
  wom_m_26,   
  wom_m_27,   
  wom_m_28,   
  wom_m_29,   
  wom_m_30,   
  wom_m_31,   
  wom_m_32,   
  wom_m_33,   
  wom_m_34,   
  wom_m_35,   
  wom_m_36,   
  wom_m_37,   
  wom_m_38,   
  wom_m_39,   
  form_0,
  form_1,
  form_2,
  form_3,
  form_4,
  form_5,
  form_6,
  form_7,
  form_8,
  form_9,
  form_10,
  form_11,
  form_12,
  form_13,
  form_14,
  form_15,
  form_16,
  form_17,
  form_18,
  form_19,
  form_20,
  form_21,
  form_22,
  form_23,
  form_24,
  form_25,
  form_26,
  form_27,
  form_28,
  form_29,
  form_30,
  form_31,
  form_32,
  form_33,
  form_34,
  form_35,
  form_36,
  form_37,
  form_38,
  form_39,
  form_40,
  form_41,
  form_42,
  form_43,
  form_44,
  form_45,
  form_46,
  form_47,
  form_48,
  form_49,
  form_50,
  form_51,
  form_52,
  form_53,
  form_54,
  form_55,
  form_56,
  form_57,
  form_58,
  form_59,
  form_60,
  form_61,
  form_62,
  form_63,
  form_64,
  form_65,
  form_66, 
} = images;
 
const top_product = [
  {
    material: 'granit',
    count: 1,
    img:rez_w_3,
    title: 'Памятник для женщин резной',
    description: 'Памятник из гранита',
    price: '75000',
    id: '3',
    prop:`
      <ul type="square">
        <li>Размеры:</li>
        <li>Памятник 120х60х8</li>
        <li>Цена указана за готовый памятник</li>
        <li>Мы можем изготовить памятник в любом цвете</li>
        <li>Оплата после установки памятника</li>
      </ul>
    `,
    info: `
    <div>
      <h1>Всю информацию можно уточнить по телефону:</h1>
      <h2>${telephone}</h2>
    </div>        
  `
  },
  {
    material: 'mramor',
    count: 1,
    img:man_mr_57,
    title: 'Памятник Мужской мрамор',
    description: 'Памятник из гранита',
    price: '75000',
    id: '115',
    prop:`
      <ul type="square">
        <li>Размеры:</li>
        <li>Памятник 120х60х8</li>
        <li>Цена указана за готовый памятник</li>
        <li>Мы можем изготовить памятник в любом цвете</li>
        <li>Оплата после установки памятника</li>
      </ul>
    `,
    info: `
    <div>
      <h1>Всю информацию можно уточнить по телефону:</h1>
      <h2>${telephone}</h2>
    </div>        
  `
  }, 
  {
    material: 'granit',
    count: 1,
    img:an_19,
    title: 'Памятник для животных',
    description: 'Памятник из гранита',
    price: '75000',
    id: '256',
    prop:`
      <ul type="square">
        <li>Размеры:</li>
        <li>Памятник 120х60х8</li>
        <li>Цена указана за готовый памятник</li>
        <li>Мы можем изготовить памятник в любом цвете</li>
        <li>Оплата после установки памятника</li>
      </ul>
    `,
    info: `
    <div>
      <h1>Всю информацию можно уточнить по телефону:</h1>
      <h2>${telephone}</h2>
    </div>        
  `
  },  

]

const about_plus = [
  {
    plus: 'Своевременную бесплатную консультацию по любому вопросу.'
  },
  {
    plus: 'Гарантию 15 лет на памятники категории вип.'
  },
  {
    plus: 'Качество продукции и материалов.'
  },
]

const about_product = {
    img: images.memory,
    text: `
      После быстрой и легкой регистрации, создайте страницу памяти о близком человеке заполнив соответсвующую форму.
      В созданой интерактивной странице на нашей платформе, вы сможете сохранить воспоминания и историю близкого человека. 
      В течение многих лет, данные будут храниться с возможностью поделиться с близкими и быстрого доступа через QR-код. 
    `,
    img_2: images.memory_2,
    text_2: `
      Создав страницу запечатлив фото, голос, видеоряд вы позволяете пронести память о родном и близком человеке сквозь поколения.
      Ведь история жизни есть у каждого. Сохраните память о важных семейных моментах жизни: 
      Первая любовь, первые успехи, преодаление трудностей, веселые моменты чтобы окружающие могли легко и быстро узнать.
    `
  }

const ordedr_work = [
  {
    title: 'Заявка',
    description: `
      Вы оставляете заявку :
      — заполнив любую форму
      на сайте, — по телефону,
      — или в одном из офисов продаж.
    `
  },
  {
    title: 'Договор',
    description: `
    Мы заключаем договор
    `
  },
  {
    title: 'Изготовление',
    description: `
    Мы изготавливаем ваш памятник
    `
  },
  {
    title: 'Доставка',
    description: `
    мы отправляем ваш заказ собственным транспортом или же транспортными компаниями
    `
  },
  {
    title: 'Установка',
    description: `
    Мы устанавливаем ваш памятник
    `
  },
  {
    title: 'Оплата',
    description: `
    Вы принимаете работу и оплачиваете остаток
    `
  },
]

const feedback = [
  {
    name:'Нина',
    sub_name:'Филипповна',
    text:'QR-код на надгробии теперь откроет историю жизни моего отца. Спасибо.',
    star: 5
  },
  {
    name:'Олег',
    sub_name:'Петрович',
    text:'Спасибо за сервис. Теперь я уверен, что мои дети и внуки будут знать, кем был их дедушка.',
    star: 5
  },
  {
    name:'Эдуард',
    sub_name:'Святославович',
    text:'Рад что запечатлел память о моем отце навеки.',
    star: 5
  },
  {
    name:'Ольга',
    sub_name:'Алексеевна',
    text:'Мне понравился сервис. Можно разместить QR-код на памятнике и, сканировав его, увидеть каким был человек.',
    star: 5
  },
  {
    name:'Юлия',
    sub_name:'Викторовна',
    text:'Мне всё понравилось, память о близких очень важна.',
    star: 5
  },
  {
    name:'Антон',
    sub_name:'Николаевич',
    text:'Отличный сервис, мне понравилось.',
    star: 5
  },
]

const answers = [
  {
    request: `Что такое «In Memory forever»?`,
    response: ` 
      «In Memory forever» — это платформа, которая позволяет сохранить память о близких людях для передачи следующим поколениям. 
      Заполните страницу памяти, получите QR-код и делитесь памятью с окружающими, получив доступ к странице памяти.
    `
  },
  {
    request: `Как я могу создать страницу памяти о близком?`,
    response: `
    Вам нужно зарегистрироваться, перейти в меню профиля и в нем создать краткую страницу памяти. 
    Если хотите разместить больше информации, оплатите расширенный функционал и заполните поля формы страницы памяти. 
    `
  },
  {
    request: `Почему важно создавать страницы памяти о своих близких?`,
    response: `
    Все материальные носители имеют ограниченный срок существования.
    А информация, которая хранится в сети, практически вечна.
    Ваши дети, внуки и правнуки узнают о своих предках, смогут увидеть их, услышать голос.
    `
  }, 
  {
    request: `Что можно разместить на краткой версии страницы памяти?`,
    response: `
    На краткой версии страницы памяти можно разместить фотографию, короткую эпитафию и ссылки на страницы родственников.
    `
  },
  {
    request: `Как я могу расширить функционал краткой страницы?`,
    response: `Если вы хотите разместить подробную биографию, больше фотографий, аудио и видеозаписи или ссылки на сторонние ресурсы, зайдите в меню профиля и оплатите расширенную версию страницы памяти.`
  },
  {
    request: `Сколько будет храниться страница памяти?`,
    response: `
    «In Memory forever» предназначен для хранения вашей информации неограниченно долго. Это обеспечивается надежной инфраструктурой и контролем за целостностью данных.

Мы используем распределенные сервера высшей категории надежности, имеющие сертификат соответствия требованиям PCI DSS. Для соответствия требованиям ФЗ-152 и индустриальных стандартов, данные хранятся на серверах, расположенных в России.

База данных и сервисы хранят пользовательские данные в зашифрованном виде. 
    `
  },
]

const works_images = [
  w_0,
  w_1,
  w_2,
  w_3,
  w_4,
  w_5,
  w_6,
  w_7,
  w_8,
  w_9,
  w_10,
  w_11,
  w_12,
  w_13,
  w_14,
  w_15,
  w_16,
  w_17,
  w_18,
  w_19,
  w_20,
  w_21,
  w_22,
  w_23,
  w_24,
  w_25,
  w_26,
  w_27,
  w_28,
  // w_29,
  w_30,
  w_31,
  w_32,
  w_33,
  w_34,
  w_35,
  w_36,
  w_37,
  w_38,
  w_39,
  w_40,
  // w_41,
  w_42,
  w_43,
  w_44,
  w_45,
  w_46,
  w_47,
  w_48,
  w_49,
  w_50,
  w_51,
  w_52,
  // w_53,
  // w_54,
  w_55,
  w_56,
  w_57,
  w_58,
  w_59,
  w_60,
  w_61,
  w_62,
  w_63,
  w_64,
  w_65,
  w_66,
  w_67,
  w_68,
  w_69,
  w_70,
  w_71,
  w_72,
  w_73,
  w_74,
  w_75,
  w_76,
  w_77,
  w_78,
  w_79,
  w_80,
  w_81,
  w_82,
  w_83,
  w_84,
  w_85,
  w_86,
  w_87,
  w_88,
  w_89,
  w_90,
  w_91,
  w_92,
  w_93,
  w_94,
  w_95,
  w_96,
  w_97,
  w_98,
  w_99,
  w_100,
  w_101,
  w_102,
  w_103,
  w_104,
  w_105,
  w_106,
  w_107,
  w_108,
  w_109,
  w_110,
  w_111,
  w_112,
  w_113,
  w_114,
  w_115,
  w_116,
  w_117,
  w_118,
  w_119,
  w_120,
  w_121,
  w_122,
  w_123,
  w_124,
  w_125,
  w_126,
  w_127,
  w_128,
  w_129,
  w_130,
  w_131,
  w_132,
  // w_133,
  // w_134,
  w_135,
  w_136,
  // w_137,
  w_138,
  w_139,
  w_140,
  w_141,
  w_142,
  w_143,
  w_144,
  w_145,
  w_146,
  w_147,
  w_148,
  w_149,
  w_150,
  w_151,
  w_152,
  w_153,
  // w_154,
  // w_155,
  w_156,
  w_157,
  // w_158,
  // w_159,
  // w_160,
  w_161,
  w_162,
  w_163,
  w_164,
  w_165,
  w_166,
  w_167,
  w_168,
  w_169,
  w_170,
  w_171,
  w_172
]
 
const products = [
  {
    category: 'woman', // Женские
    monuments: [
      {
        material: 'granit',  // Материалы Гранит
        items: [  // В этот массив вставляется вся информация по памятнику
          { 
            material: 'granit',
            count: 1,
            img:rez_w_0,
            title: 'Памятник для женщин резной',
            description: 'Памятник из гранита',
            price: '75000',
            id: '0',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Всю информацию можно уточнить по телефону:</h1>
                <h2>${telephone}</h2>
              </div>        
            `
          }, 
          {
            material: 'granit',
            count: 1,
            img:rez_w_1,
            title: 'Памятник для женщин резной',
            description: 'Памятник из гранита',
            price: '75000',
            id: '1',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'granit',
            count: 1,
            img:rez_w_2,
            title: 'Памятник для женщин резной',
            description: 'Памятник из гранита',
            price: '75000',
            id: '2',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'granit',
            count: 1,
            img:rez_w_3,
            title: 'Памятник для женщин резной',
            description: 'Памятник из гранита',
            price: '75000',
            id: '3',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'granit',
            count: 1,
            img:rez_w_4,
            title: 'Памятник для женщин резной',
            description: 'Памятник из гранита',
            price: '75000',
            id: '4',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'granit',
            count: 1,
            img:rez_w_5,
            title: 'Памятник для женщин резной',
            description: 'Памятник из гранита',
            price: '75000',
            id: '5',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'granit',
            count: 1,
            img:rez_w_6,
            title: 'Памятник для женщин резной',
            description: 'Памятник из гранита',
            price: '75000',
            id: '6',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'granit',
            count: 1,
            img:rez_w_7,
            title: 'Памятник для женщин резной',
            description: 'Памятник из гранита',
            price: '75000',
            id: '7',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'granit',
            count: 1,
            img:rez_w_8,
            title: 'Памятник для женщин резной',
            description: 'Памятник из гранита',
            price: '75000',
            id: '8',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'granit',
            count: 1,
            img:rez_w_9,
            title: 'Памятник для женщин резной',
            description: 'Памятник из гранита',
            price: '75000',
            id: '9',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'granit',
            count: 1,
            img:rez_w_10,
            title: 'Памятник для женщин резной',
            description: 'Памятник из гранита',
            price: '75000',
            id: '10',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'granit',
            count: 1,
            img:rez_w_11,
            title: 'Памятник для женщин резной',
            description: 'Памятник из гранита',
            price: '75000',
            id: '11',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'granit',
            count: 1,
            img:rez_w_12,
            title: 'Памятник для женщин резной',
            description: 'Памятник из гранита',
            price: '75000',
            id: '12',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'granit',
            count: 1,
            img:rez_w_13,
            title: 'Памятник для женщин резной',
            description: 'Памятник из гранита',
            price: '75000',
            id: '13',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'granit',
            count: 1,
            img:rez_w_14,
            title: 'Памятник для женщин резной',
            description: 'Памятник из гранита',
            price: '75000',
            id: '14',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          } 
        ]
      },
      {
        material: 'mramor',  // Материалы Мрамор
        items: [  // В этот массив вставляется вся информация по памятнику
          {
            material: 'mramor',
            count: 1,
            img:wom_m_0,
            title: 'Памятник для женщин мраморный',
            description: 'Памятник из гранита',
            price: '75000',
            id: '15',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img:wom_m_1,
            title: 'Памятник для женщин мраморный',
            description: 'Памятник из гранита',
            price: '75000',
            id: '16',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img:wom_m_2,
            title: 'Памятник для женщин мраморный',
            description: 'Памятник из гранита',
            price: '75000',
            id: '17',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img:wom_m_3,
            title: 'Памятник для женщин мраморный',
            description: 'Памятник из гранита',
            price: '75000',
            id: '18',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img:wom_m_4,
            title: 'Памятник для женщин мраморный',
            description: 'Памятник из гранита',
            price: '75000',
            id: '19',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img:wom_m_5,
            title: 'Памятник для женщин мраморный',
            description: 'Памятник из гранита',
            price: '75000',
            id: '20',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img:wom_m_6,
            title: 'Памятник для женщин мраморный',
            description: 'Памятник из гранита',
            price: '75000',
            id: '21',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img:wom_m_7,
            title: 'Памятник для женщин мраморный',
            description: 'Памятник из гранита',
            price: '75000',
            id: '22',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img:wom_m_8,
            title: 'Памятник для женщин мраморный',
            description: 'Памятник из гранита',
            price: '75000',
            id: '23',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img:wom_m_9,
            title: 'Памятник для женщин мраморный',
            description: 'Памятник из гранита',
            price: '75000',
            id: '24',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img:wom_m_10,
            title: 'Памятник для женщин мраморный',
            description: 'Памятник из гранита',
            price: '75000',
            id: '25',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img:wom_m_11,
            title: 'Памятник для женщин мраморный',
            description: 'Памятник из гранита',
            price: '75000',
            id: '26',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img:wom_m_12,
            title: 'Памятник для женщин мраморный',
            description: 'Памятник из гранита',
            price: '75000',
            id: '27',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img:wom_m_13,
            title: 'Памятник для женщин мраморный',
            description: 'Памятник из гранита',
            price: '75000',
            id: '28',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img:wom_m_14,
            title: 'Памятник для женщин мраморный',
            description: 'Памятник из гранита',
            price: '75000',
            id: '29',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img:wom_m_15,
            title: 'Памятник для женщин мраморный',
            description: 'Памятник из гранита',
            price: '75000',
            id: '30',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img:wom_m_16,
            title: 'Памятник для женщин мраморный',
            description: 'Памятник из гранита',
            price: '75000',
            id: '31',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img:wom_m_17,
            title: 'Памятник для женщин мраморный',
            description: 'Памятник из гранита',
            price: '75000',
            id: '32',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img:wom_m_18,
            title: 'Памятник для женщин мраморный',
            description: 'Памятник из гранита',
            price: '75000',
            id: '33',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img:wom_m_19,
            title: 'Памятник для женщин мраморный',
            description: 'Памятник из гранита',
            price: '75000',
            id: '34',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img:wom_m_20,
            title: 'Памятник для женщин мраморный',
            description: 'Памятник из гранита',
            price: '75000',
            id: '35',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img:wom_m_21,
            title: 'Памятник для женщин мраморный',
            description: 'Памятник из гранита',
            price: '75000',
            id: '36',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img:wom_m_22,
            title: 'Памятник для женщин мраморный',
            description: 'Памятник из гранита',
            price: '75000',
            id: '37',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img:wom_m_23,
            title: 'Памятник для женщин мраморный',
            description: 'Памятник из гранита',
            price: '75000',
            id: '38',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img:wom_m_24,
            title: 'Памятник для женщин мраморный',
            description: 'Памятник из гранита',
            price: '75000',
            id: '39',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img:wom_m_25,
            title: 'Памятник для женщин мраморный',
            description: 'Памятник из гранита',
            price: '75000',
            id: '40',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img:wom_m_26,
            title: 'Памятник для женщин мраморный',
            description: 'Памятник из гранита',
            price: '75000',
            id: '41',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img:wom_m_27,
            title: 'Памятник для женщин мраморный',
            description: 'Памятник из гранита',
            price: '75000',
            id: '42',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img:wom_m_28,
            title: 'Памятник для женщин мраморный',
            description: 'Памятник из гранита',
            price: '75000',
            id: '43',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img:wom_m_29,
            title: 'Памятник для женщин мраморный',
            description: 'Памятник из гранита',
            price: '75000',
            id: '44',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img:wom_m_30,
            title: 'Памятник для женщин мраморный',
            description: 'Памятник из гранита',
            price: '75000',
            id: '45',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img:wom_m_31,
            title: 'Памятник для женщин мраморный',
            description: 'Памятник из гранита',
            price: '75000',
            id: '46',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img:wom_m_32,
            title: 'Памятник для женщин мраморный',
            description: 'Памятник из гранита',
            price: '75000',
            id: '47',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img:wom_m_33,
            title: 'Памятник для женщин мраморный',
            description: 'Памятник из гранита',
            price: '75000',
            id: '48',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img:wom_m_34,
            title: 'Памятник для женщин мраморный',
            description: 'Памятник из гранита',
            price: '75000',
            id: '49',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img:wom_m_35,
            title: 'Памятник для женщин мраморный',
            description: 'Памятник из гранита',
            price: '75000',
            id: '50',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img:wom_m_36,
            title: 'Памятник для женщин мраморный',
            description: 'Памятник из гранита',
            price: '75000',
            id: '51',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img:wom_m_37,
            title: 'Памятник для женщин мраморный',
            description: 'Памятник из гранита',
            price: '75000',
            id: '52',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img:wom_m_38,
            title: 'Памятник для женщин мраморный',
            description: 'Памятник из гранита',
            price: '75000',
            id: '53',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img:wom_m_39,
            title: 'Памятник для женщин мраморный',
            description: 'Памятник из гранита',
            price: '75000',
            id: '54',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }
        ]
      },
      {
        material: 'poly',  // Материалы Полиамид
        items: [  // В этот массив вставляется вся информация по памятнику
          {
            material: 'poly',
            count: 1,
            img:p_0,
            title: 'Памятники из полиамида',
            description: 'Памятник из гранита',
            price: '75000',
            id: '55',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          },
          {
            material: 'poly',
            count: 1,
            img:p_1,
            title: 'Памятники из полиамида',
            description: 'Памятник из гранита',
            price: '75000',
            id: '56',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          },
          {
            material: 'poly',
            count: 1,
            img:p_2,
            title: 'Памятники из полиамида',
            description: 'Памятник из гранита',
            price: '75000',
            id: '57',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }
        ]
      } 
    ]
  },
  {
    category: 'man', // Мужские
    monuments: [
      {
        material: 'granit', // Материалы Гранит
        items: [  // В этот массив вставляется вся информация по памятнику
          {
            material: 'granit',
            count: 1,
            img: rez_m_0,
            title: 'Памятник Мужской резные',
            description: 'Памятник из гранита',
            price: '75000',
            id: '131',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          },  
          {
            material: 'granit',
            count: 1,
            img: rez_m_1,
            title: 'Памятник Мужской резные',
            description: 'Памятник из гранита',
            price: '75000',
            id: '132',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          },  
          {
            material: 'granit',
            count: 1,
            img: rez_m_2,
            title: 'Памятник Мужской резные',
            description: 'Памятник из гранита',
            price: '75000',
            id: '133',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          },  
          {
            material: 'granit',
            count: 1,
            img: rez_m_3,
            title: 'Памятник Мужской резные',
            description: 'Памятник из гранита',
            price: '75000',
            id: '134',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          },  
          {
            material: 'granit',
            count: 1,
            img: rez_m_4,
            title: 'Памятник Мужской резные',
            description: 'Памятник из гранита',
            price: '75000',
            id: '135',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          },  
          {
            material: 'granit',
            count: 1,
            img: rez_m_5,
            title: 'Памятник Мужской резные',
            description: 'Памятник из гранита',
            price: '75000',
            id: '13',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          },  
          {
            material: 'granit',
            count: 1,
            img: rez_m_6,
            title: 'Памятник Мужской резные',
            description: 'Памятник из гранита',
            price: '75000',
            id: '137',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          },  
          {
            material: 'granit',
            count: 1,
            img: rez_m_7,
            title: 'Памятник Мужской резные',
            description: 'Памятник из гранита',
            price: '75000',
            id: '138',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          },  
          {
            material: 'granit',
            count: 1,
            img: rez_m_8,
            title: 'Памятник Мужской резные',
            description: 'Памятник из гранита',
            price: '75000',
            id: '139',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          },  
          {
            material: 'granit',
            count: 1,
            img: rez_m_9,
            title: 'Памятник Мужской резные',
            description: 'Памятник из гранита',
            price: '75000',
            id: '140',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          },  
          {
            material: 'granit',
            count: 1,
            img: rez_m_10,
            title: 'Памятник Мужской резные',
            description: 'Памятник из гранита',
            price: '75000',
            id: '141',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          },  
          {
            material: 'granit',
            count: 1,
            img: rez_m_11,
            title: 'Памятник Мужской резные',
            description: 'Памятник из гранита',
            price: '75000',
            id: '142',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          },  
          {
            material: 'granit',
            count: 1,
            img: rez_m_12,
            title: 'Памятник Мужской резные',
            description: 'Памятник из гранита',
            price: '75000',
            id: '143',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          },  
          {
            material: 'granit',
            count: 1,
            img: rez_m_13,
            title: 'Памятник Мужской резные',
            description: 'Памятник из гранита',
            price: '75000',
            id: '144',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          },  
          {
            material: 'granit',
            count: 1,
            img: rez_m_14,
            title: 'Памятник Мужской резные',
            description: 'Памятник из гранита',
            price: '75000',
            id: '145',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          },  
          {
            material: 'granit',
            count: 1,
            img: rez_m_15,
            title: 'Памятник Мужской резные',
            description: 'Памятник из гранита',
            price: '75000',
            id: '146',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          },  
          {
            material: 'granit',
            count: 1,
            img: rez_m_16,
            title: 'Памятник Мужской резные',
            description: 'Памятник из гранита',
            price: '75000',
            id: '147',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          },  
          {
            material: 'granit',
            count: 1,
            img: rez_m_17,
            title: 'Памятник Мужской резные',
            description: 'Памятник из гранита',
            price: '75000',
            id: '148',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          },  
          {
            material: 'granit',
            count: 1,
            img: rez_m_18,
            title: 'Памятник Мужской резные',
            description: 'Памятник из гранита',
            price: '75000',
            id: '149',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          },  
          {
            material: 'granit',
            count: 1,
            img: rez_m_19,
            title: 'Памятник Мужской резные',
            description: 'Памятник из гранита',
            price: '75000',
            id: '150',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          },  
          {
            material: 'granit',
            count: 1,
            img: rez_m_20,
            title: 'Памятник Мужской резные',
            description: 'Памятник из гранита',
            price: '75000',
            id: '151',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          },  
          {
            material: 'granit',
            count: 1,
            img: rez_m_21,
            title: 'Памятник Мужской резные',
            description: 'Памятник из гранита',
            price: '75000',
            id: '152',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          },  
          {
            material: 'granit',
            count: 1,
            img: rez_m_22,
            title: 'Памятник Мужской резные',
            description: 'Памятник из гранита',
            price: '75000',
            id: '153',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          },  
          {
            material: 'granit',
            count: 1,
            img: rez_m_23,
            title: 'Памятник Мужской резные',
            description: 'Памятник из гранита',
            price: '75000',
            id: '154',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          },  
          {
            material: 'granit',
            count: 1,
            img: rez_m_24,
            title: 'Памятник Мужской резные',
            description: 'Памятник из гранита',
            price: '75000',
            id: '155',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          },  
          {
            material: 'granit',
            count: 1,
            img: rez_m_25,
            title: 'Памятник Мужской резные',
            description: 'Памятник из гранита',
            price: '75000',
            id: '156',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          },  
          {
            material: 'granit',
            count: 1,
            img: rez_m_26,
            title: 'Памятник Мужской резные',
            description: 'Памятник из гранита',
            price: '75000',
            id: '157',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          },  
          {
            material: 'granit',
            count: 1,
            img: rez_m_27,
            title: 'Памятник Мужской резные',
            description: 'Памятник из гранита',
            price: '75000',
            id: '157',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }
        ]
      },
      {
        material: 'mramor',  // Материалы Мрамор
        items: [  // В этот массив вставляется вся информация по памятнику
          {
            material: 'mramor',
            count: 1,
            img:man_mr_0,
            title: 'Памятник Мужской мрамор',
            description: 'Памятник из гранита',
            price: '75000',
            id: '58',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img:man_mr_1,
            title: 'Памятник Мужской мрамор',
            description: 'Памятник из гранита',
            price: '75000',
            id: '59',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img:man_mr_2,
            title: 'Памятник Мужской мрамор',
            description: 'Памятник из гранита',
            price: '75000',
            id: '60',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img:man_mr_3,
            title: 'Памятник Мужской мрамор',
            description: 'Памятник из гранита',
            price: '75000',
            id: '61',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img:man_mr_4,
            title: 'Памятник Мужской мрамор',
            description: 'Памятник из гранита',
            price: '75000',
            id: '62',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img:man_mr_5,
            title: 'Памятник Мужской мрамор',
            description: 'Памятник из гранита',
            price: '75000',
            id: '63',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img:man_mr_6,
            title: 'Памятник Мужской мрамор',
            description: 'Памятник из гранита',
            price: '75000',
            id: '64',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img:man_mr_7,
            title: 'Памятник Мужской мрамор',
            description: 'Памятник из гранита',
            price: '75000',
            id: '65',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img:man_mr_8,
            title: 'Памятник Мужской мрамор',
            description: 'Памятник из гранита',
            price: '75000',
            id: '66',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img:man_mr_9,
            title: 'Памятник Мужской мрамор',
            description: 'Памятник из гранита',
            price: '75000',
            id: '67',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img:man_mr_10,
            title: 'Памятник Мужской мрамор',
            description: 'Памятник из гранита',
            price: '75000',
            id: '68',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img:man_mr_11,
            title: 'Памятник Мужской мрамор',
            description: 'Памятник из гранита',
            price: '75000',
            id: '69',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img:man_mr_12,
            title: 'Памятник Мужской мрамор',
            description: 'Памятник из гранита',
            price: '75000',
            id: '70',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img:man_mr_13,
            title: 'Памятник Мужской мрамор',
            description: 'Памятник из гранита',
            price: '75000',
            id: '71',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img:man_mr_14,
            title: 'Памятник Мужской мрамор',
            description: 'Памятник из гранита',
            price: '75000',
            id: '72',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img:man_mr_15,
            title: 'Памятник Мужской мрамор',
            description: 'Памятник из гранита',
            price: '75000',
            id: '73',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img:man_mr_16,
            title: 'Памятник Мужской мрамор',
            description: 'Памятник из гранита',
            price: '75000',
            id: '74',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img:man_mr_17,
            title: 'Памятник Мужской мрамор',
            description: 'Памятник из гранита',
            price: '75000',
            id: '75',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img:man_mr_18,
            title: 'Памятник Мужской мрамор',
            description: 'Памятник из гранита',
            price: '75000',
            id: '76',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img:man_mr_19,
            title: 'Памятник Мужской мрамор',
            description: 'Памятник из гранита',
            price: '75000',
            id: '77',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img:man_mr_20,
            title: 'Памятник Мужской мрамор',
            description: 'Памятник из гранита',
            price: '75000',
            id: '78',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img:man_mr_21,
            title: 'Памятник Мужской мрамор',
            description: 'Памятник из гранита',
            price: '75000',
            id: '79',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img:man_mr_22,
            title: 'Памятник Мужской мрамор',
            description: 'Памятник из гранита',
            price: '75000',
            id: '80',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img:man_mr_23,
            title: 'Памятник Мужской мрамор',
            description: 'Памятник из гранита',
            price: '75000',
            id: '81',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img:man_mr_24,
            title: 'Памятник Мужской мрамор',
            description: 'Памятник из гранита',
            price: '75000',
            id: '82',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img:man_mr_25,
            title: 'Памятник Мужской мрамор',
            description: 'Памятник из гранита',
            price: '75000',
            id: '83',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img:man_mr_26,
            title: 'Памятник Мужской мрамор',
            description: 'Памятник из гранита',
            price: '75000',
            id: '84',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img:man_mr_27,
            title: 'Памятник Мужской мрамор',
            description: 'Памятник из гранита',
            price: '75000',
            id: '85',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img:man_mr_28,
            title: 'Памятник Мужской мрамор',
            description: 'Памятник из гранита',
            price: '75000',
            id: '86',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img:man_mr_29,
            title: 'Памятник Мужской мрамор',
            description: 'Памятник из гранита',
            price: '75000',
            id: '87',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img:man_mr_30,
            title: 'Памятник Мужской мрамор',
            description: 'Памятник из гранита',
            price: '75000',
            id: '88',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img:man_mr_31,
            title: 'Памятник Мужской мрамор',
            description: 'Памятник из гранита',
            price: '75000',
            id: '89',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img:man_mr_32,
            title: 'Памятник Мужской мрамор',
            description: 'Памятник из гранита',
            price: '75000',
            id: '90',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img:man_mr_33,
            title: 'Памятник Мужской мрамор',
            description: 'Памятник из гранита',
            price: '75000',
            id: '91',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img:man_mr_34,
            title: 'Памятник Мужской мрамор',
            description: 'Памятник из гранита',
            price: '75000',
            id: '92',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img:man_mr_35,
            title: 'Памятник Мужской мрамор',
            description: 'Памятник из гранита',
            price: '75000',
            id: '93',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img:man_mr_36,
            title: 'Памятник Мужской мрамор',
            description: 'Памятник из гранита',
            price: '75000',
            id: '94',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img:man_mr_37,
            title: 'Памятник Мужской мрамор',
            description: 'Памятник из гранита',
            price: '75000',
            id: '95',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img:man_mr_38,
            title: 'Памятник Мужской мрамор',
            description: 'Памятник из гранита',
            price: '75000',
            id: '96',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img:man_mr_39,
            title: 'Памятник Мужской мрамор',
            description: 'Памятник из гранита',
            price: '75000',
            id: '97',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img:man_mr_40,
            title: 'Памятник Мужской мрамор',
            description: 'Памятник из гранита',
            price: '75000',
            id: '98',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img:man_mr_41,
            title: 'Памятник Мужской мрамор',
            description: 'Памятник из гранита',
            price: '75000',
            id: '99',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img:man_mr_42,
            title: 'Памятник Мужской мрамор',
            description: 'Памятник из гранита',
            price: '75000',
            id: '100',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img:man_mr_43,
            title: 'Памятник Мужской мрамор',
            description: 'Памятник из гранита',
            price: '75000',
            id: '101',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img:man_mr_44,
            title: 'Памятник Мужской мрамор',
            description: 'Памятник из гранита',
            price: '75000',
            id: '102',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img:man_mr_45,
            title: 'Памятник Мужской мрамор',
            description: 'Памятник из гранита',
            price: '75000',
            id: '103',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img:man_mr_46,
            title: 'Памятник Мужской мрамор',
            description: 'Памятник из гранита',
            price: '75000',
            id: '104',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img:man_mr_47,
            title: 'Памятник Мужской мрамор',
            description: 'Памятник из гранита',
            price: '75000',
            id: '105',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img:man_mr_48,
            title: 'Памятник Мужской мрамор',
            description: 'Памятник из гранита',
            price: '75000',
            id: '106',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img:man_mr_49,
            title: 'Памятник Мужской мрамор',
            description: 'Памятник из гранита',
            price: '75000',
            id: '107',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img:man_mr_50,
            title: 'Памятник Мужской мрамор',
            description: 'Памятник из гранита',
            price: '75000',
            id: '108',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img:man_mr_51,
            title: 'Памятник Мужской мрамор',
            description: 'Памятник из гранита',
            price: '75000',
            id: '109',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img:man_mr_52,
            title: 'Памятник Мужской мрамор',
            description: 'Памятник из гранита',
            price: '75000',
            id: '110',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img:man_mr_53,
            title: 'Памятник Мужской мрамор',
            description: 'Памятник из гранита',
            price: '75000',
            id: '111',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img:man_mr_54,
            title: 'Памятник Мужской мрамор',
            description: 'Памятник из гранита',
            price: '75000',
            id: '112',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img:man_mr_55,
            title: 'Памятник Мужской мрамор',
            description: 'Памятник из гранита',
            price: '75000',
            id: '113',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img:man_mr_56,
            title: 'Памятник Мужской мрамор',
            description: 'Памятник из гранита',
            price: '75000',
            id: '114',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img:man_mr_57,
            title: 'Памятник Мужской мрамор',
            description: 'Памятник из гранита',
            price: '75000',
            id: '115',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img:man_mr_58,
            title: 'Памятник Мужской мрамор',
            description: 'Памятник из гранита',
            price: '75000',
            id: '116',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img:man_mr_59,
            title: 'Памятник Мужской мрамор',
            description: 'Памятник из гранита',
            price: '75000',
            id: '117',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img:man_mr_60,
            title: 'Памятник Мужской мрамор',
            description: 'Памятник из гранита',
            price: '75000',
            id: '118',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img:man_mr_61,
            title: 'Памятник Мужской мрамор',
            description: 'Памятник из гранита',
            price: '75000',
            id: '119',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img:man_mr_62,
            title: 'Памятник Мужской мрамор',
            description: 'Памятник из гранита',
            price: '75000',
            id: '120',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img:man_mr_63,
            title: 'Памятник Мужской мрамор',
            description: 'Памятник из гранита',
            price: '75000',
            id: '121',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img:man_mr_64,
            title: 'Памятник Мужской мрамор',
            description: 'Памятник из гранита',
            price: '75000',
            id: '122',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img:man_mr_65,
            title: 'Памятник Мужской мрамор',
            description: 'Памятник из гранита',
            price: '75000',
            id: '123',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img:man_mr_66,
            title: 'Памятник Мужской мрамор',
            description: 'Памятник из гранита',
            price: '75000',
            id: '124',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img:man_mr_67,
            title: 'Памятник Мужской мрамор',
            description: 'Памятник из гранита',
            price: '75000',
            id: '125',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img:man_mr_68,
            title: 'Памятник Мужской мрамор',
            description: 'Памятник из гранита',
            price: '75000',
            id: '126',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img:man_mr_69,
            title: 'Памятник Мужской мрамор',
            description: 'Памятник из гранита',
            price: '75000',
            id: '127',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img:man_mr_70,
            title: 'Памятник Мужской мрамор',
            description: 'Памятник из гранита',
            price: '75000',
            id: '128',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img:man_mr_71,
            title: 'Памятник Мужской мрамор',
            description: 'Памятник из гранита',
            price: '75000',
            id: '129',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img:man_mr_72,
            title: 'Памятник Мужской мрамор',
            description: 'Памятник из гранита',
            price: '75000',
            id: '130',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }
        ]
      },
      {
        material: 'poly',  // Материалы Полиамид
        items: [  // В этот массив вставляется вся информация по памятнику
          {
            material: 'poly',
            count: 1,
            img:p_0,
            title: 'Памятники из полиамида',
            description: 'Памятник из гранита',
            price: '75000',
            id: '159',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          },
          {
            material: 'poly',
            count: 1,
            img:p_1,
            title: 'Памятники из полиамида',
            description: 'Памятник из гранита',
            price: '75000',
            id: '160',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          },
          {
            material: 'poly',
            count: 1,
            img:p_2,
            title: 'Памятники из полиамида',
            description: 'Памятник из гранита',
            price: '75000',
            id: '162',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          } 
        ]
      } 
    ] 
  }, 
  {
    category: 'family', // Семейные
    monuments: [
      {
        material: 'granit', // Материалы Гранит
        items: [  // В этот массив вставляется вся информация по памятнику
          { 
            material: 'granit',
            count: 1,
            img:gr_f_0,
            title: 'Памятник семейный гравюра',
            description: 'Памятник из гранита',
            price: '75000',
            id: '163',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'granit',
            count: 1,
            img:gr_f_1,
            title: 'Памятник семейный гравюра',
            description: 'Памятник из гранита',
            price: '75000',
            id: '164',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'granit',
            count: 1,
            img:gr_f_2,
            title: 'Памятник семейный гравюра',
            description: 'Памятник из гранита',
            price: '75000',
            id: '165',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'granit',
            count: 1,
            img:gr_f_3,
            title: 'Памятник семейный гравюра',
            description: 'Памятник из гранита',
            price: '75000',
            id: '166',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'granit',
            count: 1,
            img:gr_f_4,
            title: 'Памятник семейный гравюра',
            description: 'Памятник из гранита',
            price: '75000',
            id: '167',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'granit',
            count: 1,
            img:gr_f_5,
            title: 'Памятник семейный гравюра',
            description: 'Памятник из гранита',
            price: '75000',
            id: '168',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'granit',
            count: 1,
            img:gr_f_6,
            title: 'Памятник семейный гравюра',
            description: 'Памятник из гранита',
            price: '75000',
            id: '169',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'granit',
            count: 1,
            img:gr_f_7,
            title: 'Памятник семейный гравюра',
            description: 'Памятник из гранита',
            price: '75000',
            id: '170',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'granit',
            count: 1,
            img:gr_f_8,
            title: 'Памятник семейный гравюра',
            description: 'Памятник из гранита',
            price: '75000',
            id: '171',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'granit',
            count: 1,
            img:gr_f_9,
            title: 'Памятник семейный гравюра',
            description: 'Памятник из гранита',
            price: '75000',
            id: '172',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'granit',
            count: 1,
            img:gr_f_10,
            title: 'Памятник семейный гравюра',
            description: 'Памятник из гранита',
            price: '75000',
            id: '173',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'granit',
            count: 1,
            img:gr_f_11,
            title: 'Памятник семейный гравюра',
            description: 'Памятник из гранита',
            price: '75000',
            id: '174',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'granit',
            count: 1,
            img:gr_f_12,
            title: 'Памятник семейный гравюра',
            description: 'Памятник из гранита',
            price: '75000',
            id: '175',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'granit',
            count: 1,
            img:gr_f_13,
            title: 'Памятник семейный гравюра',
            description: 'Памятник из гранита',
            price: '75000',
            id: '176',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'granit',
            count: 1,
            img:gr_f_14,
            title: 'Памятник семейный гравюра',
            description: 'Памятник из гранита',
            price: '75000',
            id: '177',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'granit',
            count: 1,
            img:gr_f_15,
            title: 'Памятник семейный гравюра',
            description: 'Памятник из гранита',
            price: '75000',
            id: '178',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'granit',
            count: 1,
            img:gr_f_16,
            title: 'Памятник семейный гравюра',
            description: 'Памятник из гранита',
            price: '75000',
            id: '179',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'granit',
            count: 1,
            img:gr_f_17,
            title: 'Памятник семейный гравюра',
            description: 'Памятник из гранита',
            price: '75000',
            id: '180',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'granit',
            count: 1,
            img:gr_f_18,
            title: 'Памятник семейный гравюра',
            description: 'Памятник из гранита',
            price: '75000',
            id: '181',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'granit',
            count: 1,
            img:gr_f_19,
            title: 'Памятник семейный гравюра',
            description: 'Памятник из гранита',
            price: '75000',
            id: '182',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'granit',
            count: 1,
            img:gr_f_20,
            title: 'Памятник семейный гравюра',
            description: 'Памятник из гранита',
            price: '75000',
            id: '183',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'granit',
            count: 1,
            img:gr_f_21,
            title: 'Памятник семейный гравюра',
            description: 'Памятник из гранита',
            price: '75000',
            id: '184',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'granit',
            count: 1,
            img:gr_f_22,
            title: 'Памятник семейный гравюра',
            description: 'Памятник из гранита',
            price: '75000',
            id: '185',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'granit',
            count: 1,
            img:gr_f_23,
            title: 'Памятник семейный гравюра',
            description: 'Памятник из гранита',
            price: '75000',
            id: '186',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'granit',
            count: 1,
            img:gr_f_24,
            title: 'Памятник семейный гравюра',
            description: 'Памятник из гранита',
            price: '75000',
            id: '187',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'granit',
            count: 1,
            img:gr_f_25,
            title: 'Памятник семейный гравюра',
            description: 'Памятник из гранита',
            price: '75000',
            id: '188',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'granit',
            count: 1,
            img:gr_f_26,
            title: 'Памятник семейный гравюра',
            description: 'Памятник из гранита',
            price: '75000',
            id: '189',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'granit',
            count: 1,
            img:gr_f_27,
            title: 'Памятник семейный гравюра',
            description: 'Памятник из гранита',
            price: '75000',
            id: '190',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'granit',
            count: 1,
            img:gr_f_28,
            title: 'Памятник семейный гравюра',
            description: 'Памятник из гранита',
            price: '75000',
            id: '191',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'granit',
            count: 1,
            img:gr_f_29,
            title: 'Памятник семейный гравюра',
            description: 'Памятник из гранита',
            price: '75000',
            id: '192',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'granit',
            count: 1,
            img:gr_f_30,
            title: 'Памятник семейный гравюра',
            description: 'Памятник из гранита',
            price: '75000',
            id: '193',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'granit',
            count: 1,
            img:gr_f_31,
            title: 'Памятник семейный гравюра',
            description: 'Памятник из гранита',
            price: '75000',
            id: '194',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'granit',
            count: 1,
            img:gr_f_32,
            title: 'Памятник семейный гравюра',
            description: 'Памятник из гранита',
            price: '75000',
            id: '195',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'granit',
            count: 1,
            img:gr_f_33,
            title: 'Памятник семейный гравюра',
            description: 'Памятник из гранита',
            price: '75000',
            id: '196',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'granit',
            count: 1,
            img:gr_f_34,
            title: 'Памятник семейный гравюра',
            description: 'Памятник из гранита',
            price: '75000',
            id: '197',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'granit',
            count: 1,
            img:gr_f_35,
            title: 'Памятник семейный гравюра',
            description: 'Памятник из гранита',
            price: '75000',
            id: '198',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'granit',
            count: 1,
            img:gr_f_36,
            title: 'Памятник семейный гравюра',
            description: 'Памятник из гранита',
            price: '75000',
            id: '199',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'granit',
            count: 1,
            img:gr_f_37,
            title: 'Памятник семейный гравюра',
            description: 'Памятник из гранита',
            price: '75000',
            id: '200',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'granit',
            count: 1,
            img:gr_f_38,
            title: 'Памятник семейный гравюра',
            description: 'Памятник из гранита',
            price: '75000',
            id: '201',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'granit',
            count: 1,
            img:gr_f_39,
            title: 'Памятник семейный гравюра',
            description: 'Памятник из гранита',
            price: '75000',
            id: '202',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: rez_f_0,
            title: 'Памятник семейный резные',
            description: 'Памятник из гранита',
            price: '75000',
            id: '223',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'granit',
            count: 1,
            img: rez_f_1,
            title: 'Памятник семейный резные',
            description: 'Памятник из гранита',
            price: '75000',
            id: '224',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'granit',
            count: 1,
            img: rez_f_2,
            title: 'Памятник семейный резные',
            description: 'Памятник из гранита',
            price: '75000',
            id: '225',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'granit',
            count: 1,
            img: rez_f_3,
            title: 'Памятник семейный резные',
            description: 'Памятник из гранита',
            price: '75000',
            id: '226',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }
        ]
      },
      {
        material: 'mramor',  // Материалы Мрамор
        items: [  // В этот массив вставляется вся информация по памятнику
          {
            material: 'mramor',
            count: 1,
            img: fam_m_0,
            title: 'Памятник семейный мрамор',
            description: 'Памятник из гранита',
            price: '75000',
            id: '203',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img: fam_m_1,
            title: 'Памятник семейный мрамор',
            description: 'Памятник из гранита',
            price: '75000',
            id: '204',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img: fam_m_2,
            title: 'Памятник семейный мрамор',
            description: 'Памятник из гранита',
            price: '75000',
            id: '205',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img: fam_m_3,
            title: 'Памятник семейный мрамор',
            description: 'Памятник из гранита',
            price: '75000',
            id: '206',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img: fam_m_4,
            title: 'Памятник семейный мрамор',
            description: 'Памятник из гранита',
            price: '75000',
            id: '207',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img: fam_m_5,
            title: 'Памятник семейный мрамор',
            description: 'Памятник из гранита',
            price: '75000',
            id: '208',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <h1>Всю информацию можно уточнить по телефону:
                      ${telephone}</h1>
            `
          }, 
          {
            material: 'mramor',
            count: 1,
            img: fam_m_6,
            title: 'Памятник семейный мрамор',
            description: 'Памятник из гранита',
            price: '75000',
            id: '209',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img: fam_m_7,
            title: 'Памятник семейный мрамор',
            description: 'Памятник из гранита',
            price: '75000',
            id: '210',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img: fam_m_8,
            title: 'Памятник семейный мрамор',
            description: 'Памятник из гранита',
            price: '75000',
            id: '211',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img: fam_m_9,
            title: 'Памятник семейный мрамор',
            description: 'Памятник из гранита',
            price: '75000',
            id: '212',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img: fam_m_10,
            title: 'Памятник семейный мрамор',
            description: 'Памятник из гранита',
            price: '75000',
            id: '213',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img: fam_m_11,
            title: 'Памятник семейный мрамор',
            description: 'Памятник из гранита',
            price: '75000',
            id: '214',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img: fam_m_12,
            title: 'Памятник семейный мрамор',
            description: 'Памятник из гранита',
            price: '75000',
            id: '215',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img: fam_m_13,
            title: 'Памятник семейный мрамор',
            description: 'Памятник из гранита',
            price: '75000',
            id: '216',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img: fam_m_14,
            title: 'Памятник семейный мрамор',
            description: 'Памятник из гранита',
            price: '75000',
            id: '217',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img: fam_m_15,
            title: 'Памятник семейный мрамор',
            description: 'Памятник из гранита',
            price: '75000',
            id: '218',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img: fam_m_16,
            title: 'Памятник семейный мрамор',
            description: 'Памятник из гранита',
            price: '75000',
            id: '219',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img: fam_m_17,
            title: 'Памятник семейный мрамор',
            description: 'Памятник из гранита',
            price: '75000',
            id: '220',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img: fam_m_18,
            title: 'Памятник семейный мрамор',
            description: 'Памятник из гранита',
            price: '75000',
            id: '221',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img: fam_m_19,
            title: 'Памятник семейный мрамор',
            description: 'Памятник из гранита',
            price: '75000',
            id: '222',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }
        ]
      },
      {
        material: 'poly',  // Материалы Полиамид
        items: [  // В этот массив вставляется вся информация по памятнику
          {
            material: 'poly',
            count: 1,
            img:p_0,
            title: 'Памятники из полиамида',
            description: 'Памятник из гранита',
            price: '75000',
            id: '227',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          },
          {
            material: 'poly',
            count: 1,
            img:p_1,
            title: 'Памятники из полиамида',
            description: 'Памятник из гранита',
            price: '75000',
            id: '228',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          },
          {
            material: 'poly',
            count: 1,
            img:p_2,
            title: 'Памятники из полиамида',
            description: 'Памятник из гранита',
            price: '75000',
            id: '229',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }
        ]
      } 
    ]  
  }, 
  {
    category: 'children', // Детские
    monuments: [
      {
        material: 'granit', // Материалы Гранит
        items: [  // В этот массив вставляется вся информация по памятнику
         
        ]
      },
      {
        material: 'mramor',  // Материалы Мрамор
        items: [  // В этот массив вставляется вся информация по памятнику
          {
            material: 'mramor',
            count: 1,
            img:ch_m_0,
            title: 'Памятник для детей',
            description: 'Памятник из гранита',
            price: '75000',
            id: '230',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          },
          {
            material: 'mramor',
            count: 1,
            img:ch_m_1,
            title: 'Памятник для детей',
            description: 'Памятник из гранита',
            price: '75000',
            id: '231',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          },
          {
            material: 'mramor',
            count: 1,
            img:ch_m_2,
            title:'Памятник для детей',
            description: 'Памятник из гранита',
            price: '75000',
            id: '232',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          },
          {
            material: 'mramor',
            count: 1,
            img:ch_m_3,
            title: 'Памятник для детей',
            description: 'Памятник из гранита',
            price: '75000',
            id: '233',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          },
        ]
      },
      {
        material: 'poly',  // Материалы Полиамид
        items: [  // В этот массив вставляется вся информация по памятнику
          {
            material: 'poly',
            count: 1,
            img:p_0,
            title: 'Памятники из полиамида',
            description: 'Памятник из гранита',
            price: '75000',
            id: '234',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          },
          {
            material: 'poly',
            count: 1,
            img:p_1,
            title: 'Памятники из полиамида',
            description: 'Памятник из гранита',
            price: '75000',
            id: '235',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          },
          {
            material: 'poly',
            count: 1,
            img:p_2,
            title: 'Памятники из полиамида',
            description: 'Памятник из гранита',
            price: '75000',
            id: '236',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }
        ]
      } 
    ] 
  }, 
  {
    category: 'animals', // Животным
    monuments: [
      {
        material: 'granit', // Материалы Гранит
        items: [  // В этот массив вставляется вся информация по памятнику
          {
            material: 'granit',
            count: 1,
            img:an_0,
            title: 'Памятник для животных',
            description: 'Памятник из гранита',
            price: '75000',
            id: '237',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img:an_1,
            title: 'Памятник для животных',
            description: 'Памятник из гранита',
            price: '75000',
            id: '238',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img:an_2,
            title: 'Памятник для животных',
            description: 'Памятник из гранита',
            price: '75000',
            id: '239',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img:an_3,
            title: 'Памятник для животных',
            description: 'Памятник из гранита',
            price: '75000',
            id: '240',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img:an_4,
            title: 'Памятник для животных',
            description: 'Памятник из гранита',
            price: '75000',
            id: '241',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img:an_5,
            title: 'Памятник для животных',
            description: 'Памятник из гранита',
            price: '75000',
            id: '242',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img:an_6,
            title: 'Памятник для животных',
            description: 'Памятник из гранита',
            price: '75000',
            id: '243',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img:an_7,
            title: 'Памятник для животных',
            description: 'Памятник из гранита',
            price: '75000',
            id: '244',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img:an_8,
            title: 'Памятник для животных',
            description: 'Памятник из гранита',
            price: '75000',
            id: '245',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img:an_9,
            title: 'Памятник для животных',
            description: 'Памятник из гранита',
            price: '75000',
            id: '246',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img:an_10,
            title: 'Памятник для животных',
            description: 'Памятник из гранита',
            price: '75000',
            id: '247',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img:an_11,
            title: 'Памятник для животных',
            description: 'Памятник из гранита',
            price: '75000',
            id: '248',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img:an_12,
            title: 'Памятник для животных',
            description: 'Памятник из гранита',
            price: '75000',
            id: '249',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img:an_13,
            title: 'Памятник для животных',
            description: 'Памятник из гранита',
            price: '75000',
            id: '250',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img:an_14,
            title: 'Памятник для животных',
            description: 'Памятник из гранита',
            price: '75000',
            id: '251',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img:an_15,
            title: 'Памятник для животных',
            description: 'Памятник из гранита',
            price: '75000',
            id: '252',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img:an_16,
            title: 'Памятник для животных',
            description: 'Памятник из гранита',
            price: '75000',
            id: '253',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img:an_17,
            title: 'Памятник для животных',
            description: 'Памятник из гранита',
            price: '75000',
            id: '254',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img:an_18,
            title: 'Памятник для животных',
            description: 'Памятник из гранита',
            price: '75000',
            id: '255',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img:an_19,
            title: 'Памятник для животных',
            description: 'Памятник из гранита',
            price: '75000',
            id: '256',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img:an_20,
            title: 'Памятник для животных',
            description: 'Памятник из гранита',
            price: '75000',
            id: '257',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img:an_21,
            title: 'Памятник для животных',
            description: 'Памятник из гранита',
            price: '75000',
            id: '258',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }
        ]
      },
      {
        material: 'mramor', // Материалы Мрамор
        items: [  // В этот массив вставляется вся информация по памятнику
         
        ]
      },
      {
        material: 'poly',  // Материалы Полиамид
        items: [  // В этот массив вставляется вся информация по памятнику
          {
            material: 'poly',
            count: 1,
            img:p_0,
            title: 'Памятники из полиамида',
            description: 'Памятник из гранита',
            price: '75000',
            id: '259',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          },
          {
            material: 'poly',
            count: 1,
            img:p_1,
            title: 'Памятники из полиамида',
            description: 'Памятник из гранита',
            price: '75000',
            id: '260',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          },
          {
            material: 'poly',
            count: 1,
            img:p_2,
            title: 'Памятники из полиамида',
            description: 'Памятник из гранита',
            price: '75000',
            id: '261',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }
        ]
      } 
    ] 
  }, 
  {
    category: 'forms', // Формы памятников
    monuments: [
      {
        material: 'granit', // Материалы Гранит
        items: [  // В этот массив вставляется вся информация по памятнику
          {
            material: 'granit',
            count: 1,
            img:form_0,
            title: 'Форма памятников',
            description: 'Памятник из гранита',
            price: '75000',
            id: '262',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img:form_1,
            title: 'Форма памятников',
            description: 'Памятник из гранита',
            price: '75000',
            id: '263',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img:form_2,
            title: 'Форма памятников',
            description: 'Памятник из гранита',
            price: '75000',
            id: '264',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img:form_3,
            title: 'Форма памятников',
            description: 'Памятник из гранита',
            price: '75000',
            id: '265',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img:form_4,
            title: 'Форма памятников',
            description: 'Памятник из гранита',
            price: '75000',
            id: '266',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img:form_5,
            title: 'Форма памятников',
            description: 'Памятник из гранита',
            price: '75000',
            id: '267',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img:form_6,
            title: 'Форма памятников',
            description: 'Памятник из гранита',
            price: '75000',
            id: '268',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img:form_7,
            title: 'Форма памятников',
            description: 'Памятник из гранита',
            price: '75000',
            id: '269',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img:form_8,
            title: 'Форма памятников',
            description: 'Памятник из гранита',
            price: '75000',
            id: '270',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img:form_9,
            title: 'Форма памятников',
            description: 'Памятник из гранита',
            price: '75000',
            id: '271',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img:form_10,
            title: 'Форма памятников',
            description: 'Памятник из гранита',
            price: '75000',
            id: '272',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img:form_11,
            title: 'Форма памятников',
            description: 'Памятник из гранита',
            price: '75000',
            id: '273',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img:form_12,
            title: 'Форма памятников',
            description: 'Памятник из гранита',
            price: '75000',
            id: '274',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img:form_13,
            title: 'Форма памятников',
            description: 'Памятник из гранита',
            price: '75000',
            id: '275',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img:form_14,
            title: 'Форма памятников',
            description: 'Памятник из гранита',
            price: '75000',
            id: '276',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img:form_15,
            title: 'Форма памятников',
            description: 'Памятник из гранита',
            price: '75000',
            id: '277',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img:form_16,
            title: 'Форма памятников',
            description: 'Памятник из гранита',
            price: '75000',
            id: '278',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img:form_17,
            title: 'Форма памятников',
            description: 'Памятник из гранита',
            price: '75000',
            id: '279',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img:form_18,
            title: 'Форма памятников',
            description: 'Памятник из гранита',
            price: '75000',
            id: '280',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img:form_19,
            title: 'Форма памятников',
            description: 'Памятник из гранита',
            price: '75000',
            id: '281',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <h1>Всю информацию можно уточнить по телефону:
                      ${telephone}</h1>
            `
          },
          {
            material: 'granit',
            count: 1,
            img:form_20,
            title: 'Форма памятников',
            description: 'Памятник из гранита',
            price: '75000',
            id: '282',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img:form_21,
            title: 'Форма памятников',
            description: 'Памятник из гранита',
            price: '75000',
            id: '283',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img:form_22,
            title: 'Форма памятников',
            description: 'Памятник из гранита',
            price: '75000',
            id: '284',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img:form_23,
            title: 'Форма памятников',
            description: 'Памятник из гранита',
            price: '75000',
            id: '285',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img:form_24,
            title: 'Форма памятников',
            description: 'Памятник из гранита',
            price: '75000',
            id: '286',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img:form_25,
            title: 'Форма памятников',
            description: 'Памятник из гранита',
            price: '75000',
            id: '287',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img:form_26,
            title: 'Форма памятников',
            description: 'Памятник из гранита',
            price: '75000',
            id: '288',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img:form_27,
            title: 'Форма памятников',
            description: 'Памятник из гранита',
            price: '75000',
            id: '289',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img:form_28,
            title: 'Форма памятников',
            description: 'Памятник из гранита',
            price: '75000',
            id: '290',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img:form_29,
            title: 'Форма памятников',
            description: 'Памятник из гранита',
            price: '75000',
            id: '291',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img:form_30,
            title: 'Форма памятников',
            description: 'Памятник из гранита',
            price: '75000',
            id: '292',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img:form_31,
            title: 'Форма памятников',
            description: 'Памятник из гранита',
            price: '75000',
            id: '293',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img:form_32,
            title: 'Форма памятников',
            description: 'Памятник из гранита',
            price: '75000',
            id: '294',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img:form_33,
            title: 'Форма памятников',
            description: 'Памятник из гранита',
            price: '75000',
            id: '295',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img:form_34,
            title: 'Форма памятников',
            description: 'Памятник из гранита',
            price: '75000',
            id: '296',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img:form_35,
            title: 'Форма памятников',
            description: 'Памятник из гранита',
            price: '75000',
            id: '297',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img:form_36,
            title: 'Форма памятников',
            description: 'Памятник из гранита',
            price: '75000',
            id: '298',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img:form_37,
            title: 'Форма памятников',
            description: 'Памятник из гранита',
            price: '75000',
            id: '299',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img:form_38,
            title: 'Форма памятников',
            description: 'Памятник из гранита',
            price: '75000',
            id: '300',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img:form_39,
            title: 'Форма памятников',
            description: 'Памятник из гранита',
            price: '75000',
            id: '301',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img:form_40,
            title: 'Форма памятников',
            description: 'Памятник из гранита',
            price: '75000',
            id: '302',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img:form_41,
            title: 'Форма памятников',
            description: 'Памятник из гранита',
            price: '75000',
            id: '303',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img:form_42,
            title: 'Форма памятников',
            description: 'Памятник из гранита',
            price: '75000',
            id: '304',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img:form_43,
            title: 'Форма памятников',
            description: 'Памятник из гранита',
            price: '75000',
            id: '305',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img:form_44,
            title: 'Форма памятников',
            description: 'Памятник из гранита',
            price: '75000',
            id: '306',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img:form_45,
            title: 'Форма памятников',
            description: 'Памятник из гранита',
            price: '75000',
            id: '307',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img:form_46,
            title: 'Форма памятников',
            description: 'Памятник из гранита',
            price: '75000',
            id: '308',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img:form_47,
            title: 'Форма памятников',
            description: 'Памятник из гранита',
            price: '75000',
            id: '309',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img:form_48,
            title: 'Форма памятников',
            description: 'Памятник из гранита',
            price: '75000',
            id: '310',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img:form_49,
            title: 'Форма памятников',
            description: 'Памятник из гранита',
            price: '75000',
            id: '311',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img:form_50,
            title: 'Форма памятников',
            description: 'Памятник из гранита',
            price: '75000',
            id: '312',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img:form_51,
            title: 'Форма памятников',
            description: 'Памятник из гранита',
            price: '75000',
            id: '313',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img:form_52,
            title: 'Форма памятников',
            description: 'Памятник из гранита',
            price: '75000',
            id: '314',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img:form_53,
            title: 'Форма памятников',
            description: 'Памятник из гранита',
            price: '75000',
            id: '315',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img:form_54,
            title: 'Форма памятников',
            description: 'Памятник из гранита',
            price: '75000',
            id: '316',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img:form_55,
            title: 'Форма памятников',
            description: 'Памятник из гранита',
            price: '75000',
            id: '317',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img:form_56,
            title: 'Форма памятников',
            description: 'Памятник из гранита',
            price: '75000',
            id: '318',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img:form_57,
            title: 'Форма памятников',
            description: 'Памятник из гранита',
            price: '75000',
            id: '319',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img:form_58,
            title: 'Форма памятников',
            description: 'Памятник из гранита',
            price: '75000',
            id: '320',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img:form_59,
            title: 'Форма памятников',
            description: 'Памятник из гранита',
            price: '75000',
            id: '321',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img:form_60,
            title: 'Форма памятников',
            description: 'Памятник из гранита',
            price: '75000',
            id: '322',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img:form_61,
            title: 'Форма памятников',
            description: 'Памятник из гранита',
            price: '75000',
            id: '323',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img:form_62,
            title: 'Форма памятников',
            description: 'Памятник из гранита',
            price: '75000',
            id: '324',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img:form_63,
            title: 'Форма памятников',
            description: 'Памятник из гранита',
            price: '75000',
            id: '325',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img:form_64,
            title: 'Форма памятников',
            description: 'Памятник из гранита',
            price: '75000',
            id: '326',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img:form_65,
            title: 'Форма памятников',
            description: 'Памятник из гранита',
            price: '75000',
            id: '327',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img:form_66,
            title: 'Форма памятников',
            description: 'Памятник из гранита',
            price: '75000',
            id: '328',
            prop:`
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник 120х60х8</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Всю информацию можно уточнить по телефону:</h1>
              <h2>${telephone}</h2>
            </div>        
          `
          }, 
        ]
      },
      {
        material: 'mramor', // Материалы Мрамор
        items: [  // В этот массив вставляется вся информация по памятнику
         
        ]
      },
      {
        material: 'poly', // Материалы Полиамид
        items: [  // В этот массив вставляется вся информация по памятнику
          
        ]
      } 
    ] 
  },
]

const priceDelivery = 1200;

const arrayMaterial = [[0,0,0],[9000,19000,42500],[12000,24000,50000],[12000,25500,45500],[15000,30000,53000],[15000,31000,'По запросу'],[17000,	35000,'По запросу'],[18000,37000,'По запросу'],[21000,42000,'По запросу'],[19500,47000,'По запросу'],[24500,54000,'По запросу'],[22000,52000,'По запросу'],[28000,58000,'По запросу'],[37000,81000,'По запросу'],[47500,93000,'По запросу']]

export {
  top_product,
  about_plus,
  about_product,
  ordedr_work,
  feedback,
  answers,
  products,
  works_images,
  priceDelivery,
  API_URL,
  arrayMaterial
}

