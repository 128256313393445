import React from 'react';
import './style.css';
import { UserOutlined } from '@ant-design/icons';
import { Button } from 'antd';

function LkButton(props) {
    const { text, width, height, onClick, margin, disabled, mobile} = props;
    return (
      <div 
        style={{ 
          pointerEvents: disabled ? 'none' : 'all', 
          width: width === undefined ? '150px': width,
          height: height === undefined ? '40px' : height,
          margin: margin === undefined ? '0px' : margin,
          border: mobile ? 'none' : '1px solid #fff', 
          padding:'1px'
        }} 

        className="wrapperLKButton"
        onClick={onClick === undefined ? () => {} : () => onClick()}
      >
      {
        mobile ? <Button className="profilebtn" size={'large'} style={{ marginLeft: '13px', width: '50px', height: '50px', color:'#fff'}} ghost icon={<UserOutlined />} />  :  <div className="textLKButton">{ text }</div>
      } 
      </div>
    );
}

export default LkButton;
