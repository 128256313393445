import React from 'react'; 
import OrangeButton from '../OrangeButton';
import ExamplePage from '../ExamplePage';
import Title from '../Title';
import { ordedr_work } from '../../config';
import { useNavigate, Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';  
import images from '../../assets/images';
import './style.css';  

export default function PrivacyBlock(props) {
  const { type, mobile, transparent } = props;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const content = [];

  const cross = images.cross; 

  switch (type) {

    case 'user':
      content.push(
        <div id="toppage"  key={Date.now()} className="user_yes_screen" >
          <div  className={`${mobile ? 'mobile_user_yes_wrapper' : 'user_yes_wrapper'}`}>
          <Link className='marginTop' to='/'><img onClick={() => {}} style={{cursor:'pointer'}} src={cross} alt="exit" width="28" height="28" /></Link>
            {
              mobile ? <h4 className='marginTop'>Пользовательское соглашение</h4> : <h2 className='marginTop'>Пользовательское соглашение</h2>
            }  
            <ol className={`${mobile ? 'mobilePadding_0 mobileFont_1' : ''}`}>
              <div className='marginTop'>
              Настоящее Пользовательское соглашение <b>(далее — «Соглашение»)</b> устанавливает условия, правила и ограничения использования Платформы <a href='https://inmemory-forever.com/'>https://inmemory-forever.com/</a> <b>(далее — «Платформа»)</b> и Сервисов, представленных на Платформе. Соглашение представляет собой простую <b>(неисключительную)</b> лицензию между <b>самозанятым лицом</b> Татьяной Александровной, <b>ИНН</b> 233907730584, <b>(далее — «Администрация»)</b> и лицом, являющимся пользователем Платформы <b>(далее — «Пользователь»)</b>.
              Администрация вправе устанавливать дополнительные или иные условия использования отдельных Сервисов. В случае если договором на использование отдельного Сервиса установлены условия использования, отличные от условий, указанных в настоящем Соглашении, Администрация и Пользователь будут руководствоваться <b>договором на использование</b> соответствующего Сервиса.
              </div>
              <li><b>Общие положения</b> 
                <ol className={`${mobile ? 'mobilePadding_0 mobileFont_1' : ''}`}> 
                  <li>В настоящем Соглашении применяются следующие термины и определения:</li>
                  <div><b>Платформа</b> — совокупность Сервисов и иной информации, содержащейся в информационных системах Администрации, доступ к которым обеспечивается посредством сети «Интернет» по сетевому адресу в следующих доменах <b>(включая поддомены):</b> <a href='https://inmemory-forever.com/'>https://inmemory-forever.com/</a></div>
                  <div><b>Сервис</b> — информация, охраняемые результаты интеллектуальной деятельности, иные объекты гражданских прав, исключительное право и (или) иные имущественные права на которые принадлежат Администрации.</div>
                  <div><b>Личный кабинет</b> — совокупность защищённых техническими средствами страниц Платформы, представляющих собой персональный раздел Пользователя на Платформе, к которому Пользователь получает доступ после прохождения регистрации и/или авторизации на Платформе. Личный кабинет предназначен для заключения, исполнения, прекращения, гражданско-правовых сделок с Администрацией, использования дополнительных функциональных возможностей Платформы, просмотра и управления доступными функциональными возможностями Платформы, получения скидок на Сервис, направления в адрес Администрации сообщений, уведомлений, а также осуществления иных действий, предусмотренных явными функциями Личного кабинета.</div>
                  <li>В настоящем Соглашении могут быть использованы иные термины и определения, не указанные в <b>п. 1.1.</b> Соглашения. В этом случае толкование такого термина производится в соответствии с текстом Соглашения. В случае отсутствия однозначного толкования термина или определения в тексте Соглашения, следует
                  руководствоваться его толкованием, определённым, <b>в первую очередь,</b> — договором между Администрацией и Пользователем, <b>и в последующем</b> — нормами применимого права.</li>
                  <li>Администрация предоставляет Пользователю право использования отдельными Сервисами Платформы в пределах, установленных настоящим Соглашением и иными договорами. Право использования Сервисов Платформы предоставляется на условиях простой <b>(неисключительной)</b> лицензии. Администрация вправе устанавливать дополнительные или иные условия использования отдельных Сервисов. В случае если договором на использование отдельного Сервиса установлены условия использования, отличные от условий, указанных в настоящем Соглашении, Администрация и Пользователь будут руководствоваться договором на использование соответствующего Сервиса.</li>
                  <li><b>Акцепт</b> - полное и безоговорочное принятие Лицензиатом условий Договора путем регистрации на сайте Лицензиара.</li>
                  <li>Пользователь вправе использовать Платформу и Сервисы при соблюдении условий и обязанностей, установленных настоящим Соглашением. Используя Платформу, <b>Пользователь тем самым подтверждает, что он:</b></li>
                    <div><b>&nbsp;&nbsp;&nbsp;&nbsp;a.</b> Ознакомился с условиями настоящего Соглашения в полном объёме;</div>
                    <div><b>&nbsp;&nbsp;&nbsp;&nbsp;б.</b> Принимает все условия настоящего Соглашения полностью и безоговорочно, обязуетесь их соблюдать или прекратить использование Платформы, если Пользователь не согласен с условиями Соглашения.</div>
                    <div><b>&nbsp;&nbsp;&nbsp;&nbsp;в.</b> Соглашение может быть изменено Администрацией без какого-либо специального уведомления Пользователя. Новая редакция Соглашения и/или указанных в нём обязательных документов вступает в силу с момента размещения на Платформе либо доведения до сведения Пользователя в иной удобной форме, если иное не предусмотрено новой редакцией Соглашения. Пользователь обязуется при каждом посещении Платформы проверять актуальную редакцию Соглашения и в случае, если не согласен с каким-либо условием, прекратить использование Платформы. <b>Действующая редакция Соглашения находится по адресу:</b> <a href='https://inmemory-forever.com/useryespage'>https://inmemory-forever.com/useryespage</a></div>
                  <h4>Пользователь обязуется соблюдать следующие условия и ограничения использования Платформы и Сервисов:</h4>
                </ol>
                <ol className={`${mobile ? 'mobilePadding_0 mobileFont_1' : ''}`}> 
                  <li>Для использования Платформы и Сервисов Пользователь обязуется своими силами и за свой счёт обеспечить себя компьютером, иным необходимым Пользователю оборудованием, необходимым для использования Платформы и Сервисов;</li>
                  <li>Для использования Платформы и Сервисов Пользователь обязуется своими силами и за свой счёт обеспечить подключение своего компьютера к сети Интернет, обеспечивающей достаточную скорость и качество связи (интернет-соединения);</li>
                  <li>Для использования Платформы и Сервисов Пользователь обязуется своими силами и за свой счёт обеспечить установку на свой компьютер версией интернет-браузера, иного программного обеспечения, необходимого для использования Платформы и Сервисов, произвести своими силами настройку установленного программного обеспечения, в том числе отключить всё программные средства, препятствующие работе Платформы и Сервисов;</li>
                  <li>Пользователь не вправе использовать Платформу и Сервисы, если это прямо запрещено применимым правом, судебным актом, актом органа исполнительной власти;</li>
                  <li>Если для использования Сервиса требуется регистрация Пользователя и создание Личного кабинета, заключение отдельного договора, совершение иных действий — Пользователь должен самостоятельно осуществить регистрацию и создание Личного кабинета, заключить договор с Администрацией, совершить иные предусмотренные действия. В случае если Пользователь не согласен использовать Сервис при соблюдении представленных на Платформе условий, Пользователь не вправе предпринимать попытки использовать Сервис или требовать от Администрации предоставления Сервиса на иных условиях.</li>
                  <li>Пользователь обязуется обеспечить конфиденциальность и безопасность учётных данных от Личного кабинета и имеет право использовать ПО лишь под одной учетной записью и с одного ПК.</li>
                  <li>Пользователь не вправе предоставлять свои учётные данные, доступ в личный кабинет третьим лицам.</li>
                  <li>Пользователь обязуется исполнять все обязанности, предусмотренные договором между Администрацией и Пользователем, в том числе условия и ограничения использования результатов интеллектуальной деятельности, денежные и иные обязательства.</li>
                  <li>Пользователь при использовании Платформы и Сервисов обязуется соблюдать действующее законодательство Российской Федерации, а также иных стран, если оно применимо к отношениям, связанным с использованием Платформы и Сервисов.</li>
                  <li>Пользователь не вправе нарушать права и законные интересы Администрации, работников Администрации, других пользователей.</li>
                  <li>Пользователь не вправе использовать Платформу и Сервисы:</li>
                  в политических целях и в целях, направленных на распространение экстремизма и терроризма;
                  в целях незаконного распространения контрафактной продукции, нарушения исключительных прав Администрации и любых иных лиц.
                  <li>Пользователю запрещается копировать, модифицировать, изменять, удалять, дополнять, публиковать, передавать содержащиеся на Платформе результаты
                  интеллектуальной деятельности, создавать производные работы, изготавливать или продавать товары/оказывать услуги на их основе, воспроизводить, отображать или любым другим образом эксплуатировать или использовать такие результаты интеллектуальной деятельности без прямого разрешения Администрации. При цитировании, если цитирование допускается в силу закона или отдельного договора между Пользователем и Администрацией, Пользователь обязуется указывать ссылку на Платформу.</li>
                  <li>Пользователь обязуется соблюдать ограничения, связанные с использованием шрифтов, иных охраняемых результатов интеллектуальной деятельности согласно Разделу 4 настоящего Соглашения.</li>
                  <li>Пользователь не вправе копировать, загружать на любые физические носители, облачные хранилища (за исключением случаев, когда такая загрузка прямо и требуется Администрацией), распространять любыми способами (в том числе путём создания фотографий, скриншотов и видео) любые элементы Платформы и Сервисов, в том числе, но не ограничиваясь: товарные знаки, логотипы, иконки, шрифты, изображения шрифтов, элементы дизайна и фирменного стиля, программный код, статьи, учебники, подборы материалов, каталоги, дизайн-макеты, аудиовизуальные произведения.</li>
                  <li>Пользователь не вправе использовать Платформу, Сервисы и их элементы для использования любым противоречащим закону способом;</li>
                    <b>Не использовать программное обеспечение:</b>
                    <div>&nbsp;&nbsp;&nbsp;&nbsp;- для организации СПАМА;</div>
                    <div>&nbsp;&nbsp;&nbsp;&nbsp;- для намеренной передачи сообщений, которые могут привести к нарушению программного обеспечения;</div>
                    <div>&nbsp;&nbsp;&nbsp;&nbsp;- для передачи сообщений оскорбительного или клеветнического характера, сообщений, разжигающих национальную, расовую или религиозную рознь, сообщений порнографической или другой предосудительной направленности;</div>
                    <div>&nbsp;&nbsp;&nbsp;&nbsp;- для передачи сообщений, вводящих получателей в заблуждение, например, отправленных от чужого имени или сообщающих ложную информацию;</div>
                    <div>&nbsp;&nbsp;&nbsp;&nbsp;- для передачи сообщений содержащих неоднозначную трактовку.</div>
                  <li>Пользователь не вправе копировать программный код, дизайн Платформы и Сервисов, создавать копии Сервисов, Платформы;</li>
                  <li>Пользователь не вправе размещать на Платформе персональные данные третьих лиц, изображения и фотографии третьих лиц;</li>
                  <li>Пользователь не вправе использовать на Платформе адреса электронной почты третьих лиц, банковские карты и иные средства платежа третьих лиц, если от них не было заранее получено надлежащим образом оформленное согласие;</li>
                  <li>Пользователь не вправе продавать свой Личный кабинет или делать его предметом иных сделок;</li>
                  <li>Пользователь не вправе размещать на Платформе рекламу, оферты о продаже товаров, работ, услуг, информацию политического, агитационного характера;</li>
                  <li>Пользователь не вправе размещать на Платформе информацию, распространение которой каким-либо образом запрещено или ограничено в Российской Федерации;</li>
                  <li>Пользователь не вправе использовать на Платформе ненормативную (нецензурную лексику), изображения эротического характера, информацию, способную причинить нравственные страдания или оскорбить третье лицо, а также информацию, причиняющую вред детям;</li>
                  <li>Пользователь не вправе размещать на Платформе информацию, порочащую честь, деловую репутацию, достоинство Администрации, а также любого третьего лица;</li>
                  <li>Пользователь обязуется в срок, не превышающий <b>1 (один)</b> час с момента направления Администрацией обращения в адрес Пользователя прекратить любое нарушение настоящего Соглашения и (или) иного договора, заключённого между Пользователем и Администрацией;</li>
                  <li>Пользователь обязуется в срок, не превышающий <b>24 (двадцать четыре)</b> часа с момента направления Администрацией требования предоставить ответ на запрос, затрагивающий вопрос соблюдения Пользователем Соглашения и иных договоров.</li>
                </ol>
              </li>
              <li><b>Регистрация Пользователя и использование Платформы</b>
                <ol className={`${mobile ? 'mobilePadding_0 mobileFont_1' : ''}`}> 
                  <li>Использование полных функциональных возможностей Платформы, включая использование Сервисов, допускается только после прохождения Пользователем регистрации и авторизации на Платформе в соответствии с установленной Администрацией процедурой.</li>
                  <li>Перечень функциональных возможностей Платформы, использование которых требует предварительной регистрации и/или авторизации, а также принятия в необходимых случаях дополнительных документов на использование Сервисов, определяется по единоличному усмотрению Администрации и может время от времени изменяться. Администрация доводит до Пользователя информацию о необходимости регистрации и (или) авторизации непосредственно на Платформе, без публикации отдельных списков и перечней. Администрация вправе в одностороннем порядке изменить условия использования Сервисов, установив или отменив условие об обязательной регистрации и (или) авторизации для доступа к Сервису.</li>

                  <li>По завершении процедуры регистрации создаётся уникальная учётная запись, связанная с Личным кабинетом Пользователя на Платформе, которая необходима для использования большинства функциональных возможностей Платформы и Сервисов.</li>
                  <li>Для регистрации Пользователь обязуется предоставить достоверную и полную информацию по вопросам, предлагаемым в форме регистрации, и поддерживать эту информацию в актуальном состоянии. Если Пользователь предоставляет неверную информацию или у Администрации есть основания полагать, что предоставленная Пользователем информация неполна или недостоверна, Администрация имеет право по своему усмотрению заблокировать или удалить учётную запись Пользователя, а также отказать Пользователю в использовании Платформы и Сервисов полностью или в определённой части.</li>
                  <li>Любые действия, совершённые под учётной записью Пользователя, считаются совершёнными соответствующим Пользователем. В случае несанкционированного доступа к учётной записи Пользователя, или распространения доступа, Пользователь обязан незамедлительно сообщить об этом Администрации.</li>
                  <li>Администрация оставляет за собой право в любой момент потребовать от Пользователя подтверждения информации, указанной при регистрации, либо в Личном кабинете, а также запросить в связи с этим подтверждающие документы, непредоставление которых, по усмотрению Администрации, может быть приравнено к предоставлению недостоверной информации и повлечь последствия, предусмотренные <b>п. 2.4.</b> Соглашения. В случае если данные Пользователя, указанные в предоставленных им документах, не соответствуют данным, указанным при регистрации, а также в случае, когда данные, указанные при регистрации, не позволяют идентифицировать пользователя, Администрация вправе применить меры, указанные в <b>п. 2.4.</b> Соглашения.</li>
                  <li>Информация, содержащаяся в Личном кабинете, обрабатывается Администрацией в соответствии с Политикой обработки персональных данных Администрации.</li>
                  <li>Администрация вправе использовать адреса электронной почты, номера телефонов Пользователя и другие данные Пользователя, указанные Пользователем при регистрации на Платформе, в Личном кабинете, при использовании Платформы и Сервисов, для отправки Пользователю информации, рекламы, предложений Исполнителя на адреса электронной почты, через мессенджеры, короткие SMS-сообщения, посредством телефонных звонков и интернет-звонков с использованием сети Интернет, по сети подвижной связи и иным способом.</li>
                  <li>Пользователь обязуется использовать Платформу и Сервисы только в законных целях, способами, установленными Соглашением и отдельными договорами на использование Сервисов.</li>
                  <li>Пользователь обязуется соблюдать условия и ограничения использования Платформы и Сервисов, установленные в <b>п. 1.6.</b> Соглашения, иными договорами между Пользователем и Администрацией.</li>

                  <li>Пользователь несёт единоличную полную ответственность в случае нарушения условий использования Платформы и Сервисов.</li>
                  <li>В случае, если Администрация понесёт убытки в результате нарушения Пользователем Соглашения, Пользователь обязуется компенсировать Администрации убытки в полном объёме.</li>
                  <li>В момент размещения каких-либо материалов на Платформе Пользователь безвозмездно предоставляет Администрации (или подтверждает, что владелец прав на такие материалы предоставил Администрации) бессрочное, безотзывное право на использование, воспроизведение, изменение, редактирование, копирование, опубликование, перевод и распространение таких материалов на территории всех стран мира и/или включение таких материалов в другие произведения в любой форме посредством использования любых технологий, которые известны в настоящее время или могут быть изобретены в будущем, на весь срок охраны авторского права, предусмотренный применимым правом, в отношении таких материалов.</li>
                  <li>Администрация вправе устанавливать лимиты по объёму и составу размещаемых Пользователем информационных материалов, а также вводить иные технические ограничения использования Платформы, которые время от времени будут доводиться до сведения Пользователей способом, определяемым Администрацией по своему усмотрению.</li>
                  <li>Администрация оставляет за собой право блокировать, исключать, удалять материалы с Платформы без согласия Пользователя, либо временно ограничить доступ к ним.</li>
                </ol>
              </li>
              <li><b>Ответственность и гарантии</b> 
                <ol className={`${mobile ? 'mobilePadding_0 mobileFont_1' : ''}`}>  
                  <li>Администрация не несёт ответственности за нарушение условий настоящего Соглашения, если такое нарушение вызвано действием обстоятельств непреодолимой силы (форс-мажор), включая: действия и акты органов государственной власти, должностных лиц, в том числе судебные акты, запретительные акты органов исполнительной власти, пожар, наводнение, землетрясение, другие стихийные действия, эпидемии, пандемии, отсутствие электроэнергии и/или сбои работы компьютерной сети (нарушение работы линий связи, неисправность оборудования и т. п.), приостановка работы сервисов, обслуживающих Платформу, забастовки, гражданские волнения, беспорядки, любые иные обстоятельства, не ограничиваясь перечисленным, которые могут повлиять на выполнение Администрацией условий настоящего Соглашения.</li>
                  <li>Администрация не несёт ответственности за неисполнение или ненадлежащее исполнение обязательств по Соглашению, а также возможные убытки, возникшие в том числе, но не ограничиваясь, в результате:</li>
                    <div><b>&nbsp;&nbsp;&nbsp;&nbsp;а.</b> Неправомерных действий третьих лиц, направленных на нарушения информационной безопасности или нормального функционирования Платформы;</div>
                    <div><b>&nbsp;&nbsp;&nbsp;&nbsp;б.</b> Сбоев в работе Платформы, вызванных ошибками в коде, компьютерными вирусами и иными посторонними фрагментами кода в программном обеспечении Платформы;</div>
                    <div><b>&nbsp;&nbsp;&nbsp;&nbsp;в.</b> Отсутствия (невозможности установления, прекращения и пр.) интернет-соединений между сервером Пользователя и сервером Платформы;</div>
                    <div><b>&nbsp;&nbsp;&nbsp;&nbsp;г.</b> Проведения государственными и муниципальными органами оперативно-розыскных мероприятий, контрольно-надзорных, проверочных мероприятий;</div>
                    <div><b>&nbsp;&nbsp;&nbsp;&nbsp;д.</b> Установления государственного регулирования (или регулирования иными организациями) хозяйственной деятельности коммерческих организаций в сети Интернет и/или установления указанными субъектами разовых ограничений, затрудняющих или делающих невозможным исполнение Соглашения или его части;</div>
                    <div><b>&nbsp;&nbsp;&nbsp;&nbsp;е.</b> Других случаев, связанных с действиями/бездействием третьих лиц, направленными на ухудшение общей ситуации с использованием сети Интернет и/или компьютерного оборудования, существовавшей на момент заключения Соглашения;</div>
                    <div><b>&nbsp;&nbsp;&nbsp;&nbsp;ж.</b> Выполнения Администрацией профилактических работ на Платформе.</div>
                  <li>Все споры, вытекающие из правоотношений по настоящему Соглашению, разрешаются путём переговоров. В случае, если Стороны не придут к решению возникших между ними споров в ходе переговоров, такие споры должны быть переданы на рассмотрение в соответствующий суд Российской Федерации по месту нахождения Администрации с обязательным соблюдением претензионного порядка. Срок ответа на претензию составляет <b>10 рабочих дней</b> с даты получения претензии. Претензия должна направляться в адрес Администрации по адресу, указанному в Едином государственном реестр индивидуальных предпринимателей.</li>
                  <li>Бездействие со стороны Администрации в случае нарушения Пользователем либо иными Пользователями положений Соглашений не лишает Администрацию права предпринять соответствующие действия в защиту своих интересов позднее, а также не означает отказ Администрации от своих прав в случае совершения в последующем подобных либо сходных нарушений.</li>
                </ol>
              </li>
              <li><b>Интеллектуальная собственность</b> 
                <ol className={`${mobile ? 'mobilePadding_0 mobileFont_1' : ''}`}> 
                  <li>Содержимое Платформы, в том числе, но не ограничиваясь: подбор и расположением материалов, программный код Платформы, элементы дизайна, являются результатами интеллектуальной деятельности, исключительное право на которые в полном объеме принадлежит Администрации. Отдельные результаты интеллектуальной деятельности, размещённые на Платформе, используются Администрацией на основании лицензионного соглашения между Администрацией и правообладателем.</li>
                    <div>Исключительное право на вышеуказанные результаты интеллектуальной деятельности не переходят к Пользователю в результате пользования Платформы и заключения Соглашения.</div> 
                    <div>Пользователь вправе использовать указанные результаты интеллектуальной деятельности только при условии, если Администрация предоставила Пользователю такое право.</div> 
                  <li>Отдельные результаты интеллектуальной деятельности используются Администрацией на Платформе на основании лицензионного соглашения между Администрацией и правообладателем. Пользователю запрещается использовать такие результаты интеллектуальной деятельности, если право их использования Пользователем не было предоставлено непосредственно правообладателем. В случае если право использования таких результатов интеллектуальной деятельности предоставлено Пользователю, он обязуется соблюдать установленные лицензией правила и ограничения использования материалов.</li>
                </ol>
              </li>
              <li><b>Персональные данные и их обработка</b> 
                <ol className={`${mobile ? 'mobilePadding_0 mobileFont_1' : ''}`}> 
                  <li>Цель, условия, порядок и иные условия обработки персональных данных Пользователя определены <b>Политикой обработки персональных данных,</b> доступной по адресу: <a href='https://inmemory-forever.com/personalpage'>https://inmemory-forever.com/personalpage</a></li>
                </ol>
              </li>
              <li><b>Заключительные положения</b>
                <ol className={`${mobile ? 'mobilePadding_0 mobileFont_1' : ''}`}>
                  <li>Настоящее Соглашение регулируется и толкуется в соответствии с законодательством Российской Федерации как <b>лицензионный договор.</b> Вопросы, не урегулированные настоящим Соглашением, подлежат разрешению в соответствии с законодательством Российской Федерации. Все возможные споры, связанные с использованием Платформы по настоящему Соглашению регулируются материальным и процессуальным правом Российской Федерации, при этом нормы коллизионного регулирования и нормы международного частного права не применяются к отношениям между Пользователем и Администрацией. В случае если отношения, связанные с использованием Сервиса, регулируются отдельным договором между Администрацией и Пользователем, правила разрешения споров, соблюдения претензионного порядка, вопросы применимого права определяются таким отдельным договором.</li>
                  <li>Если по тем или иным причинам одно или несколько положений настоящего Соглашения будут признаны недействительными или не имеющими юридической силы, это не оказывает влияния на действительность или применимость остальных положений Соглашения.</li>
                  <li>Администрация вправе в любой момент по своему усмотрению в одностороннем порядке изменять условия Соглашения, при этом такие изменения вступают в силу в момент опубликования новой версии Соглашения на Платформе. <b>При каждом посещении Платформы Пользователь обязуется знакомиться с новой версией Соглашения.</b></li>
                </ol>
              </li>

              <h4>Продолжение использования Платформы будет означать согласие Пользователя с условиями актуальной редакции Соглашения.</h4>
              <h5 className='marginTop'>Агапова Татьяна Александровна,</h5>
              <h5>ИНН 233907730584</h5> 
              <h5>Эл.почта life777history@gmail.com,</h5>
              <h5>Тел.+7-960-327-28-01</h5>
              <h5>Copyright © 2022-2023 inmemory-forever.com. Все права защищены</h5>
            </ol>
          </div>
        </div> 
      )
      break;
    case 'licence':
      content.push(
       <div key={Date.now()} className="ofer_screen" >
        <div className={`${mobile ? 'mobileOfer_wrapper' : 'ofer_wrapper'}`}>
        <Link className='marginTop' to='/'><img onClick={() => {}} style={{cursor:'pointer'}} src={cross} alt="exit" width="28" height="28" /></Link>
          {
            mobile ? <h4 className='marginTop'>ЛИЦЕНЗИОННЫЙ ДОГОВОР - ОФЕРТА</h4> : <h2 className='marginTop'>ЛИЦЕНЗИОННЫЙ ДОГОВОР - ОФЕРТА</h2>
          } 
          <div className='marginTop'>
          О предоставлении права использования программы для <b>ЭВМ </b> 
          Настоящий Лицензионный договор <b>(далее — «Договор»)</b> является офертой <b>Самозанятого лица</b> Агапова Татьяна Александровна, <b>ИНН 233907730584</b> именуемого в дальнейшем <b>«Лицензиар»</b>, адресованной физическому лицу либо физическому лицу, являющемуся плательщиком налога на профессиональный доход <b>(далее — «Самозанятое лицо»)</b>, юридическому лицу или индивидуальному предпринимателю, именуемому в дальнейшем <b>«Лицензиат»</b>, и регулирует отношения между Лицензиатом и Лицензиаром, возникающие в связи с использованием веб-сайта <a href='https://inmemory-forever.com/'>https://inmemory-forever.com/</a> и размещенного на указанном веб-сайте программного обеспечения. <b>Действующая редакция настоящего Договора</b> доступна для ознакомления по адресу: <a href='https://inmemory-forever.com/licencepage'>https://inmemory-forever.com/licencepage</a>.
          Настоящий Договор признается заключенным с даты его акцепта Лицензиатом. Под акцептом в целях настоящего Договора признается факт регистрации на сайте Лицензиара в порядке, сроки и на условиях, определенных настоящим Договором. Заключая настоящий Договор, Лицензиат также полностью принимает условия Пользовательского соглашения, текст которого размещен Лицензиаром в сети Интернет по адресу: <a href='https://inmemory-forever.com/useryespage'>https://inmemory-forever.com/useryespage </a>
          На правоотношения сторон, возникающие на основании данного договора не распространяются положения Закона <b>"О защите прав потребителей"</b>.
          </div>
          <ol className={`${mobile ? 'mobilePadding_0 mobileFont_1' : ''}`}>
            <li><b>Термины и определения</b>
              <ol className={`${mobile ? 'mobilePadding_0 mobileFont_1' : ''}`}>
                <li><b>Программа</b> — результат интеллектуальной деятельности, программа для <b>ЭВМ</b>, исключительные права на использование которой принадлежат Лицензиару.</li>
                <li><b>Лицензиар</b> — индивидуальный предприниматель Агапова Татьяна Александровна, <b>ИНН 233907730584.</b></li>
                <li><b>Лицензиат</b> — юридическое лицо, индивидуальный предприниматель или самозанятое лицо, а также физическое лицо, заключившее с Лицензиаром Договор на условиях, содержащихся в оферте.</li>
                <li><b>Тип лицензии</b> — тарифный план, избираемый Лицензиатом из списка, размещенного Лицензиаром в сети Интернет на странице: <a href='https://inmemory-forever.com/'>https://inmemory-forever.com/</a></li>
                <li><b>Простая (неисключительная) лицензия</b> — неисключительное право использовать экземпляр Программы на территории всего Мира в предпринимательских целях под обозначенным Лицензиаром именем, без права переделки или иной переработки, без права распространения.</li>
                <li><b>Личный кабинет</b> — персональная страница Лицензиата по адресу: <a href='https://inmemory-forever.com/'>https://inmemory-forever.com/</a> посредством которой осуществляется доступ к функциям Программы.</li>
                <li><b>Учетная запись</b> — контактные данные, хранящиеся в Программе, позволяющие идентифицировать и авторизовать Лицензиата как пользователя Программы.</li>
                <li><b>Регистрация</b> — процесс создания новой Учетной записи Лицензиата, с целью получения доступа в Личный кабинет, путем ввода Учетных данных в форму регистрации на Интернет-сайте.</li>
                <li><b>Аутентификационные данные</b> — уникальный логин <b>(login)</b> и пароль <b>(password)</b> Лицензиата для доступа в Личный кабинет.</li>
                <li><b>Оплата</b> — перечисление Лицензиатом лицензионного вознаграждения за право использования Программы в соответствии с выбранным Типом лицензии. Оплата может производиться любым из способов, доступных через интерфейс Программы. Выбор способа Оплаты осуществляется Лицензиатом самостоятельно.</li>
                <li><b>Подписка</b> — Оплата за использование Лицензии банковской картой с автоматическим списанием Лицензиаром с банковского (карточного) счета Лицензиата, использованного для Оплаты. При этом размер вознаграждения будет определен исходя из стоимости соответствующего Типа лицензии на дату списания <b>(п. 7.4 Договора).</b></li>
                <li><b>Сайт Лицензиара</b> — страница в сети Интернет: <a href='https://inmemory-forever.com/'>https://inmemory-forever.com/</a></li>
                <li><b>Оферта</b> - публичное предложение Лицензиара, адресованное любому лицу,
                заключить с ним настоящий Договор.</li>
                <li><b>Акцепт</b> - полное и безоговорочное принятие Лицензиатом условий Договора путем регистрации на сайте Лицензиара.</li>
              </ol> 
            </li>
            <li><b>Предмет договора</b>
              <ol className={`${mobile ? 'mobilePadding_0 mobileFont_1' : ''}`}>
                <li>Предметом настоящего Договора является передача Лицензиаром неисключительных прав использования результата интеллектуальной деятельности, Программы, Лицензиату на условиях простой (неисключительной) лицензии путем воспроизведения Программы (подключения к Программе через сеть Интернет), исключительно для самостоятельного использования Лицензиатом без права сублицензирования третьим лицам.</li>
                <li>Доступ к Программе предоставляется (открывается) Лицензиаром в течение одного рабочего дня с момента поступления лицензионного вознаграждения, указанного в <b>пункте 7.1 Договора,</b> на расчетный счет Лицензиара.</li>   
                <li>Лицензиар создает Учетную запись Пользователя на основании данных, предоставленных Лицензиатом при Регистрации.</li>
                <li>В рамках настоящего Договора поставляется только тот функционал и оказываются только те услуги, которые были заявлены на сайте Лицензиара на момент внесения Лицензиатом оплаты за выбранный Тип лицензии на использование Программы. Все дополнительные услуги, любые манипуляции с данными, доработки, предлагаемые Лицензиатом, функции, не описанные на сайте Лицензиара, оказываются Лицензиаром на платной основе в рамках дополнительного договора, заключаемого между</li>
                <li>В момент акцепта настоящей оферты Лицензиат подтверждает и соглашается, что приобретаемая Программа предоставляется на условиях <b>«как есть» и «как доступно»</b> без каких-либо дополнительных гарантий. До акцепта настоящей Оферты Лицензиат поставлен в известность и соглашается с тем, что Лицензиар не гарантирует и не предоставляет каких-либо заверений о том:</li>  
                <ul>
                  <div>&nbsp;&nbsp;&nbsp;&nbsp;- что Программа полностью будет удовлетворять потребностям (требованиям) Лицензиата;</div>
                  <div>&nbsp;&nbsp;&nbsp;&nbsp;- что Программа будет работать непрерывно и в ней полностью отсутствуют ошибки;</div>
                  <div>&nbsp;&nbsp;&nbsp;&nbsp;- что результат от использования Программы будет полностью соответствовать ожиданиям Лицензиата.</div>
                </ul>
                <li>Лицензиат подтверждает, что Программа приобретается на свой страх и риск. Лицензиар не несет ответственность за несоответствие Программы субъективным ожиданиям Лицензиата.</li>
                <li>Лицензиар не предоставляет Лицензиату право на использование принадлежащей ему Программы для создания нового результата интеллектуальной деятельности.</li>
              </ol>
            </li>
            <li><b>Исключительные права</b>
              <ol className={`${mobile ? 'mobilePadding_0 mobileFont_1' : ''}`}>
                <li>Программа, включая все ее компоненты, является объектом интеллектуальной собственности Лицензиара и защищается нормами законодательства Российской Федерации и международными соглашениями в сфере интеллектуальной собственности. Нарушение целостности Программы, нарушение систем защиты Программы, копирование исходного кода Программы или ее компонентов полностью или в части, а также иные действия, нарушающие исключительные права Лицензиара на Программу не допускаются. Лицензиат несет гражданско-правовую, административную либо уголовную ответственность в соответствии с законодательством Российской Федерации, в том числе обязанность исполнить решение суда по требованию Лицензиара или правообладателя о признании права, о пресечении действий, нарушающих право или создающих угрозу его нарушения, о возмещении убытков, о публикации решения суда о допущенном нарушении с указанием действительного правообладателя, о возмещении убытков либо выплате компенсации.</li>
                <li>Лицензиар гарантирует, что обладает всем необходимым объемом прав на Программу для предоставления их Лицензиату, включая документацию к Программе.</li>
                <li>Право использования Программы предоставляется только Лицензиату, а также работникам Лицензиата и уполномоченным им лицам в целях использования Лицензиатом Программы, без права передачи иным третьим лицам, исключительно в объеме, оговоренном Договором, если нет письменного согласия Лицензиара на иное.</li>
                <li>Лицензиату не предоставляется право собственности на Программу и ее компоненты, а только право использования Программы и ее компонентов в соответствии с условиями, которые обозначены в Договоре.</li>
                <li>Лицензиату не предоставляются никакие права на использование товарных знаков и знаков обслуживания Лицензиара и (или) его партнеров.</li>
                <li>Лицензиат не вправе копировать, распространять Программу и ее компоненты в любой форме, в том числе в виде исходного кода, каким-либо способом, в том числе сдавать в аренду, безвозмездное пользование, либо в прокат.</li>
              </ol>
            </li> 
            <li><b>Условия использования программы</b>
              <ol className={`${mobile ? 'mobilePadding_0 mobileFont_1' : ''}`}>
                <li>Лицензиат вправе использовать Программу следующими способами:
                  <ol>
                    <li>Круглосуточно (за исключением времени проведения профилактических работ) получать доступ к Программе с использованием сети Интернет и воспроизводить графическую часть (рабочий интерфейс) Программы на экране персонального компьютера и/или иного конечного устройства, предусмотренного документацией к Программе) Лицензиата.</li>
                    <li>Использовать все оплаченные функциональные возможности Программы в соответствии с лицензией.</li>
                    <li>Размножать относящуюся к Программе документацию для личного пользования.</li>
                  </ol>
                </li>
                <li>Лицензиар не предоставляет Лицензиату услуги связи, не организует для него возможность доступа к информационным системам информационно-телекоммуникационных сетей, в том числе к сети Интернет, и не осуществляет деятельность по приему, обработке, хранению, передаче, доставке сообщений электросвязи.</li>
                <li>Лицензиар не несет ответственности за сохранность аккаунтов Лицензиата и возможные потери и затраты при продвижении аккаунтов Лицензиата в социальных сетях.</li>
              </ol>
            </li>
          <li><b>Права и обязанности сторон</b> 
            <ol className={`${mobile ? 'mobilePadding_0 mobileFont_1' : ''}`}>
              <li><b>Лицензиар обязан:</b>
                <ol>
                  <li>Обеспечить круглосуточную (за исключением времени проведения профилактических работ) доступность сайта Лицензиара для использования Программы.</li>
                  <li>Предоставить Лицензиату возможность (открыть доступ) использования Программы:</li>
                  <div>&nbsp;&nbsp;&nbsp;&nbsp;- при приобретении бесплатной демо-версии ПО в течении <b>1 рабочего дня</b> с момента регистрации на сайте Лицензиара;</div>
                  <div>&nbsp;&nbsp;&nbsp;&nbsp;- при приобретении платной версии ПО в течение <b>1 рабочего дня</b> с момента поступления лицензионного вознаграждения, определяемого согласно <b>пункта 7.1.</b> Договора, на расчетный счет Лицензиара,</div>
                  <li>Без взимания дополнительного вознаграждения предоставлять Лицензиату техническую поддержку по вопросам, связанным с функциональностью Программы, особенностями эксплуатации на стандартных конфигурациях поддерживаемых операционных, почтовых и иных систем в порядке и на условиях, указанных в технической̆ документации к ней.</li>
                  <li>Обеспечивать конфиденциальность данных, размещенных Лицензиатом в Программе, на весь период их нахождения на сервере Лицензиара.</li>
                  <li>Исправить обнаруженные Лицензиатом ошибки Программы в максимально короткие сроки. Стороны соглашаются, что точное определение срока устранения ошибки не может быть установлено, так как Программа тесно взаимодействует с другими программами сторонних разработчиков, операционной̆ системой и аппаратными ресурсами компьютеров Лицензиата, и работоспособность и время устранения проблем в полной мере не зависят только от Лицензиара.</li>
                </ol>
              </li>
              <li><b>Лицензиар вправе:</b>
                <ol className={`${mobile ? 'mobilePadding_0 mobileFont_1' : ''}`}>
                  <li>Вносить изменения в Программу без уведомления Лицензиата в любое время и по любой причине, в том числе, но не ограничиваясь, в целях удовлетворения потребностей других лицензиатов, требований конкурентоспособности, или в целях исполнения требований нормативных актов. Лицензиар оставляет за собой право добавлять новые свойства и функциональные возможности Программы или удалять из Программы уже существующие свойства и функциональные возможности.</li>
                  <li>Блокировать доступ Лицензиата к Программе при нарушении Лицензиатом условий Договора или Пользовательского соглашения, текст которого размещен Лицензиаром в сети Интернет на странице: <a href='https://inmemory-forever.com/useryespage'>https://inmemory-forever.com/useryespage</a> При этом внесенная оплата Лицензиату не возвращается и является штрафной неустойкой за его неправомерные действия.</li>
                  <li>Предоставлять Лицензиару все сведения и документы, необходимые для выполнения Лицензиаром своих обязательств по Договору.</li>         
                  <li>Указывать при Регистрации достоверные данные.</li>
                  <li>Лицензиат полностью ответственен за сохранность своих учетных данных и за убытки или иной ущерб, которые могут возникнуть по причине несанкционированного использования этой информации. По факту утери, несанкционированного доступа к учетным данным или возможности возникновения такой ситуации Лицензиат имеет право направить запрос Лицензиару на смену учетных данных. В этом случае Лицензиар по согласованию с Лицензиатом осуществляет блокировку доступа к учетным данным Лицензиата. Срок подобной блокировки согласовывается Сторонами дополнительно. При этом Лицензиар не несет ответственности за извещение любых третьих лиц о временной приостановке доступа Лицензиата к его информационной системе и за возможные последствия, возникшие в результате отсутствия такого извещения.</li>
                  <li>Не осуществлять действий, направленных на модификацию и/или любое изменение программы.</li>
                  <li>Не распространять, в том числе, но не ограничиваясь, не копировать Программу или её части и/или осуществлять иные действия, направленные на извлечение коммерческой выгоды в отношениях с третьими лицами из использования Программы.</li>
                  <li>Лицензиат не вправе заключать сублицензионные договора на использование Программы.</li>
                  <li>При отправке Сообщения получателю, в обязательном порядке получить от него согласие. Предоставить Согласие Исполнителю по его требованию.</li>
                  <li>Отправлять сообщения только от своего имени.</li>
                  <li><b>Не использовать программное обеспечение:</b></li>
                    <div>&nbsp;&nbsp;&nbsp;&nbsp;- для организации СПАМА;</div>
                    <div>&nbsp;&nbsp;&nbsp;&nbsp;- для намеренной передачи сообщений, которые могут привести к нарушению программного обеспечения;</div>
                    <div>&nbsp;&nbsp;&nbsp;&nbsp;- для передачи сообщений оскорбительного или клеветнического характера, сообщений, разжигающих национальную, расовую или религиозную рознь, сообщений порнографической или другой предосудительной направленности;</div>
                    <div>&nbsp;&nbsp;&nbsp;&nbsp;- для передачи сообщений, вводящих получателей в заблуждение, например, отправленных от чужого имени или сообщающих ложную информацию;</div>
                    <div>&nbsp;&nbsp;&nbsp;&nbsp;- в политических целях и в целях, направленных на распространение экстремизма и терроризма;</div>
                    <div>&nbsp;&nbsp;&nbsp;&nbsp;- для передачи сообщений содержащих неоднозначную трактовку.</div>
                  <li>Лицензиат обязуется соблюдать дисциплину и общепринятые нормы поведения, в частности, проявлять уважение к Лицензиару и его представителям. В случае несоблюдения Лицензиатом указанного требования Лицензиар (его представитель) вправе расторгнуть настоящий лицензионный договор в одностороннем порядке.</li>
                </ol>
              </li>
              <li><b>Лицензиат вправе:</b> 
                <ol className={`${mobile ? 'mobilePadding_0 mobileFont_1' : ''}`}>
                  <li>Получать круглосуточный (за исключением времени проведения профилактических работ) доступ к сайту Лицензиара для использования Программы.</li>
                  <li>Использовать все оплаченные функциональные возможности Программы в соответствии с описанием выбранного Типа лицензии способами, не нарушающими условия Договора, Пользовательского соглашения, текст которого размещен Лицензиаром в сети Интернет на странице: <a href='https://inmemory-forever.com/'>https://inmemory-forever.com/</a> законодательства Российской Федерации.</li>
                  <li>Сохранять конфиденциальность информации, материалов, документов, ставших доступными ему в рамках исполнения настоящего Договора.</li>
                  <li>Не предоставлять Лицензиару отчеты об использовании Программы.</li> 
                  <li>Лицензиат гарантирует, что:</li>
                  <div> &nbsp;&nbsp;&nbsp;&nbsp;- При обработке персональных данных Лицензиатом соблюдены все права субъектов персональных данных, предусмотренные законодательством Российской Федерации в области защиты персональных данных.</div>
                  <div> &nbsp;&nbsp;&nbsp;&nbsp;- Лицензиатом получено согласие субъектов персональных данных на обработку принадлежащих им персональных данных.</div>
                </ol>
              </li>
            </ol>
          </li>
          <li><b>Срок действия и порядок расторжения договора</b>
            <ol className={`${mobile ? 'mobilePadding_0 mobileFont_1' : ''}`}>
              <li>Договор вступает в силу с даты осуществления Лицензиатом полного и безоговорочного акцепта Договора, в соответствии с преамбулой настоящего Договора и статьей 438 Гражданского Кодекса Российской Федерации — оплаты лицензионного вознаграждения Лицензиара в полном объеме, согласно условиям Договора, размещенного в сети Интернет на странице: <a href='https://inmemory-forever.com/licencepage'>https://inmemory-forever.com/licencepage</a> и действует до истечения срока соответствующей лицензии, выбранной Лицензиатом.</li>
              <li>Договор автоматически пролонгируется на новый срок при оплате каждого следующего выбранного Типа лицензии, размещенного в сети Интернет на странице: <a href='https://inmemory-forever.com/'>https://inmemory-forever.com/</a> , а в случае, если право использования Программы предоставляется бесплатно — в случае продолжения использования Программы Лицензиатом после истечения срока действия лицензии.</li>
              <li>Настоящий Договор действует на всей территории всего Мира.</li>
                
              <li>Все споры, возникающие по Договору, решаются сторонами путем переговоров, а в случае невозможности урегулирования споров — путем судебного урегулирования, претензионный порядок урегулирования споров обязателен для сторон, срок рассмотрения претензионного письма и направления ответа на него составляет <b>10 (десять)</b> календарных дней с даты его получения.</li>
              <li>Договор может быть расторгнут по соглашению сторон, решению суда, а также Лицензиаром в одностороннем порядке, установленном настоящим Договором, при условии проведения всех взаиморасчетов, связанных с выполнением условий настоящего Договора или в порядке, предусмотренном действующим законодательством Российской Федерации.</li>
              <li>Лицензиат вправе в любое время отказаться от использования ПО. При этом, денежные средства, списанные в счет оплаты за отчетный период, в котором было принято решение об отказе от использования Программы, перерасчету и частичному возврату не подлежат.</li>
              <li>При существенном нарушении Лицензиатом обязанности выплатить Лицензиару в установленный Договором срок лицензионного вознаграждения, Лицензиар вправе отказаться в одностороннем порядке от Договора и потребовать возмещения убытков, причиненных его расторжением. Договор прекращается по истечении тридцатидневного срока с момента получения уведомления об отказе от Договора, если в этот срок Лицензиат не исполнил обязанность выплатить вознаграждение.</li>
              <li>При невозможности прийти к согласию в досудебном порядке споры между Лицензиатом и Лицензиаром подлежат рассмотрению в суде по местунахождения Лицензиара.</li>
            </ol>
          </li>
          <li><b>Стоимость услуг по договору, порядок оплаты</b>
            <ol className={`${mobile ? 'mobilePadding_0 mobileFont_1' : ''}`}>
              <li>Стоимость права использования Программы <b>(простая (неисключительная)
              лицензия)</b>, устанавливается в сети Интернет на странице: <a href='https://inmemory-forever.com/'>https://inmemory-forever.com/</a></li>
              <li>Лицензиат оплачивает Лицензиару лицензионное вознаграждение за право использования Программы в размере, указанном на сайте Лицензиара, в соответствии с выбранным Типом лицензии.</li>
              <li>Лицензиат оплачивает выставленный Лицензиаром счет путем единовременного перечисления в срок, указанный в таком счете, всей суммы лицензионного вознаграждения, указанной в счете, на банковский счет Лицензиара по реквизитам, указанным в счете.</li>
              <li>Датой исполнения Лицензиатом обязанности по оплате Договора выставленного Лицензиаром в адрес Лицензиата счета, является дата поступления денежных средств, в полном объеме, на расчетный счет Лицензиара.</li>
              
              <li>В случае, если оплата производится по Подписке, действие Договора будет продлено после внесения оплаты за следующий срок использования Программы.</li>
              <li>Стоимость права использования Программы (лицензионное вознаграждение, устанавливаемое в порядке, указанном в <b>пункте 7.1. Договора</b>) налогом на добавленную стоимость не облагается на основании <b>подпункта 26 пункта 2 статьи 149 Налогового кодекса Российской Федерации.</b></li>
              <li>В случае, если в течение пяти календарных дней с даты окончания срока действия выбранного Типа лицензии, Лицензиар не получил претензий Лицензиата, связанных с объемом предоставленных прав, то считается что неисключительное право использования Программы предоставлено Лицензиату в соответствии с Договором в полном объеме и надлежащим образом.</li>
              <li>Досрочное расторжение настоящего Договора не является основанием для возврата лицензионного вознаграждения.</li>
            </ol>
          </li>
          <li><b>Ответственность сторон</b>
            <ol className={`${mobile ? 'mobilePadding_0 mobileFont_1' : ''}`}>
            <li>За нарушение условий Договора Лицензиар и Лицензиат несут ответственность согласно действующему законодательству Российской Федерации.</li>
            <li>Лицензиар не несет ответственности за задержки, перебои в работе и невозможность полноценного использования собственных ресурсов Лицензиара, происходящие прямо или косвенно по причине действия или бездействия самого Лицензиата и третьих лиц и/или неработоспособностью информационных каналов, находящихся за пределами собственных ресурсов Лицензиара.</li>
            <li>Лицензиат соглашается с тем, что для работы с Программой Лицензиату необходимо использовать программное обеспечение (веб-браузеры, операционные системы и прочее) и оборудование (персональные компьютеры, сетевое оборудование, удаленные серверы и прочее) произведенное и предоставленное третьими лицами, и Лицензиар не может нести ответственность за качество их работы.</li>
            <li>Лицензиар не несет ответственности за неполученную прибыль и упущенную выгоду, а также за любые косвенные убытки, понесенные Лицензиатом в период использования или неиспользования Программы, в том числе в период проведения профилактических работ.</li>
            <li>Лицензиар не несет ответственности за утерю Лицензиатом Аутентификационных данных, а также не несет ответственности за ущерб, как прямой, так и косвенный, понесенный Лицензиатом вследствие получения доступа к Личному кабинету третьими лицами в результате утери Лицензиатом Аутентификационных данных либо их похищения третьими лицами.</li>
            <li>Лицензиар не несет ответственности за действия и решения Лицензиата, принятые на основании информации, полученной Лицензиатом при использовании Программы,

            их последствия, а также прямые и косвенные убытки, включая упущенную выгоду, возникшие в результате применения Программы.</li>
            <li>Любая ответственность Лицензиара, вне зависимости от оснований для ее возникновения, будет ограничена суммой, уплаченной Лицензиатом в счет лицензионного вознаграждения Лицензиара за предоставление прав на использование Программы.</li>
            <li>Лицензиат несет самостоятельную ответственность за возможные убытки, возникшие в результате утери или похищения третьими лицами Учетных данных, указанных им при Регистрации, а также Аутентификационных данных.</li>
            <li>Лицензиат несет самостоятельную ответственность за соблюдение при использовании Программы требований действующего законодательства Российской Федерации, а также всех прав и законных интересов третьих лиц.</li>
            <li>Лицензиар не несет ответственности за содержание, достоверность и полноту информации, полученной Лицензиатом при использовании Программы.</li>
            <li>Стороны освобождаются от ответственности за неисполнение или ненадлежащее исполнение обязательств по Договору, если надлежащее исполнение оказалось невозможным вследствие непреодолимой силы, то есть чрезвычайных и непредотвратимых при данных условиях обстоятельств, под которыми понимаются: запретительные действия органов власти, гражданские волнения, эпидемии, блокада, эмбарго, землетрясения, наводнения, пожары или другие стихийные бедствия. в случае наступления этих обстоятельств, Сторона обязана в течение <b>5(пяти) рабочих дней</b> уведомить об этом другую Сторону.</li>
            </ol> 
          </li>
          <li><b>Прочие условия</b> 
            <ol className={`${mobile ? 'mobilePadding_0 mobileFont_1' : ''}`}>
            <li>К положениям Договора применяется законодательство Российской Федерации. Вопросы, не урегулированные Договором, разрешаются в соответствии с законодательством Российской Федерации и нормами международного права.</li>
            <li>В случае, если какая-либо часть Договора будет признана утратившей юридическую силу и не подлежащей исполнению, остальные части Договора сохраняют свою юридическую силу и подлежат исполнению.</li>
            <li>Принимая условия Договора, Лицензиат дает согласие Лицензиару на сбор, хранение и обработку своих персональных данных, в том числе на передачу персональных данных правообладателю и иным третьим лицам в рамках исполнения обязательств по Договору и требований Федерального Закона Российской Федерации <b>от 27.07.2006 No 152-ФЗ «о персональных данных».</b> Лицензиат гарантирует, что при предоставлении Лицензиару персональных данных иных лиц, Лицензиатом от таких лиц получены соответствующие согласия.</li>

            <li>Принимая условия Договора, Лицензиат дает согласие на получение дополнительной информации и информационных рассылок по указанным при регистрации на сайте и в Программе адресам электронной почты и телефонам.</li>
            <li>Согласно пункту <b>2 статьи 310</b> Гражданского кодекса Российской Федерации односторонний отказ Лицензиата от настоящего Договора не допускается. Добровольное прекращение Лицензиатом использования Программы (в том числе, удаление Учетной записи Пользователя) не признается отказом Лицензиата от настоящего Договора (вне зависимости от факта уведомления об этом Лицензиара) и не влечет обязанности Лицензиара по перерасчету и (или) возврату лицензионного вознаграждения, уплаченного Лицензиатом в порядке, предусмотренном <b>пунктами 7.1—7.4 Договора.</b></li>
            <li>Во избежание неоднозначного толкования, предоставляя права использования программы для ЭВМ, Лицензиар не оказывает Лицензиату каких-либо услуг, в частности, но не ограничиваясь, Лицензиар не оказывает услуги по предоставлению технических, организационных, информационных и иных возможностей, осуществляемых с использованием информационных технологий и систем, для продвижения, рекламы продукции клиента и сбора, обработки и систематизации информации о покупателях клиента.</li>
            </ol>
          </li>
          <li className='marginTop'><b>Реквизиты Лицензиара</b></li> 
              <h5>Агапова Татьяна Александровна,</h5>
              <h5>ИНН 233907730584</h5> 
              <h5>Эл.почта life777history@gmail.com,</h5>
              <h5>Тел.+7-960-327-28-01</h5>
              <h5>Copyright © 2022-2023 inmemory-forever.com. Все права защищены</h5>   
          </ol> 

        </div>
      </div>
      )
      break;
    case 'personal':
      content.push(
       <div key={Date.now()} className="politics_screen" >
        <div className={`${mobile ? 'mobilePolitics_wrapper' : 'politics_wrapper'}`}>
        <Link className='marginTop' to='/'><img onClick={() => {}} style={{cursor:'pointer'}} src={cross} alt="exit" width="28" height="28" /></Link>
            {
              mobile ? <h3 className='marginTop'>Согласие на обработку данных</h3> : <h2 className='marginTop'>Согласие на обработку данных</h2>
            }   
            {
              mobile ? <h4 style={{ textAlign:'center' }} className='marginTop'>Политика в отношении обработки персональных данных</h4> : <h4 className='marginTop'>Политика в отношении обработки персональных данных</h4>
            }    
          <div className={`${mobile ? 'mobileFont_1 marginTop' : 'marginTop'}`}>Настоящая политика обработки персональных данных составлена в соответствии с требованиями Федерального закона <b>от 27.07.2006. No152-ФЗ «О персональных данных»</b> и определяет порядок обработки персональных данных и меры по обеспечению безопасности персональных данных <b>самозанятым лицом</b> Агапова Татьяна Александровна, <b>ИНН</b> 233907730584 <b>(далее – Оператор).</b></div>
          <ol className={`${mobile ? 'mobilePadding_0 mobileFont_1' : ''}`}>
          <li><b>Общие положения</b>
            <ol>
              <li>Оператор ставит своей важнейшей целью и условием осуществления своей деятельности соблюдение прав и свобод человека и гражданина при обработке его персональных данных, в том числе защиты прав на неприкосновенность частной жизни, личную и семейную тайну.</li>
              <li>Настоящая политика Оператора в отношении обработки персональных данных <b>(далее – Политика)</b> применяется ко всей информации, которую Оператор может получить о посетителях веб-сайта: <a href='https://inmemory-forever.com/'>https://inmemory-forever.com/</a> и других сайтов Оператора.</li>
            </ol>
          </li>
          <li><b>Основные понятия, используемые в Политике</b>
            <ol className={`${mobile ? 'mobilePadding_0 mobileFont_1' : ''}`}>
              <li><b>Автоматизированная обработка персональных данных</b> – обработка персональных данных с помощью средств вычислительной техники;</li>
              <li><b>Блокирование персональных данных</b> – временное прекращение обработки персональных данных (за исключением случаев, если обработка необходима для уточнения персональных данных);</li>
              <li><b>Веб-сайт</b> – совокупность графических и информационных материалов, а также программ для ЭВМ и баз данных, обеспечивающих их доступность в сети интернет по сетевому адресу: <a href='https://inmemory-forever.com/'>https://inmemory-forever.com/</a></li>
              <li><b>Информационная система персональных данных</b> — совокупность содержащихся в базах данных персональных данных, и обеспечивающих их обработку информационных технологий и технических средств;</li>
              <li><b>Обезличивание персональных данных</b> — действия, в результате которых невозможно определить без использования дополнительной информации принадлежность персональных данных конкретному Пользователю или иному субъекту персональных данных;</li>
              <li><b>Обработка персональных данных</b> – любое действие (операция) или совокупность действий (операций), совершаемых с использованием средств автоматизации или без использования таких средств с персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных;</li>
              <li><b>Оператор</b> – государственный орган, муниципальный орган, юридическое или физическое лицо, самостоятельно или совместно с другими лицами организующие и (или) осуществляющие обработку персональных данных, а также определяющие цели обработки персональных данных, состав персональных данных, подлежащих обработке, действия (операции), совершаемые с персональными данными;</li>
              <li><b>Персональные данные</b> – любая информация, относящаяся прямо или косвенно к определенному или определяемому Пользователю веб-сайта: <a href='https://inmemory-forever.com/'>https://inmemory-forever.com/</a> и других сайтов Оператора;</li>
              <li><b>Пользователь</b> – любой посетитель веб-сайта: <a href='https://inmemory-forever.com/'>https://inmemory-forever.com/</a> и других сайтов Оператора;</li>
              <li><b>Предоставление персональных данных</b> – действия, направленные на раскрытие персональных данных определенному лицу или определенному кругу лиц;</li>
              <li><b>Распространение персональных данных</b> – любые действия, направленные на раскрытие персональных данных неопределенному кругу лиц (передача персональных данных) или на ознакомление с персональными данными неограниченного круга лиц, в том числе обнародование персональных данных в средствах массовой информации, размещение в информационно-телекоммуникационных сетях или предоставление доступа к персональным данным каким-либо иным способом;</li>
              <li><b>Трансграничная передача персональных данных</b> – передача персональных данных на территорию иностранного государства органу власти иностранного государства, иностранному физическому или иностранному юридическому лицу;</li>
              <li><b>Уничтожение персональных данных</b> – любые действия, в результате которых персональные данные уничтожаются безвозвратно с невозможностью дальнейшего восстановления содержания персональных данных в информационной системе персональных данных и (или) результате которых уничтожаются материальные носители персональных данных.</li>
            </ol>
          </li>
          <li><b>Оператор может обрабатывать следующие персональные данные Пользователя</b>
            <ol className={`${mobile ? 'mobilePadding_0 mobileFont_1' : ''}`}>
              <li>Фамилия, имя, отчество;</li>
              <li>Электронный адрес;</li>
              <li>Номера телефонов;</li>
              <li>Названия аккаунтов Пользователя в соцсетях .</li>
              <li>Также на сайте происходит сбор и обработка обезличенных данных о посетителях <b>(в т.ч. файлов «cookie»)</b> с помощью сервисов интернет-статистики (Яндекс Метрика и Гугл Аналитика и других).</li>
              <li>Вышеперечисленные данные далее по тексту Политики объединены общим понятием <b>Персональные данные.</b></li>
            </ol>
          </li>
          <li><b>Информация об обработке персональных данных</b></li>
          <div>Обработка персональных данных осуществляется Оператором на законной и справедливой основе, действуя разумно и добросовестно и на основе принципов: законности целей и способов обработки персональных данных; добровольности предоставления персональных данных; добросовестности; соответствия целей обработки персональных данных целям, заранее определенным и заявленным при сборе персональных данных; соответствия объема и характера обрабатываемых персональных данных, способов обработки персональных данных целям обработки персональных данных.</div>
          <li><b>Цели Политики</b></li>
          <div>Обеспечение безопасности персональных данных пользователей Сайта в процессе их получения, обработки (использования), передачи, хранения, утилизации и иных действий, предусмотренных Законом;</div>
          <div>обеспечение должного уровня доверия пользователей Сайта к предлагаемым Оператором сервисам;</div>
          <div>обеспечение надлежащего исполнения принятых Оператором на себя обязательств перед пользователями Сайта в процессе исполнения заключенных с помощью Сайта и его сервисов договоров.</div>
          <li><b>Персональные данные. Цели сбора и обработки персональных данных</b>
            <ol className={`${mobile ? 'mobilePadding_0 mobileFont_1' : ''}`}>
              <li>Вы всегда можете посетить данный сайт, не раскрывая никакой персональной информации.</li>
              <li>Под персональными данными понимается любая информация, относящаяся к определенному или определяемому на основании такой информации физическому лицу.</li>
              <li>Оператор собирает и использует персональные данные, необходимые для выполнения запроса Пользователя, верификации пользователей на Сайте, включая случаи восстановления доступа или изменения учетных данных по запросу Пользователя; направление документов об оплатах согласно <b>ФЗ No 54-ФЗ;</b> решение возможных конфликтных ситуаций; улучшение качества обслуживания; проведение статистических исследований; обеспечение связи с Оператором; направления рекламной информации по желанию Пользователя; минимизация правовых рисков нашего проекта, это – фамилия, имя, телефон и электронный адрес. Они нужны для того, чтобы Пользователь мог поддерживать связь с Оператором, авторизоваться на Сайте, восстановить доступ к аккаунту, при необходимости.</li>
              <li>Оператор не проверяет достоверность персональных данных, предоставляемых Пользователями, и не проверяет их дееспособность.</li>
              <li>Цель обработки персональных данных Пользователя — информирование Пользователя посредством отправки электронных писем; заключение, исполнение и прекращение гражданско-правовых договоров; предоставление доступа Пользователю к сервисам, информации и/или материалам, содержащимся на сайте: <a href='https://inmemory-forever.com/'>https://inmemory-forever.com/</a> и других сайтах Оператора.</li>
              <li>Персональные данные не распространяются, а также не предоставляются третьим лицам без согласия субъекта персональных данных и используются Оператором исключительно для исполнения указанного договора и заключения договоров с субъектом персональных данных;</li>
              <li>Также Оператор имеет право направлять Пользователю уведомления о новых продуктах и услугах, специальных предложениях и различных событиях. Пользователь всегда может отказаться от получения информационных сообщений, направив Оператору письмо на адрес электронной почты <b>life777history@gmail.com</b> с пометкой <b>«Отказ от уведомлениях о новых продуктах и услугах и специальных предложениях».</b></li>
              <li>Обезличенные данные Пользователей, собираемые с помощью сервисов интернет-статистики, служат для сбора информации о действиях Пользователей на сайте, улучшения качества сайта и его содержания.</li>
            </ol>
          </li>
          <li><b>Правовые основания обработки персональных данных</b>
            <ol className={`${mobile ? 'mobilePadding_0 mobileFont_1' : ''}`}>
              <li>Оператор обрабатывает персональные данные Пользователя только в случае их заполнения и/или отправки Пользователем самостоятельно через специальные формы, расположенные на сайте: <a href='https://inmemory-forever.com/'>https://inmemory-forever.com/</a> и других сайтах Оператора. Заполняя соответствующие формы и/или отправляя свои персональные данные Оператору, Пользователь выражает свое согласие с данной Политикой.</li>
              <li>Оператор обрабатывает обезличенные данные о Пользователе в случае, если это разрешено в настройках браузера Пользователя <b>(включено сохранение файлов «cookie» и использование технологии JavaScript)</b>.</li>
            </ol>
          </li>
          <li><b>Порядок сбора, хранения, передачи и других видов обработки персональных данных</b>
            <ol className={`${mobile ? 'mobilePadding_0 mobileFont_1' : ''}`}>
              <li>Безопасность персональных данных, которые обрабатываются Оператором, обеспечивается путем реализации правовых, организационных и технических мер, необходимых для выполнения в полном объеме требований действующего законодательства в области защиты персональных данных.</li>
              <li>Оператор обеспечивает сохранность персональных данных и принимает все возможные меры, исключающие доступ к персональным данным неуполномоченных лиц.</li>
              <li>Персональные данные Пользователя никогда, ни при каких условиях не будут переданы третьим лицам, за исключением случаев, связанных с исполнением действующего законодательства.</li>
              <li>В случае выявления неточностей в персональных данных, Пользователь может актуализировать их самостоятельно, путем направления Оператору уведомление на адрес электронной почты Оператора <b>life777history@gmail.com</b> с пометкой <b>«Актуализация персональных данных».</b></li>
              <li>Срок обработки персональных данных является неограниченным. Пользователь может в любой момент отозвать свое согласие на обработку персональных данных, направив Оператору уведомление посредством электронной почты на электронный адрес Оператора <b>life777history@gmail.com</b> с пометкой <b>«Отзыв согласия на обработку персональных данных».</b></li>
              <li>Оператор не осуществляет трансграничную передачу персональных данных.</li> 
            </ol>
          </li>
          <li><b>Заключительные положения</b>
            <ol className={`${mobile ? 'mobilePadding_0 mobileFont_1' : ''}`}>
              <li>Пользователь может получить любые разъяснения по интересующим вопросам, касающимся обработки его персональных данных, обратившись к Оператору с помощью электронной почты <b>life777history@gmail.com</b>.</li>
              <li>Круг прав и обязанностей пользователя определен действующим законодательством. Настоящая Политика лишь дополняет общие положения нормативно-правовых актов для целей регулирования конкретной модели работы Оператора с пользователями, сложившейся в процессе использования Сайта и его сервисов.</li>
              <li>Мы не имеем возможности проверить достоверность указанных пользователем персональных данных при регистрации, за исключением номера телефона и адреса электронной почты, однако исходим из того, что пользователь указывает достоверные персональные данные.</li>
              <li>В случае, если пользователь предоставил недостоверные персональные данные, он самостоятельно несет риск наступления возможных негативных последствий (к примеру: отказ в восстановлении доступа при неверификации субъекта на основе ранее предоставленных им персональных данных, невозможность возвращения оплат при отказе от услуг). Пользователь обязан хранить в тайне от третьих лиц свои учетные данные для доступа к Сайту и его сервисам (логин и пароль).</li>
              <li>В случае, если учетные данные стали известны третьим лицам или у пользователя сайта – субъекта персональных данных есть основания предполагать наступление указанных обстоятельств, он должен обратиться к Оператору на адрес электронной почты <b>life777history@gmail.com</b></li>
              <li>В данном документе будут отражены любые изменения политики обработки персональных данных Оператором. Политика действует бессрочно до замены ее новой версией.</li>
              <li>Актуальная версия Политики в свободном доступе расположена в сети Интернет по адресу: <a href='https://inmemory-forever.com/personalpage'>https://inmemory-forever.com/personalpage</a></li>
            </ol>
          </li>
        </ol>
        </div>
      </div>
      )
      break;
    default:
      break;
  }
 
  return (
    <div id="info"  style={transparent ? { background: 'transparent' } : {} } className="pageInfoBlockWrapper">
      { content }
    </div>
  );
}
