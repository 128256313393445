import React, { useState } from 'react'; 
import './style.css';

export default function TextArea (props) {
 
  const { lable, lable_value, placeholder, onChange, input_value, err, styles, mobile } = props;   
  return (
    <div style={styles} className={`container_textarea`}>
      <textarea onChange={({target: { value }}) => {onChange(value)}} value={input_value} placeholder={placeholder} maxLength={lable_value} className={` ${mobile ? 'mobile_popup_textarea' : 'popup_textarea'} ${err ? 'error_input' : ''}`} /> 
      <div className={` ${mobile ? 'mobile_lable_container' : 'lable_container'} `}>
        {(lable || lable == '') && <label className={`${mobile ? 'mobile_lable_textarea' : 'lable_textarea'}`} htmlFor="login">{`${ input_value.length } / ${lable_value}`}</label>}
      </div>
    </div>   
      
  );
}