import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConfigProvider } from 'antd';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import store from './redux/store';
import 'dayjs/locale/ru';
import ruRU from 'antd/locale/ru_RU';
import './index.css'; 
 

ReactDOM.render(
    <BrowserRouter>
      <Provider store={store}>
        <ConfigProvider locale={ruRU}>
          <App />
        </ConfigProvider>
      </Provider>
    </BrowserRouter>,
  document.getElementById('root')
);
 