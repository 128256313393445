import axios from 'axios'; 
//  export const API_URL = 'https://inmemory-forever.com/api'; // RELEASE
export const API_URL = process.env.NODE_ENV == 'development' ? 'http://localhost:4000/api' : 'https://inmemory-forever.com/api'; 
 
const $api = axios.create({
  withCredentials: true,
  baseURL: API_URL
}); 
 
$api.interceptors.request.use((config) => {
  // config.headers['Content-Type'] = `multipart/form-data`;
  config.headers.Authorization = `Bearer ${localStorage.getItem('token_memory')}`;
  return config;
})
 
$api.interceptors.response.use((config) => {
  return config;
}, async (error)=> {
  const originRequest = error.config
  if(error.response.status === 401 && error.config && !error.config._isRetry) {
    originRequest._isRetry = true;
    try { 
   
      const response = await axios.get(`${API_URL}/refresh`, { withCredentials:true }) 
      // if(response.data.accessToken !== undefined) {
        localStorage.setItem('token_memory',response.data.accessToken);
      // }
      return $api.request(originRequest)
    } catch (e) {

    }
  }
   throw error;
})
  
const api =  {   
  main_api: $api 
}; 

export default api;
