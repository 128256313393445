import React, { useState } from 'react';
import { useDispatch,useSelector } from 'react-redux';
import { popup_referal } from '../../redux/selectors/index';
import { change_visible_referal_popup,set_user } from '../../redux/actions/users'; 
import images from '../../assets/images'; 
import OrangeButton from '../OrangeButton'; 
import InputNumber from '../InputNumber';  
import { MaskedInput, createDefaultMaskGenerator } from 'react-hook-mask';
import UsersServices from '../../services/UsersServices';
import moment from 'moment';
import { sendEmailServer }  from '../../redux/actions/pageinfo';
import './style.css';  
 
const maskGenerator = createDefaultMaskGenerator('9999-9999-9999-9999');
export default function PopapReferal (props) {
    
  const [inputCardsValue, setInputCardsValue] = useState(''); 
  const [errorOut, setErrorOut] = useState(false);
  const [errorCard, setErrorCard] = useState(false);
  const [successCard, setSuccessCard] = useState(false); 
  const [disabled, setDisabled] = useState(false); 
  const [inputOutMoney, setInputOutMoney] = useState(0); 
  
  const dispatch = useDispatch(); 
 
  const popup_visible = useSelector(popup_referal.popup_visible);  

  const roundNumber = (number, digits) => {
    var multiple = Math.pow(10, digits);
    var rndedNum = Math.round(number * multiple) / multiple;
    return rndedNum;
  }
 
  const {  mobile, balance, userName } = props; 
  const outMoney = []
  const { cross } = images;
  let user = {}
  return (
    <div className="modal" >
      <div className="modal-dialog" >
        <div className="modal-content">
          <button onClick={ () => { dispatch(change_visible_referal_popup(!popup_visible));}} type="button" className="close" >
            <img alt="" src={cross} style={{width: '16px', height: '16px'}} /> 
          </button>
          <ul className={`nav-link ${mobile ? 'mobilenav-container' : 'nav-container'}`} >  
            <li className=" "> <h4 className='titlePage'>РЕФЕРАЛЬНАЯ ПРОГРАММА</h4> </li>  
          </ul>
          <div className={`nav-link ${mobile ? 'tab-content background-grey mobile_tabcontent' : 'tab-content background-grey p-5'}`}>  
            <div className={mobile ? 'mobile_interactiveInfo' : 'interactiveInfo'}>Вывод средств производится в ручном режиме в течение 72 часов. </div>
            <h3 className='titlePage'>Введите номер карты для вывода средств:</h3>  
            <MaskedInput
              maskGenerator={maskGenerator}
              value={inputCardsValue}
              onChange={setInputCardsValue}
              placeholder="Номер банковской карты" 
              className={mobile ? 'mobile_input_component_item' : 'input_component_item'}
            />   
            <div className={mobile ? 'mobile_titleInteractiveOutContainer' : 'titleInteractiveOutContainer'}>
            <h3 className='titlePage'>Введите сумму для вывода средств:</h3>  
            <InputNumber max={100000} styles={mobile ? {width: '60%'} : {width: '16%'}} value={inputOutMoney} onChange={setInputOutMoney} />
                <div>Руб.</div> 
                <OrangeButton
                  disabled={disabled}
                  onClick={async () => {  
                    setDisabled(true)
                    if(inputOutMoney > Number(balance) || inputOutMoney < 1) {
                      setErrorOut(true); setTimeout(()=>{ setErrorOut(false);  setDisabled(false);},2000) 
                    } else { 
                      if(inputCardsValue.length === 16) { 

                        await UsersServices.decMoney(userName, inputOutMoney);   
                        outMoney.push({ date: moment().format(), sum: inputOutMoney,cart_number: inputCardsValue });
                        user = await UsersServices.setOutMoney(userName, outMoney ); 
                        dispatch(set_user(user.data.user))  
                        let string = ""
                        for (let index = 0; index < inputCardsValue.length; index++) {
                          const element = inputCardsValue[index];
                          string += element
                          if (index == 3 || index == 7 || index == 11) {
                            string += '-'
                          }
                        } 
                        await sendEmailServer({ 
                          byer_consult_initial: user.data.user.login,
                          byer_consult_tel: user.data.user.registartion_tel, 
                          coment_consult: `Прошу вывести сумму ${inputOutMoney} на карту с номером ${string} ,дата запроса ${moment().format()}`
                        },dispatch) 
                        
                        setSuccessCard(true); setTimeout(()=>{ setSuccessCard(false);  setTimeout(()=>{ setDisabled(false); },1000)  },4000);
                        setInputCardsValue('');
                        setInputOutMoney(0);
                      } else { 
                        setErrorCard(true); setTimeout(()=>{ setErrorCard(false); setDisabled(false); },2000); 
                      }
                    }
                  }}
                  margin={10}
                  text="Вывести"
                  width={150}
                  styles={{fontSize: '16px'}}
                />  
              </div> 
            <div className='interactiveBalansContainer'> 
              <>{errorOut && <div className={mobile ? 'mobile_errortxt' : 'errortxt'}>{`${ inputOutMoney < 1 ? 'Сумма меньше минимальной' : 'Недостаточно средств для вывода'}`}</div>}</>
              <>{errorCard && <div className={mobile ? 'mobile_errortxt' : 'errortxt'}>{`Введите корректно номер карты`}</div>}</> 
              <>{successCard && <div className={mobile ? 'mobile_successtxt' : 'successtxt'}>{`Запрос отправлен, в течении 72 часов средства придут на карту`}</div>}</> 
            </div>    
            <div className='btnTableContainer'> 
              <div className={`${mobile ? 'create__bottom-price_balance_mobile' : 'create__bottom-price'}`}>Ваш реферальный баланс</div> 
              <div className='btnCol'>
                <div className={`${mobile ? 'create__bottom-price_mobile' : 'create__bottom-price'}`}>{`${roundNumber(Number(balance), 1)} ₽.`}</div> 
              </div>
            </div> 
          </div>
        </div>
      </div>
    </div> 
  );
}
 