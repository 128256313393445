import React from 'react';
import Title from '../Title';
import './style.css'
import { about_plus } from '../../config';
import images from '../../assets/images';


export default function PlusBlock(props) {
  const { mobile } = props;
  return (
    <div className="plusItemWrapper">
      <div className='layout'></div>
      {
        mobile 
        ? 
          <>
            <Title mobile={mobile} size={mobile ? 25 : 15} margin="50px 0px 0px 0px" color="white" text="ПОДЕЛИСЬ ПАМЯТЬЮ О БЛИЗКОМ ЧЕЛОВЕКЕ" under z={2}/>
            <div style={{borderColor:'white',zIndex: 9}} className="underlineTextMonument"></div>
            <div style={{zIndex: 9}} className={`${mobile ? 'mobilePlusRightBlock' : 'plusRightBlock'}`}> 
              {
                [ { img: images.action_1, txt: 'Пройдите регистрацию в сервисе и начните пользоваться им' }, { img: images.action_3, txt: 'Наполните страницу данными о близком' }, { img: images.action_2, txt: 'Делитесь информацией с родными через QR-код, и разместите его на памятнике' } ].map((item,i) => (<div key={i+'1p'} className="mobilePlusItem">
                  <div className='containerActions'>
                  <img className='actionCardImg' src={item.img} alt='img'/>  
                  </div>
                  <div className='containerActionTxt'>{item.txt}</div>
                </div>))
              }
            </div>
          </>
        :
        <>
          <Title margin="50px 0px 0px 0px" color="white" text="ПОДЕЛИСЬ ПАМЯТЬЮ О БЛИЗКОМ ЧЕЛОВЕКЕ" under z={2}/>
          <div style={{borderColor:'white',zIndex: 9}} className="underlineTextMonument"></div>
          <div style={{zIndex: 9}} className="plusRightBlock"> 
            {
              [ { img: images.action_1, txt: 'Пройдите регистрацию в сервисе и начните пользоваться им' }, { img: images.action_3, txt: 'Наполните страницу данными о близком' }, { img: images.action_2, txt: 'Делитесь информацией с родными через QR-код, и разместите его на памятнике' } ].map((item,i) => (<div key={i+'1p'} className="plusItem">
                <div className='containerActions'>
                <img className='actionCardImg' src={item.img} alt='img'/>  
                </div>
                <div className='containerActionTxt'>{item.txt}</div>
              </div>))
            }
          </div>
        </>
      }
    </div>
  );
}
 