import ActionTypes from '../constants';

const initialState = {
  user: {},
  isAuth: false,
  untilDay: 0,
  restate: false,
  generatepage: undefined
};

export default function users(state = initialState, {type,payload}) {
  switch (type) {
    case ActionTypes.USERS_PUT_USER:
      return {
        ...state,
        user: payload,
      };

      
    case ActionTypes.USERS_PUT_ISAUTH:
      return {
        ...state,
        isAuth: payload,
      };
    case ActionTypes.USERS_PUT_UNTILDAY:
      return {
        ...state,
        untilDay: payload,
      };
    case ActionTypes.USERS_PUT_RESTATE:
      return {
        ...state,
        restate: payload,
      }; 
    default:
      return state;
  }
}