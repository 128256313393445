import React from 'react';
import Moment from "react-moment";
import images from '../../assets/images';
import { Divider, Form, Radio, Skeleton, Space, Switch } from 'antd';
import 'moment/locale/ru';
import './style.css';

function DataLine(props) {
    const { birth, death, mobile, transparent,backg } = props;
    const { linedead, linelive,linelivegray ,linedeadgray } = images;
    Moment.globalLocale = 'RU'; 
    return ( 
      <div className={`${mobile ? 'mobileDataLineContainer' : 'dataLineContainer'}`}> 
        {
         birth == 'Invalid Date' || death == 'Invalid Date' ? <Skeleton.Input active={true} /> :
          <>
            {/* <div className={`${ transparent ? 'backtransparent' : '' } ${mobile ? 'mobileDataLineContainer' : 'dataLineContainer'}`}>  */}
            <div className={`${mobile ? 'mobileBirthDateContainer' : 'birthDateContainer'}`}>
            {/* <Moment duration={birth} date={death}/> */}
            <Moment className={`colorFon_${backg}`} format="D MMM" withTitle>{birth}</Moment>
            </div>
            {/* <div className={`${mobile ? 'mobileDataLlineGray' : 'lineGray'}`}/>  */}
            <img className={`${mobile ? 'mobileDataLinemonitor' : 'linemonitor'}`} src={ backg != 'none' ? linelive : linelivegray} alt="linemonitorl" />
            <div className={`${mobile ? 'mobileYearsContainer' : 'yearsContainer'}`}>
            <div className={`${mobile ? 'mobileYearTop' : 'yearTop'}`}>
              <Moment className={`colorFon_${backg}`} format="YYYY" withTitle>{birth}</Moment>
              <div style={{backgroundColor: backg == '1' && backg != 'none'   ?  '#fff' : backg == '2' ? '#fff' : backg == '0' ? '#fff' :'#353d51' }} className={`${mobile ? 'mobileLineBlack' : 'lineBlack'} `}/> 
              <Moment className={`colorFon_${backg}`} format="YYYY" withTitle>{death}</Moment>
            </div>
            {/* <div className={`${mobile ? 'mobileYearBottom' : 'yearBottom'} colorFon_${backg}`}>
            <span>В памяти&nbsp;</span><Moment to={death}>{birth}</Moment><span>&nbsp;жизни</span>
            </div> */}
            </div>
            <img className={`${mobile ? 'mobileDataLinemonitor' : 'linemonitor'}`} src={ backg != 'none' ? linedead : linedeadgray} alt="linemonitord" />
            {/* <div className={`${mobile ? 'mobileDataLlineGray' : 'lineGray'}`}/>  */}
            <div className={`${mobile ? 'mobileDeathDateContainer' : 'deathDateContainer'}`}>
            <Moment className={`colorFon_${backg}`} format="D MMM" withTitle>{death}</Moment>
            </div>  
          </>
        } 
      </div> 
    );
}

export default DataLine;
