import React from 'react'; 
import OrangeButton from '../OrangeButton';
import ExamplePage from '../ExamplePage';
import Title from '../Title';
import { ordedr_work } from '../../config';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';  
import images from '../../assets/images';
import { Empty } from 'antd';
import ImageGallery from 'react-image-gallery';
import './style.css';

export default function PagePhotoBlock(props) {
  const { mobile, media, transparent, backg } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch(); 

  // const renderVideo = (item) => {
  //   return (
  //     <div className="video-wrapper">
  //       <iframe
  //         width="100%"
  //         height="480px"
  //         src={item.embedUrl}
  //         frameBorder="0"
  //         allowFullScreen
  //         title="ex"
  //       />
  //     </div>
  //   );
  // };

 const renderVideoUrl = (video) => {
    return (
      <video controls preload="auto" width="90%" height="280">
        <source src={video.original} /> 
      </video>
    )
  }
 
  const media_parse = JSON.parse(media);

  media_parse.map((item) => {
    if(item.type === 'video') { 
      item.renderItem = renderVideoUrl.bind(this)
    }  
 
    if(item.original?.links != undefined) { 
      item.original = item.original?.links
      item.thumbnail = item.thumbnail?.links
    }
  })

 
  return (
    <div id="medias"  style={transparent ? { background: 'transparent' } : {} } className={`${mobile ? 'mobilePagePhotoBlockWrapper' : 'pagePhotoBlockWrapper'}`}>
      {
        mobile ?
        <>
          <Title color={backg != '1' && backg != 'none' ?  '#fff' : backg == '2' ? '#fff' : !mobile  ? '#fff' :  backg == '1' ? '#fff' : undefined} size={30} margin="10px 0px 40px 0px" text="Медиафайлы" under /> 
          <div className={`${mobile ? 'mobilePagePhotoContainer' : 'pagePhotoContainer'}`}> 
          <div className={`${mobile ? 'mobileImageGalleryWrapper' : 'imageGalleryWrapper'}`}>
            {  
              (media !== null && media_parse.length !== 0) && <ImageGallery  
                items={media === null ? [] : media_parse}  
                thumbnailPosition={'top'} 
                showFullscreenButton={false}  
                showPlayButton={false}  
              />   
            } 
            {
              (media_parse.length === 0) && <div style={{ color: backg != '1' && backg != 'none' ?  '#fff' : backg == '2' ? '#fff' : !mobile  ?  backg == 'none' ? undefined :'#fff' :  backg == '1' ? '#fff' : undefined}} className='errorDateTxt' >Не указано</div>
            }
          </div>
          </div>
          </> :
        <>
          <Title  color={backg != '1' && backg != 'none' ?  '#fff' : backg == '2' ? '#fff' : !mobile  ?  backg == 'none' ? undefined :'#fff' :  backg == '1' ? '#fff' : undefined} size={50} margin="50px 0px 0px 0px" text="Медиафайлы" under /> 
          <div className={`${mobile ? 'mobilePagePhotoContainer' : 'pagePhotoContainer'}`}> 
          <div className='imageGalleryWrapper'>
            { 
              (media !== null && media_parse.length !== 0) && <ImageGallery  
                items={media === null ? [] : media_parse}  
                thumbnailPosition={'top'}  
                showFullscreenButton={false}   
                showPlayButton={false}   
              />   
            } 
            {
              (media_parse.length === 0) && <div style={{ color: backg != '1' && backg != 'none' ?  '#fff' : backg == '2' ? '#fff' : !mobile  ?  backg == 'none' ? undefined :'#fff' :  backg == '1' ? '#fff' : undefined}} className='errorDateTxt' >Не указано</div>
            }
          </div>
          </div>
        </>
      }
    </div>
  );
}
