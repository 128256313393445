import React, { useState } from 'react'; 
import './style.css';

export default function InputPopap (props) {
 
  const [visible_pass, setVisiblePass] = useState(false); 

  const { lable, eye, placeholder, onChange, input_value, err, styles, mobile, requer } = props;  
  return (
    <div style={styles} className={`${eye ? "custom-password-control" : ""} ${mobile ? 'mobile_form-group' : 'form-group'}`}>
      {lable && <label htmlFor="login">{lable}
      {
       requer && <sup style={{color: 'red'}}>*</sup>
      }
      </label>}
      <input value={input_value} onChange={({target: { value }}) => {onChange(value)}} type={`${(!visible_pass && eye) ? 'password': 'text'}`} name="username" className={`form-control form-control-lg ${err ? 'error_input' : ''}`} placeholder={placeholder} />
      {eye && <i style={{ top: lable ? '45px' : '0px', right: '15px' }} className={`icon-show-password ${visible_pass ? 'op5' : ''}`} onClick={() => {setVisiblePass(!visible_pass)}}></i>}
      <div className="invalid-feedback" /> 
    </div>  
  );
}