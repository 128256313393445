  import React, { useState,useEffect } from 'react';
  import { useDispatch, useSelector  } from 'react-redux'
  import { Upload  } from 'antd';
  import { useNavigate,useParams } from 'react-router-dom';
  import dayjs from 'dayjs';
  import { Spinner }  from 'react-spinner-animated';
  import Moment from "react-moment";
  import DatePicker from 'react-mobile-datepicker';
  import { CameraOutlined, LockOutlined } from '@ant-design/icons';
  import { users } from '../../redux/selectors';
  import Title from '../../components/Title';
  import OrangeButton from '../../components/OrangeButton';
  import BiosectionCreate from '../../components/BiosectionCreate';
  import BackGroundCreate from '../../components/BackGroundCreate';
  import InputPopap from '../../components/InputPopap';
  import TextArea from '../../components/TextArea';
  import images from '../../assets/images';
  import moment from 'moment';
  import { getIOSSaveDateObj } from '../../helpers'
  import {
    setDeathDate,
    setBirthdayDate,
    setInitialDeath,
    setNationality,
    setBirthLocation,
    setDeathLocation,
    setEpity,
    setChildren,
    setSecondHalf,
    setCareer,
    setEducation,
    setBackgroundSelect,
    setFile, 
    editPage,
    setOneBlockArea,
    setOneBlockInputTitle,
    setOneBlockOneInput,
    setOneBlockTwoInput,
    setTwoBlockArea,
    setTwoBlockInputTitle,
    setTwoBlockOneInput,
    setTwoBlockTwoInput,
    setThreeBlockArea, 
  }  from '../../redux/actions/pageinfo';

  import { pageinfo } from '../../redux/selectors';

  import customParseFormat from 'dayjs/plugin/customParseFormat';

  import "cropperjs/dist/cropper.css";
  import 'react-spinner-animated/dist/index.css';
  import 'moment/locale/ru';

  Moment.globalLocale = 'RU';

  dayjs.extend(customParseFormat);

  function EditPagesScreen(props) {
 
    const { mobile } = props;
    const { profile } = images;

    const dispatch = useDispatch();

    const { idEditPage } = useParams(); 
    const [image, setImage] = useState("");

    const [fileList, setFileList] = useState([]);
    const [fileOneList, setFileOneList] = useState([]);
    const [fileTwoList, setFileTwoList] = useState([]);

    const [bioAreaArr, setBioAreaArr] = useState([]);
    const [loadingcreate, setLoadingCreate] = useState(false);
    const [errorDate, setErrorDate] = useState(false);

    const backgrounds = useSelector(pageinfo.backgrounds);
    const backgroundselect = useSelector(pageinfo.backgroundselect);
    const initialDeath = useSelector(pageinfo.initialDeath);
    const deathdate = useSelector(pageinfo.deathdate);
    const birthdate = useSelector(pageinfo.birthdate);
    const nationality = useSelector(pageinfo.nationality);
    const birthlocation = useSelector(pageinfo.birthlocation);
    const deathlocation = useSelector(pageinfo.deathlocation);
    const secondhalf = useSelector(pageinfo.secondhalf);
    const children = useSelector(pageinfo.children);
    const career = useSelector(pageinfo.career);
    const education = useSelector(pageinfo.education);
    const epity = useSelector(pageinfo.epity);
    const user = useSelector(users.user);

    const OneBlockArea = useSelector(pageinfo.OneBlockArea);
    const OneBlockInputTitle = useSelector(pageinfo.OneBlockInputTitle);
    const OneBlockOneInput = useSelector(pageinfo.OneBlockOneInput);
    const OneBlockTwoInput = useSelector(pageinfo.OneBlockTwoInput);

    const TwoBlockArea = useSelector(pageinfo.TwoBlockArea);
    const TwoBlockInputTitle = useSelector(pageinfo.TwoBlockInputTitle);
    const TwoBlockOneInput = useSelector(pageinfo.TwoBlockOneInput);
    const TwoBlockTwoInput = useSelector(pageinfo.TwoBlockTwoInput);

    const ThreeBlockArea = useSelector(pageinfo.ThreeBlockArea);

 
    function srcToFile(src, fileName, mimeType) { 
      return (fetch(src)
      .then(function(res){return res.arrayBuffer();})
      .then(function(buf){return new File([buf], fileName, {type:mimeType});}));
    }

    const navigate = useNavigate();

    const onChange = ({ fileList: newfiles }) => { 
      setFileList(newfiles);
    };

    const onPreview = async (file) => { 
      let src = file.url;

      if (!src) {
        src = await new Promise((resolve) => {
          const reader = new FileReader();
          reader.readAsDataURL(file.originFileObj);
          reader.onload = () => resolve(reader.result);
        });
      }

      const image = new Image();
      image.src = src;
      const imgWindow = window.open(src);
      imgWindow?.document.write(image.outerHTML);
    };


    const getBlobFromUrl = (myImageUrl) => {
      return new Promise((resolve, reject) => {
        let request = new XMLHttpRequest();
        request.open('GET', myImageUrl, true);
        request.responseType = 'blob';
        request.onload = () => {
          resolve(request.response);
        };
        request.onerror = reject;
        request.send();
      })
    }

    const getDataFromBlob = (myBlob) => {
      return new Promise((resolve, reject) => {
        let reader = new FileReader();
        reader.onload = () => {
        resolve(reader.result);
        };
        reader.onerror = reject;
        reader.readAsDataURL(myBlob);
      })
    }

    const convertUrlToImageData = async (myImageUrl) => {
      try {
        let myBlob = await getBlobFromUrl(myImageUrl);
        let myImageData = await getDataFromBlob(myBlob);
        return myImageData;
      } catch (err) {
        console.log(err);
        return null;
      }
    }

    const convertAsyncMedia = async (fileList) => {
      try {
        var promisesfileOneList = '';
        if (fileList.length != 0) {
          promisesfileOneList = fileList.map(itemFie => {
            return new Promise(async (resolve, reject) => {
            resolve(await convertUrlToImageData(itemFie))
            })
          })
        }

        return promisesfileOneList;

      } catch (err) {
      console.log(err);
      return null;
      }
    }

    const generationTumbai = async (promisesArr, linkThumb, setFunc) => {
      try {
      var tmpFLOne = [];
      var file = {};
      var fileUpload = {};
      var tmpPromises = [];

      tmpPromises = promisesArr.map(async(item, key) => {

        return new Promise(async (resolve, reject) => {

          file = await srcToFile(item, `photo.png`, `image/png`);

          fileUpload = {
            lastModified: file.lastModified,
            lastModifiedDate: file.lastModifiedDate,
            name:file.name,
            originFileObj: file,
            percent: file.percent,
            size: file.size,
            thumbUrl: linkThumb[key],
            type: file.type,
            uid: file.uid
          }

          tmpFLOne.push(fileUpload)
          resolve(tmpFLOne)
        })

      })

      let LinksBioOneFile = await Promise.all(tmpPromises);

      if(LinksBioOneFile.length != 0) {
        setFunc(LinksBioOneFile[0])
      }

      } catch (err) {
        console.log(err);
        return null;
      }
    }

    useEffect( () => {
  
      if(user?.pages != undefined) { 
        const filtered = user?.pages.filter((item) => {
          return item.pageId === idEditPage
        })
 
        var mediaEdit = JSON.parse(filtered[0].media);
        var filesOneList = JSON.parse(filtered[0].fileOneList);
        var filesTwoList = JSON.parse(filtered[0].fileTwoList);
 
        dispatch(setInitialDeath(filtered[0].initialDeath))
        dispatch(setBirthLocation(filtered[0].birthlocation))
        dispatch(setDeathLocation(filtered[0].deathlocation))
        dispatch(setNationality(filtered[0].nationality))
        dispatch(setEducation(filtered[0].education))
        dispatch(setSecondHalf(filtered[0].secondhalf))
        dispatch(setChildren(filtered[0].children))
        dispatch(setCareer(filtered[0].career))
        dispatch(setEpity(filtered[0].epity))

        dispatch(setOneBlockArea(filtered[0].OneBlockArea))
        dispatch(setOneBlockInputTitle(filtered[0].OneBlockInputTitle))
        dispatch(setOneBlockOneInput(filtered[0].OneBlockOneInput))
        dispatch(setOneBlockTwoInput(filtered[0].OneBlockTwoInput))
        dispatch(setTwoBlockArea(filtered[0].TwoBlockArea))
        dispatch(setTwoBlockInputTitle(filtered[0].TwoBlockInputTitle))
        dispatch(setTwoBlockOneInput(filtered[0].TwoBlockOneInput))
        dispatch(setTwoBlockTwoInput(filtered[0].TwoBlockTwoInput))
        dispatch(setThreeBlockArea(filtered[0].ThreeBlockArea))

        dispatch(setDeathDate( filtered[0].deathdate ))
        dispatch(setBirthdayDate( filtered[0].birthdate ))
    //     // dispatch(setDeathDate( filtered[0].deathdate == "Invalid date" ? getIOSSaveDateObj(new Date().toISOString().split('T')[0]) : getIOSSaveDateObj(filtered[0].deathdate) ))
    //     // dispatch(setBirthdayDate( filtered[0].birthdate == "Invalid date" ? getIOSSaveDateObj(new Date().toISOString().split('T')[0]) : getIOSSaveDateObj(filtered[0].birthdate) ))

        if(filtered[0].backgroundselect !== 'none') {
          dispatch(setBackgroundSelect(filtered[0].backgroundselect.split('/')[3].split('.')[0].split('_')[1]))
        } else {
          dispatch(setBackgroundSelect(3))
        }
 
        ( async() => {
          var extention = '';
          var filemedias = '';
          var fileVideo = '';
          var tmpFL = [];
          var itemOriginal = '';
          var LinksBioOneFile = [];

          let promisesArrayMedia = await Promise.all(await convertAsyncMedia([filtered[0].photo])); 
          let promisesArrayOneList = await Promise.all(await convertAsyncMedia(filesOneList));
          let promisesArrayfilesTwoList = await Promise.all(await convertAsyncMedia(filesTwoList));
 
          setImage(promisesArrayMedia[0])

          if (mediaEdit.length != 0) { 
            var promisesfilesMedia = mediaEdit.map((item) => {  

              // extention = item.original?.link == undefined ? item.original.split('.')[item.original.split('.').length - 1] : item.original.link.split('.')[item.original.link.split('.').length - 1]
              extention = item.original.split('.')[item.original.split('.').length - 1]

              return new Promise(async (resolve, reject) => {

              if(extention == 'mov' || extention == 'mp4') {

                fileVideo =  await srcToFile(item.original, `video.${extention}`, `video/${extention}`);

                let test2 = {
                  lastModified: fileVideo.lastModified,
                  lastModifiedDate: fileVideo.lastModifiedDate,
                  name:fileVideo.name,
                  originFileObj: fileVideo,
                  percent: fileVideo.percent,
                  size: fileVideo.size,
                  thumbUrl: item.original,
                  type: fileVideo.type,
                  uid: fileVideo.uid
                }

                tmpFL.push(test2)
              } else {
                filemedias =  await srcToFile(item.original, `photo.${extention}`, `image/${extention}`);

                let test = {
                  lastModified: filemedias.lastModified,
                  lastModifiedDate: filemedias.lastModifiedDate,
                  name:filemedias.name,
                  originFileObj: filemedias,
                  percent: filemedias.percent,
                  size: filemedias.size,
                  thumbUrl: item.original,
                  type: filemedias.type,
                  uid: filemedias.uid
                }

                tmpFL.push(test)

              }

                resolve(tmpFL)
              })
            })
          } 

          if (promisesfilesMedia != undefined && promisesfilesMedia.length != 0) {
            LinksBioOneFile = await Promise.all(promisesfilesMedia);
          }

          if (LinksBioOneFile.length != 0) {
            setFileList(LinksBioOneFile[0])
          }

          generationTumbai(promisesArrayOneList, filesOneList, setFileOneList)
          generationTumbai(promisesArrayfilesTwoList, fileTwoList, setFileTwoList)

        } )();
      } 
    },[user?.pages])

    const [isOpenBird, setIsOpenBird] = useState(false);
    const [isOpenDead, setIsOpenDead] = useState(false);

    const handleToggleBirdth = () => {
      setIsOpenBird(false);
    }

    const handleToggleDead = () => {
      setIsOpenDead(false);
    }

    const handleSelectBirdth = (nextTime) => { 
      dispatch(setBirthdayDate(moment(nextTime).format("YYYY-MM-DD HH:mm")))
      setIsOpenBird(false);
    }

    const handleSelectDead = (nextTime) => {
      dispatch(setDeathDate(moment(nextTime).format("YYYY-MM-DD HH:mm")))
      setIsOpenDead(false);
    }

    // if(new Date(document.getElementsByTagName('time')[0]?.title).toLocaleDateString() == new Date().toLocaleDateString()) {
    //   document.getElementsByTagName('time')[0].innerHTML = 'Не указан'
    // }

    // if(new Date(document.getElementsByTagName('time')[1]?.title).toLocaleDateString() == new Date().toLocaleDateString()) {
    //   document.getElementsByTagName('time')[1].innerHTML = 'Не указан'
    // }
 
      return (
        <div className="createpage_screen">
          <div className='createpage_wrapper'>
            {
              mobile ?
                <>
                  <Title margin="50px 0px 0px 0px" text="Редактировать страницу" under />
                  <div className={`${mobile ? 'mobileCreatePageContainer' : 'createPageContainer'}`}>
                    <div className='croppActionContainer'>
                      <div className='croppActionLeftContainer'>
                        {
                          image !== '' ? <div className='fileImageContainer'>
                          <img className='fileimg' src={image} alt="profile"/>
                          </div> : <div className='btnAddFileContainer'>
                          <input
                          onChange={(event) => {
                            const files = event.target.files;
                            if(files.length !== 0) {
                              var reader = new FileReader();
                              reader.readAsDataURL(files[0]);
                              reader.onload = (function (readerEvt) {
                                return function (e) {
                                  setImage(e.target.result)
                                }
                              })(files[0]);
                            }
                          }}
                          name="file"
                          type="file"
                          id='files'
                          className="input input__file"
                          multiple={false}
                          accept="image/jpeg, image/png, image/gif"  />
                          <label htmlFor="files">
                          <span className='titleCropp'>Добавить фото</span>
                          <CameraOutlined style={{ fontSize: '35px', color: '#555c6d' }} />
                          </label>
                          </div>
                        }
                        {
                          image !== '' && <div className='backImage' onClick={() => {
                          setImage('') 
                          }}>Отменить</div>
                        }
                      </div>
                    </div>

                    <div id='datepicker' className='dateLiveyearsContainer'>
                      <div className='dateLiveyearsTitleContainer'>
                        <div className='dateBirthTitle'>Дата рождения<sup style={{color: 'red'}}>*</sup></div>
                        <div className='dateDeadTitle'>Дата смерти<sup style={{color: 'red'}}>*</sup></div>
                      </div>
                      <div className='dateLiveyearsPickerContainer'>
                        <div style={{border: errorDate ? '1px solid red' : 'none' }} className='dateContainerLine'><Moment format="D MMM YYYY" withTitle>{birthdate}</Moment><div>&nbsp;{`г.`}</div></div>
                        <div style={{border: errorDate ? '1px solid red' : 'none' }} className='dateContainerLine'><Moment format="D MMM YYYY" withTitle>{deathdate}</Moment><div>&nbsp;{`г.`}</div></div>
                        {/* <div style={{border: errorDate ? '1px solid red' : 'none' }} className='dateContainerLine'><Moment format="D MMM YYYY" withTitle>{ birthdate == '' ? '' : birthdate}</Moment><div>&nbsp;{`г.`}</div></div>
                        <div style={{border: errorDate ? '1px solid red' : 'none' }} className='dateContainerLine'><Moment format="D MMM YYYY" withTitle>{ deathdate == '' ? '' : deathdate}</Moment><div>&nbsp;{`г.`}</div></div>  */}
                      </div>
                      <div className='dateLiveyearsPickerContainer'>
                        <OrangeButton
                          styles={{ fontSize: '16px'}}
                          height={40}
                          width={140}
                          margin={{ width: mobile ? "0px 0px 0px 0px" : "20px 0px 0px 0px" }}
                          text="Выбрать"
                          onClick={ () => { setIsOpenBird(true) } }
                        />
                        <OrangeButton
                          styles={{ fontSize: '16px'}}
                          height={40}
                          width={140}
                          margin={{ width: mobile ? "0px 0px 0px 0px" : "20px 0px 0px 0px" }}
                          text="Выбрать"
                          onClick={ () => { setIsOpenDead(true) } }
                        />
                      </div>

                      <DatePicker
                        // value={typeof(birthdate) == 'object' ? birthdate : getIOSSaveDateObj(birthdate)}
                        value={getIOSSaveDateObj(birthdate)}
                        theme="ios"
                        confirmText="Выбрать"
                        cancelText="Отменить" 
                        isOpen={isOpenBird}
                        max={getIOSSaveDateObj(deathdate)}
                        // max={deathdate}
                        headerFormat='DD/MM/YYYY'
                        isPopup={true}
                        onSelect={handleSelectBirdth}
                        onCancel={() => handleToggleBirdth(false)}
                      />
                      <DatePicker
                        value={getIOSSaveDateObj(deathdate)}
                        theme="ios"
                        confirmText="Выбрать"
                        headerFormat='DD/MM/YYYY'
                        cancelText="Отменить" 
                        isOpen={isOpenDead}
                        max={new Date('2500-08-10')}
                        isPopup={true}
                        onSelect={handleSelectDead}
                        onCancel={() => handleToggleDead(false)}
                      />
                    </div>

                    <div className={`${mobile ? 'mobileinputFioContainer' : 'inputFioContainer' }`}>

                      <InputPopap input_value={initialDeath} onChange={(e) => { dispatch(setInitialDeath(e)) }} lable="Ф.И.О" placeholder="Иванов Иван Иванович" />

                      <InputPopap input_value={birthlocation} onChange={(e) => { dispatch(setBirthLocation(e)) }} lable="Место рождения" placeholder="Россия" />

                      <InputPopap input_value={deathlocation} onChange={(e) => { dispatch(setDeathLocation(e)) }} lable="Место смерти" placeholder="Россия" />

                      <InputPopap input_value={nationality} onChange={(e) => { dispatch(setNationality(e)) }} lable="Гражданство" placeholder="Русский" />

                      <InputPopap input_value={education} onChange={(e) => { dispatch(setEducation(e)) }} lable="Образование" placeholder="Высшее" />

                      <InputPopap input_value={secondhalf} onChange={(e) => { dispatch(setSecondHalf(e)) }} lable="Вторая половинка" placeholder="Иванова Людмила Ивановна" />

                      <InputPopap input_value={children} onChange={(e) => { dispatch(setChildren(e)) }} lable="Дети" placeholder="Иванов Иван Иванович" />

                      <InputPopap input_value={career} onChange={(e) => { dispatch(setCareer(e)) }} lable="Род деятельности" placeholder="Инженер" />

                    </div>
                    <div className={`${mobile ? 'mobile_epityTextAreaContainer' : 'epityTextAreaContainer' }`}>
                      <div className={`${mobile ? 'mobile_texareaEpityTitle' : 'texareaEpityTitle' }`}>Напишите краткую эпитафию</div>
                      <TextArea mobile={mobile} lable={epity} lable_value={1000} input_value={epity} onChange={(value) => { dispatch(setEpity(value)) }} />
                    </div>
                    <div className='mediaFilesContainer'>
                      <div className={`${mobile ? 'mobile_texareaEpityTitle' : 'texareaEpityTitle' }`}>Добавить медиафайлы</div>
                      <Upload
                        method='post'
                        listType="picture-card"
                        fileList = {fileList}
                        className={`${mobile ? 'mobile_uploadwidth' : '' }`}
                        onPreview={onPreview}
                        onChange={onChange}
                        onRemove={(file) => {
                          const index = fileList.indexOf(file);
                          const newFileList = fileList.slice();
                          newFileList.splice(index, 1);
                          setFileList(newFileList);
                        }}
                        beforeUpload={(file) => {
                          setFileList([...fileList, file]);
                          return false;
                        }}
                      >
                        { fileList.length < 10 && '+ Добавить' }
                      </Upload>
                    </div>
                    <div className='backgroundCreateContainer'>
                      <div className='texareaEpityTitle'>Выбрать задний фон</div>
                      <div className={`${mobile ? 'mobileBackgroundItemContainer' : 'backgroundItemContainer' }`}>
                        {
                          backgrounds.map((item, key) => (<BackGroundCreate
                            key={key}
                            mobile={mobile}
                            id={key}
                            checked={key == backgroundselect}
                            background={item}
                            onClick={(e) => {
                              dispatch(setBackgroundSelect(e))
                            }}
                          />))
                        }
                      </div>
                    </div>
                    <div className='bioCreateSection'>
                      {
                        [
                          ['Первый блок биографии', OneBlockArea, OneBlockOneInput, OneBlockTwoInput, setOneBlockArea, setOneBlockOneInput, setOneBlockTwoInput, setFileOneList, fileOneList, OneBlockInputTitle, setOneBlockInputTitle],
                          ['Второй блок биографии', TwoBlockArea, TwoBlockOneInput, TwoBlockTwoInput, setTwoBlockArea, setTwoBlockOneInput, setTwoBlockTwoInput, setFileTwoList, fileTwoList, TwoBlockInputTitle, setTwoBlockInputTitle],
                          ['Резюмирующий блок биографии', ThreeBlockArea, '', '', setThreeBlockArea, () => {}, () => {}, () => {}, [], '', () => {}]
                        ].map((item, key) => {
                          return <React.Fragment key={key}>
                          <div className={`${mobile ? 'mobile_texareaEpityTitle' : 'texareaEpityTitle'}`}>{ item[0] }</div>
                            {
                              user.payinfo == '1' ? <div className='bioIconBtn'> <LockOutlined /> </div> : <BiosectionCreate
                                mobile={mobile}
                                value_area={ item[1] }
                                textPhotoInputOne={ item[2] }
                                textPhotoInputTwo={ item[3] }
                                setArea={(value) => {
                                  dispatch(item[4](value))
                                }}
                                setTextPhotoInputOne={(value) => {
                                  dispatch(item[5](value))
                                }}
                                setTextPhotoInputTwo={(value) => {
                                  dispatch(item[6](value))
                                }}
                                setFileList={ item[7] }
                                fileList={ item[8] }
                                inputTitle={ item[9] }
                                setInputTitle={(value) => {
                                  dispatch(item[10](value))
                                }}
                                resume={key == 2} 
                              />
                            }
                          </React.Fragment>
                        })
                      }
                    </div>
                    {
                      !loadingcreate && <div className={`${mobile ? 'mobile_saveBtnContainer' : 'saveBtnContainer' }`}>
                        <OrangeButton
                          height={ mobile ? 50 : 55 }
                          width={ mobile ? 300 : 350 }
                          margin={{ width: mobile ? "0px 0px 0px 0px" : "20px 0px 0px 0px" }}
                          text="Сохранить"
                          onClick={
                          async() => {
                          if(birthdate === '' || deathdate === '') {
                          setErrorDate(true);
                          navigate(`/createpage/#datepicker`);
                          setTimeout(() => {
                          setErrorDate(false);
                          }, 2000);
                          } else {

                          setLoadingCreate(true)
                          const formData = new FormData();
                          if(image !== '') {
                          try {
                          let filePhotos =  await srcToFile(image, 'photo.png', 'image/png');
                          formData.append('upload_photo', filePhotos);
                          } catch (error) {
                          console.error(error)
                          }
                          }

                          if(fileList.length !== 0) {
                          fileList.forEach((file) => {
                          formData.append('media', file.originFileObj)
                          });
                          }

                          if(fileOneList.length !== 0) {
                          fileOneList.forEach((file) => {
                          formData.append('fileOneList', file.originFileObj)
                          });
                          }

                          if(fileTwoList.length !== 0) {
                          fileTwoList.forEach((file) => {
                          formData.append('fileTwoList', file.originFileObj)
                          });
                          }

                          formData.append('userId',user.id)
                          formData.append('initialDeath',initialDeath)
 
                          // if(getIOSSaveDateObj(deathdate).toLocaleDateString() != getIOSSaveDateObj(birthdate).toLocaleDateString()) {
                            formData.append('deathdate',deathdate)
                            formData.append('birthdate',birthdate)
                          // } else {
                          //   formData.append('deathdate',null)
                          //   formData.append('birthdate',null)
                          // }
 
                          formData.append('nationality',nationality)
                          formData.append('birthlocation',birthlocation)
                          formData.append('deathlocation',deathlocation)
                          formData.append('secondhalf',secondhalf)
                          formData.append('children',children)
                          formData.append('career',career)
                          formData.append('education',education)
                          formData.append('epity',epity)
                          formData.append('backgroundselect',backgrounds[backgroundselect])

                          formData.append('OneBlockArea',OneBlockArea)
                          formData.append('OneBlockInputTitle',OneBlockInputTitle)
                          formData.append('OneBlockOneInput',OneBlockOneInput)
                          formData.append('OneBlockTwoInput',OneBlockTwoInput)

                          formData.append('TwoBlockArea',TwoBlockArea)
                          formData.append('TwoBlockInputTitle',TwoBlockInputTitle)
                          formData.append('TwoBlockOneInput',TwoBlockOneInput)
                          formData.append('TwoBlockTwoInput',TwoBlockTwoInput)

                          formData.append('ThreeBlockArea',ThreeBlockArea)

                          var createpagesid = { status: 400 };
                          formData.append('pageId',idEditPage)

                          createpagesid = await editPage(formData,dispatch)

                          if(createpagesid?.status === 200) {
                          dispatch(setOneBlockArea(''))
                          dispatch(setOneBlockInputTitle(''))
                          dispatch(setOneBlockOneInput(''))
                          dispatch(setOneBlockTwoInput(''))
                          dispatch(setTwoBlockArea(''))
                          dispatch(setTwoBlockInputTitle(''))
                          dispatch(setTwoBlockOneInput(''))
                          dispatch(setTwoBlockTwoInput(''))
                          dispatch(setThreeBlockArea(''))
                          dispatch(setDeathDate(moment().format("YYYY-MM-DD HH:mm")));
                          dispatch(setBirthdayDate(moment().format("YYYY-MM-DD HH:mm")));
                          dispatch(setInitialDeath(''));
                          dispatch(setNationality(''));
                          dispatch(setBirthLocation(''));
                          dispatch(setDeathLocation(''));
                          dispatch(setEpity(''));
                          dispatch(setFile([]));
                          dispatch(setChildren(''));
                          dispatch(setSecondHalf(''));
                          dispatch(setCareer(''));
                          dispatch(setEducation(''));
                          navigate(`/pages/${createpagesid.data}`)
                          } else {
                          setLoadingCreate(false)
                          }
                          }
                          }}
                        />
                        <OrangeButton
                          height={ mobile ? 50 : 55 }
                          width={ mobile ? 300 : 350 }
                          color="#aaaaaa"
                          margin={{ width: mobile ? "0px 0px 0px 0px" : "20px 0px 0px 0px" }}
                          text="Отмена"
                          onClick={() => {
                          dispatch(setOneBlockArea(''))
                          dispatch(setOneBlockInputTitle(''))
                          dispatch(setOneBlockOneInput(''))
                          dispatch(setOneBlockTwoInput(''))
                          dispatch(setTwoBlockArea(''))
                          dispatch(setTwoBlockInputTitle(''))
                          dispatch(setTwoBlockOneInput(''))
                          dispatch(setTwoBlockTwoInput(''))
                          dispatch(setThreeBlockArea(''))
                          dispatch(setDeathDate(moment().format("YYYY-MM-DD HH:mm")));
                          dispatch(setBirthdayDate(moment().format("YYYY-MM-DD HH:mm")));
                          dispatch(setInitialDeath(''));
                          dispatch(setNationality(''));
                          dispatch(setBirthLocation(''));
                          dispatch(setDeathLocation(''));
                          dispatch(setEpity(''));
                          dispatch(setFile([]));
                          dispatch(setChildren(''));
                          dispatch(setSecondHalf(''));
                          dispatch(setCareer(''));
                          dispatch(setEducation(''));
                          navigate('/');
                          }}
                        />
                      </div>
                    }
                    {
                      loadingcreate && <div style={{ width: '100%',height: '40px',marginBottom:'140px', display: 'flex', alignItems:'center', justifyContent:'center'}}>
                        <Spinner
                        text={'Создание страницы'}
                        bgColor={"#FFF"}
                        center={false} width={"200px"}
                        height={"100px"}/>
                      </div>
                    }

                  </div>
                </>
                :
                <>
                <Title margin="50px 0px 0px 0px" text="Редактировать страницу" under />
                <div className={`${mobile ? 'mobileCreatePageContainer' : 'createPageContainer'}`}>
                <div className='croppActionContainer'>
                <div className='croppActionLeftContainer'> 
                  {
                    image !== '' ? <div className='fileImageContainer'>
                    <img className='fileimg' src={image} alt="s"/>
                    </div> : <div className='btnAddFileContainer'>
                    <input
                    onChange={(event) => {
                      const files = event.target.files;
                      if(files.length !== 0) {
                        var reader = new FileReader();
                        reader.readAsDataURL(files[0]);
                        reader.onload = (function (readerEvt) {
                        return function (e) {
                        // console.log(e.target.result,'e.target.result')
                        setImage(e.target.result)
                        }
                        })(files[0]);
                      }
                    }}
                    name="file"
                    type="file"
                    id='files'
                    className="input input__file"
                    multiple={false}
                    accept="image/jpeg, image/png, image/gif"  />
                    <label htmlFor="files">
                    <span className='titleCropp'>Добавить фото</span>
                    <CameraOutlined style={{ fontSize: '35px', color: '#555c6d' }} />
                    </label>
                    </div>
                  }
                {
                  image !== '' && <div className='backImage' onClick={() => {
                  setImage('')
                  }}>Отменить</div>
                }

                </div>
                </div>

                <div id='datepicker' className='dateLiveyearsContainer'>
                <div className='dateLiveyearsTitleContainer'>
                <div className='dateBirthTitle'>Дата рождения</div>
                <div className='dateDeadTitle'>Дата смерти</div>
                </div>
                <div className='dateLiveyearsPickerContainer'>
                <div style={{border: errorDate ? '1px solid red' : 'none' }} className='dateContainerLine'><Moment format="D MMM YYYY" withTitle>{birthdate}</Moment><div>&nbsp;{`г.`}</div></div>
                <div style={{border: errorDate ? '1px solid red' : 'none' }} className='dateContainerLine'><Moment format="D MMM YYYY" withTitle>{deathdate}</Moment><div>&nbsp;{`г.`}</div></div>
                </div>
                <div className='dateLiveyearsPickerContainer'>
                <OrangeButton
                height={50}
                width={200}
                margin={{ width: mobile ? "0px 0px 0px 0px" : "20px 0px 0px 0px" }}
                text="Выбрать"
                onClick={ () => { setIsOpenBird(true) } }
                />
                <OrangeButton
                height={50}
                width={200}
                margin={{ width: mobile ? "0px 0px 0px 0px" : "20px 0px 0px 0px" }}
                text="Выбрать"
                onClick={ () => { setIsOpenDead(true) } }
                />
                </div>

                <DatePicker
                // value={typeof(birthdate) == 'object' ? birthdate : getIOSSaveDateObj(birthdate)}
                value={getIOSSaveDateObj(birthdate)}
                theme="ios"
                confirmText="Выбрать"
                cancelText="Отменить"
                // showCaption={true}
                isOpen={isOpenBird}
                max={getIOSSaveDateObj(deathdate)}
                // max={deathdate}
                headerFormat='DD/MM/YYYY'
                isPopup={true}
                onSelect={handleSelectBirdth}
                onCancel={() => handleToggleBirdth(false)}
                />
                <DatePicker
                value={getIOSSaveDateObj(deathdate)}
                theme="ios"
                confirmText="Выбрать"
                headerFormat='DD/MM/YYYY'
                cancelText="Отменить"
                // showCaption={true}
                isOpen={isOpenDead}
                max={new Date('2500-08-10')}
                isPopup={true}
                onSelect={handleSelectDead}
                onCancel={() => handleToggleDead(false)}
                />
                </div>

                <div className='inputFioContainer'>

                <InputPopap input_value={initialDeath} onChange={(e) => { dispatch(setInitialDeath(e)) }} lable="Ф.И.О" placeholder="Иванов Иван Иванович" />

                <InputPopap input_value={birthlocation} onChange={(e) => { dispatch(setBirthLocation(e)) }} lable="Место рождения" placeholder="Россия" />

                <InputPopap input_value={deathlocation} onChange={(e) => { dispatch(setDeathLocation(e)) }} lable="Место смерти" placeholder="Россия" />

                <InputPopap input_value={nationality} onChange={(e) => { dispatch(setNationality(e)) }} lable="Гражданство" placeholder="Русский" />

                <InputPopap input_value={education} onChange={(e) => { dispatch(setEducation(e)) }} lable="Образование" placeholder="Высшее" />

                <InputPopap input_value={secondhalf} onChange={(e) => { dispatch(setSecondHalf(e)) }} lable="Вторая половинка" placeholder="Иванова Людмила Ивановна" />

                <InputPopap input_value={children} onChange={(e) => { dispatch(setChildren(e)) }} lable="Дети" placeholder="Иванов Иван Иванович" />

                <InputPopap input_value={career} onChange={(e) => { dispatch(setCareer(e)) }} lable="Род деятельности" placeholder="Инженер" />

                </div>

                <div className='epityTextAreaContainer'>
                <div className='texareaEpityTitle'>Напишите краткую эпитафию</div>
                <TextArea mobile={mobile} lable={epity} lable_value={1000} input_value={epity} onChange={(value) => { dispatch(setEpity(value)) }} />
                </div>

                <div className='mediaFilesContainer'>
                <div className='texareaEpityTitle'>Добавить медиафайлы</div>
                {/* <ImgCrop rotationSlider restrictPosition showReset minZoom={0.2}> */}
                <div className='uploadContainer'>
                <Upload
                style={{ marginTop: '50px' }}
                // action={'http://localhost:4000/createpage'}
                method='post'
                listType="picture-card"
                fileList = {fileList}
                onPreview={onPreview}
                onChange={onChange}
                onRemove={(file) => {
                const index = fileList.indexOf(file);
                const newFileList = fileList.slice();
                newFileList.splice(index, 1);
                setFileList(newFileList);
                }}
                beforeUpload={(file) => {
                setFileList([...fileList, file]);
                return false;
                }}
                >
                {fileList.length < 10 && '+ Добавить'}
                </Upload>
                </div>
                {/* </ImgCrop> */}

                </div>

                <div className='backgroundCreateContainer'>
                <div className='texareaEpityTitle'>Выбрать задний фон</div>
                <div className='backgroundItemContainer'>
                {
                backgrounds.map((item, key) => (<BackGroundCreate
                key={key}
                id={key}
                checked={key == backgroundselect}
                background={item}
                onClick={(e) => {
                dispatch(setBackgroundSelect(e))

                }}
                />))
                }
                </div>
                </div>
                <div className='bioCreateSection'>
                {
                [['Первый блок биографии', OneBlockArea, OneBlockOneInput, OneBlockTwoInput, setOneBlockArea, setOneBlockOneInput, setOneBlockTwoInput, setFileOneList, fileOneList, OneBlockInputTitle, setOneBlockInputTitle],
                ['Второй блок биографии', TwoBlockArea, TwoBlockOneInput, TwoBlockTwoInput, setTwoBlockArea, setTwoBlockOneInput, setTwoBlockTwoInput, setFileTwoList, fileTwoList, TwoBlockInputTitle, setTwoBlockInputTitle],
                ['Резюмирующий блок биографии', ThreeBlockArea, '', '', setThreeBlockArea, () => {}, () => {}, () => {}, [], '', () => {}]].map((item, key) => {

                return <React.Fragment key={key}>
                <div className='texareaEpityTitle'>{ item[0] }</div>
                {
                user.payinfo == '1' ? <div className='bioIconBtn'> <LockOutlined /> </div> : <BiosectionCreate
                value_area={ item[1] }
                textPhotoInputOne={ item[2] }
                textPhotoInputTwo={ item[3] }
                setArea={(value) => {
                dispatch(item[4](value))
                }}
                setTextPhotoInputOne={(value) => {
                dispatch(item[5](value))
                }}
                setTextPhotoInputTwo={(value) => {
                dispatch(item[6](value))
                }}
                setFileList={ item[7] }
                fileList={ item[8] }
                inputTitle={ item[9] }
                setInputTitle={(value) => {
                dispatch(item[10](value))
                }}
                resume={key == 2}
                // onDeleted={(e) => {
                //   let test = bioAreaArr.filter((item) => { return item.id_key !== +e });
                //   setBioAreaArr([...test])
                //   console.log(test)
                // }}
                />
                }
                </React.Fragment>
                })
                }
                </div>
                {
                !loadingcreate && <div className={`${mobile ? 'mobile_saveBtnContainer' : 'saveBtnContainer' }`}>
                <OrangeButton
                disabled={user.id === undefined}
                height={ mobile ? 50 : 55 }
                width={ mobile ? 300 : 350 }
                margin={{ width: mobile ? "0px 0px 0px 0px" : "20px 0px 0px 0px" }}
                text="Сохранить"
                onClick={
                async() => {
                if(birthdate === '' || deathdate === '') {
                setErrorDate(true);
                navigate(`/createpage/#datepicker`);
                setTimeout(() => {
                setErrorDate(false);
                }, 2000);
                } else {

                setLoadingCreate(true)
                const formData = new FormData();

                if(image !== '') {
                try {
                let filePhotos =  await srcToFile(image, 'photo.png', 'image/png');
                formData.append('upload_photo', filePhotos);
                } catch (error) {
                console.error(error)
                }
                }

                if(fileList.length !== 0) {
                fileList.forEach((file) => {
                formData.append('media', file.originFileObj)
                });
                }

                if(fileOneList.length !== 0) {
                fileOneList.forEach((file) => {
                formData.append('fileOneList', file.originFileObj)
                });
                }

                if(fileTwoList.length !== 0) {
                fileTwoList.forEach((file) => {
                formData.append('fileTwoList', file.originFileObj)
                });
                }

                formData.append('userId',user.id)
                formData.append('initialDeath',initialDeath)

                // if(new Date(deathdate).toLocaleDateString() != new Date(birthdate).toLocaleDateString()) {
                formData.append('deathdate',deathdate)
                formData.append('birthdate',birthdate)
                // } else {
                // formData.append('deathdate',null)
                // formData.append('birthdate',null)
                // }

                formData.append('nationality',nationality)
                formData.append('birthlocation',birthlocation)
                formData.append('deathlocation',deathlocation)
                formData.append('secondhalf',secondhalf)
                formData.append('children',children)
                formData.append('career',career)
                formData.append('education',education)
                formData.append('epity',epity)
                formData.append('backgroundselect',backgrounds[backgroundselect])

                formData.append('OneBlockArea',OneBlockArea)
                formData.append('OneBlockInputTitle',OneBlockInputTitle)
                formData.append('OneBlockOneInput',OneBlockOneInput)
                formData.append('OneBlockTwoInput',OneBlockTwoInput)

                formData.append('TwoBlockArea',TwoBlockArea)
                formData.append('TwoBlockInputTitle',TwoBlockInputTitle)
                formData.append('TwoBlockOneInput',TwoBlockOneInput)
                formData.append('TwoBlockTwoInput',TwoBlockTwoInput)

                formData.append('ThreeBlockArea',ThreeBlockArea)
                formData.append('pageId',idEditPage)
                var createpagesid = { status: 400 };

                createpagesid = await editPage(formData,dispatch)

                if(createpagesid?.status === 200) {
                dispatch(setOneBlockArea(''))
                dispatch(setOneBlockInputTitle(''))
                dispatch(setOneBlockOneInput(''))
                dispatch(setOneBlockTwoInput(''))
                dispatch(setTwoBlockArea(''))
                dispatch(setTwoBlockInputTitle(''))
                dispatch(setTwoBlockOneInput(''))
                dispatch(setTwoBlockTwoInput(''))
                dispatch(setThreeBlockArea(''))
                dispatch(setDeathDate(moment().format("YYYY-MM-DD HH:mm")));
                dispatch(setBirthdayDate(moment().format("YYYY-MM-DD HH:mm")));
                dispatch(setInitialDeath(''));
                dispatch(setNationality(''));
                dispatch(setBirthLocation(''));
                dispatch(setDeathLocation(''));
                dispatch(setEpity(''));
                dispatch(setFile([]));
                dispatch(setChildren(''));
                dispatch(setSecondHalf(''));
                dispatch(setCareer(''));
                dispatch(setEducation(''));
                navigate(`/pages/${createpagesid.data}`)
                } else {
                setLoadingCreate(false)
                }

                }

                }}
                />
                <OrangeButton
                height={ mobile ? 50 : 55 }
                width={ mobile ? 300 : 350 }
                color="#aaaaaa"
                margin={{ width: mobile ? "0px 0px 0px 0px" : "20px 0px 0px 0px" }}
                text="Отмена"
                onClick={() => {
                dispatch(setOneBlockArea(''))
                dispatch(setOneBlockInputTitle(''))
                dispatch(setOneBlockOneInput(''))
                dispatch(setOneBlockTwoInput(''))
                dispatch(setTwoBlockArea(''))
                dispatch(setTwoBlockInputTitle(''))
                dispatch(setTwoBlockOneInput(''))
                dispatch(setTwoBlockTwoInput(''))
                dispatch(setThreeBlockArea(''))
                dispatch(setDeathDate(moment().format("YYYY-MM-DD HH:mm")));
                dispatch(setBirthdayDate(moment().format("YYYY-MM-DD HH:mm")));
                dispatch(setInitialDeath(''));
                dispatch(setNationality(''));
                dispatch(setBirthLocation(''));
                dispatch(setDeathLocation(''));
                dispatch(setEpity(''));
                dispatch(setFile([]));
                dispatch(setChildren(''));
                dispatch(setSecondHalf(''));
                dispatch(setCareer(''));
                dispatch(setEducation(''));
                navigate('/');
                }}
                />
                </div>
                }
                {
                loadingcreate && <div style={{ width: '100%',height: '40px',marginBottom:'140px', display: 'flex', alignItems:'center', justifyContent:'center'}}>
                <Spinner
                text={'Создание страницы'}
                bgColor={"#FFF"}
                center={false} width={"200px"}
                height={"100px"}/>
                </div>
                }
                </div>
                </>
            } 
          </div>
        </div>
      );
  }

  export default EditPagesScreen;

