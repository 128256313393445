import { React, useState } from 'react';
import { Input, Upload } from 'antd';  
import TextArea from '../TextArea'
import InputPopap from '../InputPopap'
import './style.css';  
import { setOneBlockInputTitle } from '../../redux/actions/pageinfo';
 
function BiosectionCreate(props) {
    const { 
      value_area, 
      setArea,  
      textPhotoInputOne, 
      textPhotoInputTwo, 
      setTextPhotoInputOne, 
      setTextPhotoInputTwo, 
      setFileList,
      fileList,
      inputTitle,
      setInputTitle,
      resume,
      id, 
      onDeleted,
      mobile
    } = props;
  
    // const [fileList, setFileList] = useState([]); 
 
    const onChange = ({ fileList: newfiles }) => {  
      setFileList(newfiles);
    };

    const onPreview = async (file) => {
  
      let src = file.url;
    
      if (!src) {
        src = await new Promise((resolve) => {
          const reader = new FileReader();
          reader.readAsDataURL(file.originFileObj);
          reader.onload = () => resolve(reader.result);
        });
      }

      const image = new Image();
      image.src = src;
      const imgWindow = window.open(src);
      imgWindow?.document.write(image.outerHTML); 
    };
  

// console.log(fileList)


    return ( 
      <div className='bioSectionContainer'> 
        {/* <div id={id} className='clearBtnCreate' onClick={(e) => { onDeleted(e.target.id) }} >Удалить</div> */}
        {
          !resume && <InputPopap mobile={mobile} styles={{ marginTop: '50px' }} input_value={ inputTitle } onChange={(e) => { setInputTitle(e) }} placeholder={`Заголовок`} /> 
        }
       
        <TextArea mobile={mobile} lable={value_area} lable_value={1000} input_value={value_area} onChange={(value) => { setArea(value) }} />
      
        {
          !resume && <><div style={{ marginTop: '80px'}} className={`${mobile ? 'mobile_texareaEpityTitle' : 'texareaEpityTitle'}`}>Добавить фото в блок</div>
            <div className='uploadContainer'>
              <Upload 
                // action={'http://localhost:4000/createpage'}
                method='post'
                listType="picture-card"
                className={`${mobile ? 'mobile_uploadwidth' : '' }`}
                fileList = {fileList} 
                onPreview={onPreview}
                onChange={onChange} 
                onRemove={(file) => {
                  const index = fileList.indexOf(file);
                  const newFileList = fileList.slice();
                  newFileList.splice(index, 1);
                  setFileList(newFileList);
                }} 
                beforeUpload={(file) => {
                  setFileList([...fileList, file]);
                  return false;
                }}  
                >
                {fileList.length < 2 && '+ Добавить'}
              </Upload>
            </div></>
        }

        { fileList.length > 0 && <div className={`${mobile ? 'mobile_texareaEpityTitle' : 'texareaEpityTitle'}`}>Добавить подпись к фото</div> } 
        {
          fileList.map((i,k) => {
            return <InputPopap  mobile={mobile} key={k} styles={{ marginTop: '50px' }} input_value={ k == 1 ?  textPhotoInputTwo : textPhotoInputOne } onChange={(e) => { k == 1 ?  setTextPhotoInputTwo(e) : setTextPhotoInputOne(e) }} placeholder={`Фото №${k + 1}`} /> 
          })
        }
      </div>
    );
}

export default BiosectionCreate;
