import React from 'react';
import './style.css'; 


function Biosection(props) {
    const { title, description, infoPhoto, line, summary, mobile,backg } = props;
    // console.log(`${description.replace (/\/n\/n/gm,`\n`)}`)
    console.log()
    return (
      <> 
        {
          summary && <div  style={{color : backg != '1' && backg != 'none' ?  '#fff' : backg == '2' ? '#fff' : !mobile  ?  backg == 'none' ? undefined :'#fff' :  backg == '1' ? '#fff' : undefined}} className='bioSummarySection'>
            {summary}
          </div>
        }
        {
         !summary && <div className='bioSection'>
            <div className={`${mobile ? 'mobile_bioInnerSection' : 'bioInnerSection'}`}>
              <div  style={{color : backg != '1' && backg != 'none' ?  '#fff' : backg == '2' ? '#fff' : !mobile  ?  backg == 'none' ? undefined :'#fff' :  backg == '1' ? '#fff' : undefined}} className={`${mobile ? 'mobile_bioInnerTitle' : 'bioInnerTitle'}`}>{title}</div>
              <div style={{color : backg != '1' && backg != 'none' ?  '#fff' : backg == '2' ? '#fff' : !mobile  ?  backg == 'none' ? undefined :'#fff' :  backg == '1' ? '#fff' : undefined}} className='bioInnerDescr'>{`${description.replace(/\/n\/n/gm,'\n').replace(/&nbsp/gm,' ')}`}</div>
            </div>
            <div className={`${mobile ? 'mobile_bioPhotoSection' : 'bioPhotoSection'} ${infoPhoto.length > 1 ? 'twoPhoto' : 'onePhoto' }`}>
              {
              infoPhoto.map((item,key) => (<div key={`${key}`} className={`${mobile ? 'mobile_bioPhotoContainer' : 'bioPhotoContainer'}`}>
                <img className='bioPhoto' src={item.img} alt="photos" />
                <div  style={{color : backg != '1' && backg != 'none' ?  '#fff' : backg == '2' ? '#fff' : !mobile  ?  backg == 'none' ? undefined :'#fff' :  backg == '1' ? '#fff' : undefined}} className='bioPhotoDesc'>{item.titlephoto}</div>
              </div>))
              }
            </div>
            { line && <div className='bioLineGray' /> } 
          </div>
        }
      </> 
    );
}

export default Biosection;
