import React from 'react';
import { Routes, Route } from 'react-router-dom'; 
import MyPagesScreen from '../Screens/MyPagesScreen/index';
import CreatePagesScreen from '../Screens/CreatePagesScreen/index';
import EditPagesScreen from '../Screens/EditPagesScreen/index';
import MainScreen from '../Screens/MainScreen/index';
import PageScreen from '../Screens/PageScreen/index'; 
import ExamplePageScreen from '../Screens/ExamplePageScreen/index'; 

import DocumentsPageScreen from '../Screens/DocumentsPageScreen/index';  

const Main = (props) => {
 
  const { mobile, isAuth, user } = props; 

  return (
    <main>
      <Routes>
        <Route exact path='/' element={<MainScreen user={user} isAuth={isAuth} mobile={mobile} />} />
        <Route path='/mypages' element={<MyPagesScreen user={user} mobile={mobile} />} /> 
        <Route path='/pages/:idPage' element={<PageScreen user={user} mobile={mobile} />} /> 
        <Route path='/examplepage' element={<ExamplePageScreen mobile={mobile} />} /> 
        <Route path='/useryespage' element={<DocumentsPageScreen mobile={mobile} type={'user'} />} /> 
        <Route path='/licencepage' element={<DocumentsPageScreen mobile={mobile} type={'licence'} />} /> 
        <Route path='/personalpage' element={<DocumentsPageScreen mobile={mobile} type={'personal'} />} /> 
        <Route path='/createpage' element={<CreatePagesScreen mobile={mobile} isAuth={isAuth} />} />  
        <Route path='/editpage/:idEditPage' element={<EditPagesScreen mobile={mobile} />} />  
      </Routes>
    </main>
  )
} 

export default Main;